// src/components/Sidebar.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { auth, db } from "../firebaseConfig"; // Ensure you import Firebase config
import { doc, getDoc } from "firebase/firestore";

const SidebarContainer = styled.div`
  background-color: #fff;
  border-right: 1px solid #e5e5e5;
  width: 14rem;
  height: 100vh; /* Keep for desktop */
  padding: 24px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 10000;

  @media (max-width: 840px) {
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    width: 100%;
    height: -webkit-fill-available; /* Add this */
    min-height: 100vh; /* Add this */
    max-height: 100%; /* Add this */
    padding: 16px 16px 80px 16px; /* Increase bottom padding */
    transition: left 0.3s ease-in-out;
    overflow-y: auto;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;

  @media (max-width: 840px) {
    padding-bottom: 60px; /* Space for profile section */
  }
`;

const CloseButton = styled.div`
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 1001; /* Ensure the close button is above other elements */

  @media (max-width: 840px) {
    display: block;
  }
`;

const Logo = styled.div`
  height: 32px;
  margin-bottom: 80px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MenuItem = styled(NavLink)`
  color: #949ca5;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 198%;
  text-decoration: none;
  padding: 10px;
  border-radius: 8px;
  transition: all 0.3s;

  &.active {
    background-color: #102547;
    color: #fff;
  }

  &:hover {
    background-color: #f0f0f5;
  }
`;

const ProfileInfo = styled(NavLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 3rem;

  @media (max-width: 840px) {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 2rem 1rem;
    margin-bottom: 0;
    // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const ProfileName = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameText = styled.span`
  color: #000;
  font-size: 14px;
  line-height: 120%;
`;

const CompanyName = styled.span`
  color: #85858a;
  font-size: 10px;
  line-height: 120%;
`;

const LogoutButton = styled.button`
  cursor: pointer;
  padding: 8px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
`;

const AdminButton = styled(MenuItem)`
  background-color: #f4f4f4; /* Optional: Different styling */
  color: #000;
  font-weight: bold;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin role

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;

      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists() && userDoc.data().role === "admin") {
          setIsAdmin(true); // Set admin status
        }
      }
    };

    fetchUserRole();
  }, []);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    fullName: "Guest User",
    company: "Your Company",
    profilePicture:
      "https://xdil-qda0-zofk.m2.xano.io/vault/ZZLvFZFt/II7U8HuoQxB6a9fp1nH2dfLz1JQ/UNFV0g../user-icon.jpg", // Placeholder image
  });

  useEffect(() => {
    // Load user data from localStorage
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    if (storedUserData) {
      setUserData(storedUserData);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    toggleSidebar(); // Add this line
    navigate("/login");
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <SidebarContent>
        <CloseButton onClick={toggleSidebar}>
          {/* Close icon SVG */}
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M18 6L6 18M6 6l12 12" stroke="#000" strokeWidth="2" />
          </svg>
        </CloseButton>
        <div>
          <Logo>
            <img
              src="/images/domar logo.png"
              width="100"
              height="80"
              viewBox="0 0 100 80"
              alt="Escodelar Logo"
            />
          </Logo>
          <Menu>
            <MenuItem to="/" activeClassName="active" onClick={toggleSidebar}>
              Dashboard
            </MenuItem>
            <MenuItem
              to="/market-analysis"
              activeClassName="active"
              onClick={toggleSidebar}
            >
              Market Analysis
            </MenuItem>
            <MenuItem
              to="/initiate-sale"
              activeClassName="active"
              onClick={toggleSidebar}
            >
              Initiate a Sale
            </MenuItem>
            <MenuItem
              to="/faq"
              activeClassName="active"
              onClick={toggleSidebar}
            >
              Support
            </MenuItem>
            {isAdmin && (
              <AdminButton
                to="/admin"
                activeClassName="active"
                onClick={toggleSidebar}
              >
                Admin
              </AdminButton>
            )}
          </Menu>
        </div>
        <ProfileSection>
          <ProfileInfo to="/profile" onClick={toggleSidebar}>
            <ProfileImage src={userData.profilePicture} alt="Profile" />
            <ProfileName>
              <NameText>{userData.fullName}</NameText>
              <CompanyName>{userData.company}</CompanyName>
            </ProfileName>
          </ProfileInfo>
          <LogoutButton onClick={handleLogout}>
            {/* Logout icon SVG */}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 1C7.5 1.27614 7.27614 1.5 7 1.5L3.5 1.5C2.67157 1.5 2 2.17157 2 3L2 13C2 13.8284 2.67157 14.5 3.5 14.5L7 14.5C7.27614 14.5 7.5 14.7239 7.5 15C7.5 15.2761 7.27614 15.5 7 15.5L3.5 15.5C2.11929 15.5 0.999999 14.3807 0.999999 13L1 3C1 1.61929 2.11929 0.5 3.5 0.5L7 0.5C7.27614 0.5 7.5 0.723858 7.5 1Z"
                fill="#949CA5"
                stroke="#949CA5"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8536 7.6463C15.0488 7.84156 15.0488 8.15814 14.8536 8.3534L11.6716 11.5354C11.4763 11.7306 11.1597 11.7306 10.9645 11.5354C10.7692 11.3401 10.7692 11.0235 10.9645 10.8283L13.2929 8.49985L5.5 8.49985C5.22386 8.49985 5 8.27599 5 7.99985C5 7.72371 5.22386 7.49985 5.5 7.49985L13.2929 7.49985L10.9645 5.17142C10.7692 4.97616 10.7692 4.65958 10.9645 4.46432C11.1597 4.26906 11.4763 4.26906 11.6716 4.46432L14.8536 7.6463Z"
                fill="#949CA5"
                stroke="#949CA5"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </LogoutButton>
        </ProfileSection>
      </SidebarContent>
    </SidebarContainer>
  );
};

export default Sidebar;
