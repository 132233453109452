// src/pages/Profile.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaUserEdit,
  FaCalendarAlt,
  FaProjectDiagram,
  FaUserShield,
  FaLock,
  FaMoneyCheckAlt,
  FaSignOutAlt,
  FaPhoneAlt,
  FaNewspaper,
  FaUsers,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 24px;
    margin: 0;
  }

  p {
    color: #555;
    margin: 0.5rem 0;
  }

  .connect-company-btn {
    background-color: #0c1244;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
    .connect-company-btn {
      padding: 0.5rem 1rem;
      font-size: 12px;
    }
  }
`;

const ProfileOptions = styled.div`
  margin-top: 2rem;
  width: 100%;
  max-width: 600px;

  .option {
    display: flex;
    align-items: center;
    background: #f7f9fc;
    padding: 0.75rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;

    &:hover {
      background-color: #e0e0e0;
    }

    span {
      margin-left: 10px;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .new-badge {
      background: #0c1244;
      color: #fff;
      font-size: 10px;
      border-radius: 4px;
      padding: 2px 4px;
    }

    input[type="checkbox"] {
      margin-left: auto;
    }
  }

  @media (max-width: 768px) {
    .option {
      font-size: 12px;
      padding: 0.5rem;
      .new-badge {
        font-size: 8px;
      }
    }
  }
`;

const ProfilePage = () => {
  const [userData, setUserData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser; 
        if (user) {
          const userDoc = await getDoc(doc(db, "users", user.uid)); 
          if (userDoc.exists()) {
            setUserData(userDoc.data()); 
          } else {
            console.error("No such document in Firestore!");
          }
        } else {
          console.error("No user is currently signed in.");
          navigate("/login"); 
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchUserData();
  }, [navigate]);

  const options = [
    {
      label: "Client mode",
      isToggle: true,
      icon: <FaUserShield size={20} color="#333" />,
    },
    { label: "Edit profile", icon: <FaUserEdit size={20} color="#333" /> },
    {
      label: "Calendar",
      isNew: true,
      icon: <FaCalendarAlt size={20} color="#333" />,
    },
    {
      label: "Add a project",
      icon: <FaProjectDiagram size={20} color="#333" />,
    },
    { label: "Support", icon: <FaPhoneAlt size={20} color="#333" /> },
    { label: "News", icon: <FaNewspaper size={20} color="#333" /> },
    { label: "Community", icon: <FaUsers size={20} color="#333" /> },
    { label: "Contact us", icon: <FaPhoneAlt size={20} color="#333" /> },
    { label: "User guide", icon: <FaUsers size={20} color="#333" /> },
    { label: "Verification", icon: <FaUserShield size={20} color="#333" /> },
    { label: "Change password", icon: <FaLock size={20} color="#333" /> },
    {
      label: "Subscription & payments",
      icon: <FaMoneyCheckAlt size={20} color="#333" />,
    },
    {
      label: "Log out",
      icon: <FaSignOutAlt size={20} color="#333" />,
      onClick: () => {
        auth.signOut();
        navigate("/login");
      },
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ProfileContainer>
      <ProfileHeader>
        <img
          className="profile-image"
          src={
            userData?.profilePicture ||
            "https://xdil-qda0-zofk.m2.xano.io/vault/ZZLvFZFt/II7U8HuoQxB6a9fp1nH2dfLz1JQ/UNFV0g../user-icon.jpg"
          }
          alt="Profile"
        />
        <h2>{userData?.fullName || "Full Name"}</h2>
        <p>{userData?.company || "Company Name"}</p>
        <button className="connect-company-btn">Connect the company</button>
      </ProfileHeader>

      <ProfileOptions>
        {options.map((option, index) => (
          <div
            key={index}
            className="option"
            onClick={option.onClick ? option.onClick : undefined}
          >
            {option.icon}
            <span>
              {option.label}
              {option.isNew && <span className="new-badge">NEW</span>}
            </span>
            {option.isToggle && (
              <input
                type="checkbox"
                checked={userData?.clientMode || false}
                onChange={() => {}}
              />
            )}
          </div>
        ))}
      </ProfileOptions>
    </ProfileContainer>
  );
};

export default ProfilePage;
