// src/App.js
import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { auth, db } from "./firebaseConfig";
import styled from "styled-components";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import PropertyDetails from "./components/PropertyDetails";
import MarketAnalysis from "./pages/MarketAnalysis";
import InitiateSale from "./pages/InitiateSale";
import FAQ from "./pages/FAQ";
import Profile from "./pages/Profile";
import LoginPage from "./pages/Login";
import projectData from "./projectData";
import OfferLinkPage from "./components/OfferLinkPage";
import ForgotPassword from "./pages/ForgotPassword";
import CreateAccount from "./pages/CreateAccount";
import MapView from "./components/MapView";
import Admin from "./pages/Admin";

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const MainContentContainer = styled.div`
  flex: 1;
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? "14rem" : "0")};
  transition: margin-left 0.3s ease;
  overflow: auto;
  scroll-behavior: smooth; // Add this
  height: 100vh; // Make sure this is set

  @media (max-width: 840px) {
    margin-left: 0;
  }
`;

const ChatButton = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  background-color: #0c1244;
  color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  opacity: 70%;
  z-index: 1000;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0a0e33;
  }

  @media (max-width: 840px) {
    width: 3rem;
    height: 3rem;
    bottom: 3.5rem;
    right: 1rem;
    font-size: 1.5rem;
  }
`;

const ChatPopup = styled.div`
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 100%;
  max-width: 350px;
  max-height: 500px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.show ? "block" : "none")};
  padding: 1rem;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 1000;

  @media (max-width: 840) {
    right: 1rem;
    bottom: 6rem;
    max-width: 90%;
  }
`;

const ChatTextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  background-color: #f9f9f9;
  box-sizing: border-box;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0c1244;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #0a0e33;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ChatResponse = styled.div`
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

function App() {
  const [filteredProjects, setFilteredProjects] = useState(projectData);
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation();
  const [favoriteProjects, setFavoriteProjects] = useState({});
  const userId = auth.currentUser?.uid;
  const [showChat, setShowChat] = useState(false);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    const mainContent = document.querySelector(".main-content-container");
    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  }, [location.pathname]);

  const handleSendMessage = async () => {
    if (!message.trim()) {
      alert("Please enter a message.");
      return;
    }

    setLoading(true);

    try {
      const apiResponse = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are a real estate expert specifically in the Dubai market...",
            },
            { role: "user", content: message },
          ],
          max_tokens: 300,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );
      setResponse(apiResponse.data.choices[0].message.content);
    } catch (error) {
      setResponse("Sorry, I couldn't process your request.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!userId) return;

      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setFavoriteProjects(userDoc.data().favorites || {});
      } else {
        await setDoc(userDocRef, { favorites: {} });
      }
    };

    fetchFavorites();
  }, [userId]);

  const handleFilterChange = ({
    location,
    salesStatus,
    recommended,
    priceRange,
    search,
    myFavorite,
  }) => {
    let filtered = projectData;

    if (location) {
      filtered = filtered.filter((project) => project.city === location);
    }
    if (salesStatus) {
      filtered = filtered.filter(
        (project) => project.salesStatus === salesStatus
      );
    }
    if (recommended) {
      filtered = filtered.filter((project) => project.recommended === true);
    }
    if (myFavorite && favoriteProjects) {
      filtered = filtered.filter((project) => !!favoriteProjects[project.id]);
    }
    if (priceRange.from || priceRange.to) {
      filtered = filtered.filter((project) => {
        const price = parseInt(project.price.replace(/[^0-9]/g, ""), 10);
        const from = priceRange.from ? parseInt(priceRange.from, 10) : 0;
        const to = priceRange.to ? parseInt(priceRange.to, 10) : Infinity;
        return price >= from && price <= to;
      });
    }
    if (search) {
      filtered = filtered.filter((project) =>
        project.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredProjects(filtered);
  };

  const isSidebarVisible = !(
    location.pathname === "/login" ||
    location.pathname === "/forgot-password" ||
    location.pathname === "/create-account" ||
    location.pathname.match(/^\/property\/[^/]+\/offer-link$/)
  );

  const toggleFavorite = async (projectId) => {
    const user = auth.currentUser;
    if (!user) {
      alert("You must be logged in to favorite projects.");
      return;
    }

    const updatedFavorites = { ...favoriteProjects };

    if (updatedFavorites[projectId]) {
      delete updatedFavorites[projectId];
    } else {
      updatedFavorites[projectId] = true;
    }

    setFavoriteProjects(updatedFavorites);

    const docRef = doc(db, "users", user.uid);
    await updateDoc(docRef, { favoriteProjects: updatedFavorites });
  };

  const handleToggleMapView = () => setShowMap((prev) => !prev);

  return (
    <AppContainer>
      {isSidebarVisible && (
        // <Sidebar
        //   isOpen={isSidebarOpen}
        //   toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
        // />
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={() => setIsSidebarOpen(false)} // Changed from toggle to explicitly setting false
        />
      )}
      <MainContentContainer
        className="main-content-container"
        sidebarVisible={isSidebarVisible}
      >
        {isSidebarVisible && (
          <Header
            onFilterChange={handleFilterChange}
            filteredCount={filteredProjects.length}
            toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
            onToggleMapView={handleToggleMapView}
          />
        )}

        {showMap ? (
          <MapView
            projects={filteredProjects}
            favoriteProjects={favoriteProjects}
            toggleFavorite={toggleFavorite}
            onCloseMap={handleToggleMapView}
          />
        ) : (
          <Routes>
            <Route
              path="/"
              element={
                loggedIn ? (
                  <MainContent
                    projects={filteredProjects}
                    favoriteProjects={favoriteProjects}
                    toggleFavorite={(projectId) => {
                      const updatedFavorites = { ...favoriteProjects };
                      if (updatedFavorites[projectId]) {
                        delete updatedFavorites[projectId];
                      } else {
                        updatedFavorites[projectId] = true;
                      }
                      setFavoriteProjects(updatedFavorites);

                      const userDocRef = doc(db, "users", userId);
                      updateDoc(userDocRef, { favorites: updatedFavorites });
                    }}
                  />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/property/:id"
              element={
                loggedIn ? (
                  <PropertyDetails />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/market-analysis"
              element={
                loggedIn ? <MarketAnalysis /> : <Navigate to="/login" replace />
              }
            />
            <Route
              path="/initiate-sale"
              element={
                loggedIn ? <InitiateSale /> : <Navigate to="/login" replace />
              }
            />
            <Route
              path="/faq"
              element={loggedIn ? <FAQ /> : <Navigate to="/login" replace />}
            />
            <Route
              path="/profile"
              element={
                loggedIn ? <Profile /> : <Navigate to="/login" replace />
              }
            />
            <Route
              path="/property/:id/offer-link"
              element={<OfferLinkPage />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route
              path="/login"
              element={<LoginPage onLogin={() => setLoggedIn(true)} />}
            />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        )}
      </MainContentContainer>

      <ChatButton onClick={() => setShowChat(!showChat)}>💬</ChatButton>
      <ChatPopup show={showChat}>
        <ChatTextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Ask anything about Dubai real estate..."
          rows="3"
        />
        <Button onClick={handleSendMessage}>
          {loading ? "Sending..." : "Send"}
        </Button>
        {response && (
          <ChatResponse>
            <strong>Chatbot:</strong> {response}
          </ChatResponse>
        )}
      </ChatPopup>
    </AppContainer>
  );
}

export default App;
