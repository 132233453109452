// src/pages/MarketAnalysis.js
import React, { useState } from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const PageWrapper = styled.div`
  background-color: #ffffff; /* Slight background to see padding */
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll */
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: #f3f7fe;
  text-align: center;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const Heading = styled.h2`
  font-size: 30px;
  font-weight: 600;
  color: #000;
  margin: 0;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Subheading = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: #1882de;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const TabButton = styled.button`
  background-color: ${({ active }) => (active ? "#0246c1" : "#f0f0f5")};
  color: ${({ active }) => (active ? "#fff" : "#000")};
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
  margin-right: 0;

  &:hover {
    background-color: ${({ active }) => (active ? "#0358f3" : "#e0e0e0")};
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    padding: 8px;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-top: 20px;
    gap: 20px;
  }
`;

const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 5px 1px #e3e3e3;
  border-radius: 8px;
  padding: 20px;
  background: ${({ main }) => (main ? "#e3f2fd" : "#f7f9f8")};
  border: ${({ main }) => (main ? "2px solid #2196f3" : "none")};
  box-sizing: border-box;
  max-width: 100%;
`;

const StatTitle = styled.h3`
  font-size: 18px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const StatValue = styled.h2`
  font-size: 30px;
  font-weight: 600;
  color: #0246c1;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const StatChange = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: green;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const VolumeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 16px;
  margin-top: 30px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin-top: 20px;
    grid-template-columns: 1fr;
  }
`;

const RentalHeader = styled.h2`
  margin: 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 10px 0;
    font-size: 18px;
  }
`;

const RentalContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 16px;
  margin-top: 30px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin-top: 20px;
    grid-template-columns: 1fr;
  }
`;

const VolumeCard = styled(StatCard)`
  text-align: left;
  padding: 12px;
  max-width: 100%;
  box-sizing: border-box;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Icon = styled.span`
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const SalesInfo = styled.div`
  margin-top: 10px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

// const ChartsContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 20px;
//   padding: 20px 0;
//   flex-wrap: wrap;
//   overflow: hidden;
//   box-sizing: border-box;
//   width: 100%;

//   @media (max-width: 768px) {
//     gap: 10px;
//     padding: 10px 0;
//   }

//   .chart-wrapper {
//     width: 300px;
//     height: 300px;
//     max-width: 100%;
//     overflow: hidden;
//   }

//   canvas {
//     max-width: 100% !important;
//     height: auto !important;
//   }
// `;

const MarketAnalysis = () => {
  const [activeTab, setActiveTab] = useState("all");

  const data = {
    all: {
      saleVolume: "18,045",
      pricePerSqft: "AED 1,539",
      salesValue: "AED 44.6B",
      changeSaleVolume: "32.7% vs. Sep-2023",
      changePricePerSqft: "7.8% vs. Sep-2023",
      changeSalesValue: "18.1% vs. Sep-2023",
      volumeData: [
        {
          icon: "🏢",
          title: "14,155",
          type: "Apartments",
          change: "35.9%",
          salesValue: "AED 24.4B",
        },
        {
          icon: "🏠",
          title: "2,935",
          type: "Villas",
          change: "29.2%",
          salesValue: "AED 12.2B",
        },
        {
          icon: "🏢",
          title: "358",
          type: "Commercial",
          change: "-6.5%",
          salesValue: "AED 797M",
        },
        {
          icon: "🗺️",
          title: "597",
          type: "Plots",
          change: "12.6%",
          salesValue: "AED 7.1B",
        },
      ],
    },
    firstSale: {
      saleVolume: "12,733",
      pricePerSqft: "AED 1,610",
      salesValue: "AED 29.5B",
      changeSaleVolume: "42.6% vs. Sep-2023",
      changePricePerSqft: "3.4% vs. Sep-2023",
      changeSalesValue: "22.5% vs. Sep-2023",
      volumeData: [
        {
          icon: "🏢",
          title: "10,218",
          type: "Apartments",
          change: "43.7%",
          salesValue: "AED 17.9B",
        },
        {
          icon: "🏠",
          title: "2,104",
          type: "Villas",
          change: "44.9%",
          salesValue: "AED 8B",
        },
        {
          icon: "🏢",
          title: "80",
          type: "Commercial",
          change: "-25.2%",
          salesValue: "AED 213.3M",
        },
        {
          icon: "🗺️",
          title: "331",
          type: "Plots",
          change: "-25.9%",
          salesValue: "AED 3.4B",
        },
      ],
    },
    resale: {
      saleVolume: "5,312",
      pricePerSqft: "AED 1,278",
      salesValue: "AED 15.1B",
      changeSaleVolume: "13.7% vs. Sep-2023",
      changePricePerSqft: "14.4% vs. Sep-2023",
      changeSalesValue: "10.3% vs. Sep-2023",
      volumeData: [
        {
          icon: "🏢",
          title: "3,937",
          type: "Apartments",
          change: "19% vs. Sep-2023",
          salesValue: "AED 6.5B",
        },
        {
          icon: "🏠",
          title: "831",
          type: "Villas",
          change: "1.5% vs. Sep-2023",
          salesValue: "AED 4.2B",
        },
        {
          icon: "🏢",
          title: "278",
          type: "Commercial",
          change: "0.7% vs. Sep-2023",
          salesValue: "AED 583.7M",
        },
        {
          icon: "🗺️",
          title: "266",
          type: "Plots",
          change: "-4% vs. Sep-2023",
          salesValue: "AED 3.8B",
        },
      ],
    },
  };

  const currentData = data[activeTab]; // currentData will now be defined for all tabs

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const pieData = {
    labels: ["Villa", "Plot", "Apartment", "Commercial"],
    datasets: [
      {
        data: [27, 16, 55, 2],
        backgroundColor: ["#1976d2", "#4caf50", "#ffeb3b", "#f44336"],
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Dubai property sales value by category",
        font: {
          size: 18,
        },
      },
    },
  };

  const barData = {
    labels: [
      "Jumeirah Village Circle (JVC)",
      "Dubai South",
      "Dubai Hills Estate",
      "Sobha Hartland 2",
      "Business Bay",
    ],
    datasets: [
      {
        label: "Property Demand",
        data: [1800, 1200, 1100, 1000, 900],
        backgroundColor: "#1976d2",
      },
    ],
  };

  const barOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Top 5 performing areas - September 2024",
        font: {
          size: 18,
        },
      },
    },
  };

  return (
    <PageWrapper>
      <BannerContainer>
        <Heading>Dubai Real Estate Market Overview</Heading>
        <Subheading>September 2024</Subheading>
      </BannerContainer>
      <ButtonContainer>
        <TabButton
          active={activeTab === "all"}
          onClick={() => handleTabClick("all")}
          isFirst
        >
          All
        </TabButton>
        <TabButton
          active={activeTab === "firstSale"}
          onClick={() => handleTabClick("firstSale")}
        >
          1st Sale
        </TabButton>
        <TabButton
          active={activeTab === "resale"}
          onClick={() => handleTabClick("resale")}
          isLast
        >
          Resale
        </TabButton>
      </ButtonContainer>

      <StatsContainer>
        <StatCard main>
          <StatTitle>Sale Volume</StatTitle>
          <StatValue>{currentData.saleVolume}</StatValue>
          <StatChange>⬆ {currentData.changeSaleVolume}</StatChange>
        </StatCard>
        <StatCard main>
          <StatTitle>Price per sqft</StatTitle>
          <StatValue>{currentData.pricePerSqft}</StatValue>
          <StatChange>⬆ {currentData.changePricePerSqft}</StatChange>
        </StatCard>
        <StatCard main>
          <StatTitle>Sales Value</StatTitle>
          <StatValue>{currentData.salesValue}</StatValue>
          <StatChange>⬆ {currentData.changeSalesValue}</StatChange>
        </StatCard>
      </StatsContainer>

      <VolumeContainer>
        {currentData.volumeData.map((item, index) => (
          <VolumeCard key={index}>
            <CardHeader>
              <Icon>{item.icon}</Icon>
              <div>
                <h3 style={{ fontSize: "16px", margin: 0 }}>{item.title}</h3>
              </div>
            </CardHeader>
            <p style={{ fontSize: "14px" }}>{item.type}</p>
            <br />
            <SalesInfo>
              <p style={{ margin: 0 }}>Sales value</p>
              <StatChange>⬆ {item.change}</StatChange>
              <h3 style={{ fontSize: "16px", marginTop: "5px" }}>
                {item.salesValue}
              </h3>
            </SalesInfo>
          </VolumeCard>
        ))}
      </VolumeContainer>

      <RentalHeader>Rental prices</RentalHeader>
      <RentalContainer>
        {[
          {
            icon: "🏢",
            type: "Apartment",
            change: "17.1% vs. Sep, 2023",
            salesValue: "AED 82K",
          },
          {
            icon: "🏠",
            type: "Villa",
            change: "2.9% vs. Sep, 2023",
            salesValue: "AED 180K",
          },
          {
            icon: "🏢",
            type: "Commercial",
            change: "19.1% vs. Sep, 2023",
            salesValue: "AED 65.5K",
          },
        ].map((item, index) => (
          <VolumeCard key={index}>
            <CardHeader>
              <Icon>{item.icon}</Icon>
              <div>
                <h3 style={{ fontSize: "16px", margin: 0 }}>
                  {item.salesValue}
                </h3>
              </div>
            </CardHeader>
            <p style={{ fontSize: "14px" }}>{item.type}</p>
            <br />
            <SalesInfo>
              <StatChange>⬆ {item.change}</StatChange>
            </SalesInfo>
          </VolumeCard>
        ))}
      </RentalContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: "20px 0",
          flexWrap: "wrap",
          overflow: "hidden",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <div
          className="chart-wrapper"
          style={{ width: "300px", height: "300px" }}
        >
          <Doughnut data={pieData} options={pieOptions} />
        </div>
        <div
          className="chart-wrapper"
          style={{ width: "300px", height: "300px" }}
        >
          <Bar data={barData} options={barOptions} />
        </div>
      </div>
    </PageWrapper>
  );
};

export default MarketAnalysis;
