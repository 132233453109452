// src/components/Header.js
import React, { useState } from "react";
import styled from "styled-components";
import projectData from "../projectData";
import { useLocation } from "react-router-dom";

const HeaderContainer = styled.div`
  // position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #ffffff;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-bottom: 0;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;
  margin-right: 1rem;

  @media (max-width: 768px) {
    display: block;
    align-self: flex-start;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    align-self: flex-start;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ProjectCount = styled.span`
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: 600;
  background-color: #f0f0f5;
  margin-left: 12px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 6px 10px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileButtonsRow = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    gap: 1rem;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 8px 12px;
  cursor: pointer;
  color: #000;
  border: 1px solid #e0e0e0;
  height: 24px;
  font-weight: 800;
  white-space: nowrap;

  @media (max-width: 768px) {
    height: auto;
    border-radius: 8px;
    font-size: 14px;
    justify-content: center;
    width: 48%;
    padding: 10px;
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const Dropdown = styled.select`
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  font-weight: 800;
`;

const RecommendedButton = styled(FilterButton)`
  justify-content: space-between;
  background-color: #254f8e;
  color: white;
  font-weight: 800;
`;

const FavoriteButton = styled(FilterButton)`
  justify-content: space-between;
  background-color: white;
  color: black;
  font-weight: 800;
`;

const Radio = styled.div`
  background-color: ${(props) => (props.active ? "#102547" : "#bdc2d3")};
  border-radius: 20px;
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 3px;
  transition: background-color 0.3s;
  cursor: pointer;
  margin-left: 5px;
`;

const RadioCircle = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: all 0.3s;
  transform: ${(props) =>
    props.active ? "translateX(23.5px)" : "translateX(0)"};
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  width: 18rem;
  height: 40px;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 0.5rem;
  }
`;

const SearchIcon = styled.img`
  margin-left: 12px;
  flex: none;
  align-self: center;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  color: #949ca5;
  background-color: #f4f4f4;
  border: none;
  border-radius: 10px;
  margin: 0;
  padding: 0 12px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  outline: none;
`;

const PriceRangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f4f4f4;
  border-radius: 8px;
  height: 40px;
`;

const PriceInput = styled.input`
  background: transparent;
  border: none;
  width: 5rem;
  text-align: center;
  font-size: 14px;
  outline: none;
  color: #000;

  &::placeholder {
    color: #949ca5;
  }
`;

const MobileFilterModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileFilterContent = styled.div`
  background: #fff;
  width: 90%;
  max-width: 400px;
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 20px;
`;

const ModalFilterBlock = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  ${Dropdown} {
    background: #f4f4f4;
    border-radius: 8px;
    padding: 8px;
    border: none;
  }
`;

const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  span {
    font-weight: 600;
    margin-right: 1rem;
  }
`;

const ModalApplyButton = styled.button`
  width: 100%;
  background-color: #254f8e;
  color: #fff;
  font-weight: 800;
  border: none;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 1rem;
`;

const Header = ({
  onFilterChange,
  filteredCount,
  toggleSidebar,
  onToggleMapView,
}) => {
  const [locationFilter, setLocationFilter] = useState("");
  const [salesStatusFilter, setSalesStatusFilter] = useState("");
  const [recommendedFilter, setrecommendedFilter] = useState(false);
  const [priceRange, setPriceRange] = useState({ from: "", to: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [myFavoriteFilter, setMyFavoriteFilter] = useState(false);
  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);

  const { pathname } = useLocation();
  const isDashboard = pathname === "/";

  const uniqueCities = [...new Set(projectData.map((project) => project.city))];
  const uniqueSalesStatus = [
    ...new Set(projectData.map((project) => project.salesStatus)),
  ];

  const handleLocationChange = (e) => {
    setLocationFilter(e.target.value);
    applyFilters(
      e.target.value,
      salesStatusFilter,
      recommendedFilter,
      priceRange,
      searchTerm,
      myFavoriteFilter
    );
  };

  const handleSalesStatusChange = (e) => {
    setSalesStatusFilter(e.target.value);
    applyFilters(
      locationFilter,
      e.target.value,
      recommendedFilter,
      priceRange,
      searchTerm,
      myFavoriteFilter
    );
  };

  const handlerecommendedToggle = () => {
    const newValue = !recommendedFilter;
    setrecommendedFilter(newValue);
    applyFilters(
      locationFilter,
      salesStatusFilter,
      newValue,
      priceRange,
      searchTerm,
      myFavoriteFilter
    );
  };

  const handlePriceChange = (e, field) => {
    const value = e.target.value;
    const updatedPriceRange = { ...priceRange, [field]: value };
    setPriceRange(updatedPriceRange);

    applyFilters(
      locationFilter,
      salesStatusFilter,
      recommendedFilter,
      updatedPriceRange,
      searchTerm,
      myFavoriteFilter
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    applyFilters(
      locationFilter,
      salesStatusFilter,
      recommendedFilter,
      priceRange,
      e.target.value,
      myFavoriteFilter
    );
  };

  const handleMyFavoriteToggle = () => {
    const newValue = !myFavoriteFilter;
    setMyFavoriteFilter(newValue);
    applyFilters(
      locationFilter,
      salesStatusFilter,
      recommendedFilter,
      priceRange,
      searchTerm,
      newValue
    );
  };

  const applyFilters = (
    location,
    salesStatus,
    recommended,
    priceRange,
    search,
    myFavorite
  ) => {
    onFilterChange({
      location,
      salesStatus,
      recommended,
      priceRange,
      search,
      myFavorite,
    });
  };

  const handleMobileFilterClick = () => {
    setShowMobileFilterModal(true);
  };

  const closeMobileFilterModal = () => {
    setShowMobileFilterModal(false);
  };

  return (
    <>
      <HeaderContainer>
        <TitleRow>
          <HamburgerIcon onClick={toggleSidebar}>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M3 6h18M3 12h18M3 18h18" stroke="#000" strokeWidth="2" />
            </svg>
          </HamburgerIcon>

          {/* Only show these elements if on the dashboard */}
          {isDashboard && (
            <>
              <TitleContainer>
                <Title>Total Projects</Title>
                <ProjectCount>{filteredCount}</ProjectCount>
              </TitleContainer>
              <SearchBox>
                <SearchIcon
                  src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/648f729535bcc5782b9b6d27_Frame%2044.svg"
                  alt="Search Icon"
                />
                <SearchInput
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </SearchBox>
            </>
          )}
        </TitleRow>

        {/* Only show filters and map if on the dashboard */}
        {isDashboard && (
          <>
            <FilterRow>
              <FilterButton>
                <IconContainer>
                  {/* Globe icon as placeholder */}
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 2.88571C6.96632 2.88571 2.88571 6.96632 2.88571 12C2.88571 17.0337 6.96632 21.1143 12 21.1143C17.0337 21.1143 21.1143 17.0337 21.1143 12C21.1143 6.96632 17.0337 2.88571 12 2.88571ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                      fill="black"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.554 8.61671C9.38039 9.58225 8.59632 10.9403 8.34696 12.4395L7.79641 15.7493C7.51051 17.4681 9.50904 18.6219 10.8546 17.5149L13.4457 15.3832C14.6194 14.4177 15.4034 13.0597 15.6528 11.5605L16.2033 8.25065C16.4892 6.53186 14.4907 5.37801 13.1452 6.485L10.554 8.61671ZM12 10.1143C10.9585 10.1143 10.1143 10.9585 10.1143 12C10.1143 13.0415 10.9585 13.8857 12 13.8857C13.0415 13.8857 13.8857 13.0415 13.8857 12C13.8857 10.9585 13.0415 10.1143 12 10.1143Z"
                      fill="black"
                    ></path>
                  </svg>
                </IconContainer>
                <Dropdown
                  onChange={handleLocationChange}
                  value={locationFilter}
                >
                  <option value="">Location</option>
                  {uniqueCities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </Dropdown>
              </FilterButton>
              <FilterButton>
                <Dropdown
                  onChange={handleSalesStatusChange}
                  value={salesStatusFilter}
                >
                  <option value="">Sales Status</option>
                  {uniqueSalesStatus.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Dropdown>
              </FilterButton>
              <RecommendedButton onClick={handlerecommendedToggle}>
                <span>Recommended</span>
                <Radio active={recommendedFilter}>
                  <RadioCircle active={recommendedFilter} />
                </Radio>
              </RecommendedButton>
              <FavoriteButton onClick={handleMyFavoriteToggle}>
                <span>My Favorite</span>
                <Radio active={myFavoriteFilter}>
                  <RadioCircle active={myFavoriteFilter} />
                </Radio>
              </FavoriteButton>
              <PriceRangeContainer>
                <PriceInput
                  type="text"
                  placeholder="From ($)"
                  value={priceRange.from}
                  onChange={(e) => handlePriceChange(e, "from")}
                />
                <span>-</span>
                <PriceInput
                  type="text"
                  placeholder="To ($)"
                  value={priceRange.to}
                  onChange={(e) => handlePriceChange(e, "to")}
                />
              </PriceRangeContainer>
              <FilterButton onClick={onToggleMapView}>
                <IconContainer>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20.5 3.5L13.5 6.5L7 4L3.5 5.5V19.5L10.5 16.5L17 19.5L20.5 18V3.5ZM9.5 6L14.5 7.5V18L9.5 16.5V6ZM5.5 7.5L8.5 8.5V16L5.5 17.5V7.5ZM15.5 9L18.5 8V16.5L15.5 15.5V9Z"
                      fill="#000"
                    />
                  </svg>
                </IconContainer>
                Map
              </FilterButton>
            </FilterRow>

            <MobileButtonsRow>
              <FilterButton onClick={handleMobileFilterClick}>
                <IconContainer>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M3 4H21L14 13V20L10 16V13L3 4Z"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IconContainer>
                Filters
              </FilterButton>
              <FilterButton onClick={onToggleMapView}>
                <IconContainer>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M20.5 3.5L13.5 6.5L7 4L3.5 5.5V19.5L10.5 16.5L17 19.5L20.5 18V3.5ZM9.5 6L14.5 7.5V18L9.5 16.5V6ZM5.5 7.5L8.5 8.5V16L5.5 17.5V7.5ZM15.5 9L18.5 8V16.5L15.5 15.5V9Z"
                      fill="#000"
                    />
                  </svg>
                </IconContainer>
                Map
              </FilterButton>
            </MobileButtonsRow>
          </>
        )}
      </HeaderContainer>

      {showMobileFilterModal && isDashboard && (
        <MobileFilterModal>
          <MobileFilterContent>
            <ModalCloseButton onClick={closeMobileFilterModal}>
              ✕
            </ModalCloseButton>
            <ModalTitle>Filters</ModalTitle>

            <ModalFilterBlock>
              <label>Location</label>
              <Dropdown onChange={handleLocationChange} value={locationFilter}>
                <option value="">All Locations</option>
                {uniqueCities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Dropdown>
            </ModalFilterBlock>

            <ModalFilterBlock>
              <label>Sales Status</label>
              <Dropdown
                onChange={handleSalesStatusChange}
                value={salesStatusFilter}
              >
                <option value="">All Statuses</option>
                {uniqueSalesStatus.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Dropdown>
            </ModalFilterBlock>

            <ToggleRow>
              <span>Recommended</span>
              <Radio
                active={recommendedFilter}
                onClick={handlerecommendedToggle}
              >
                <RadioCircle active={recommendedFilter} />
              </Radio>
            </ToggleRow>

            <ToggleRow>
              <span>My Favorite</span>
              <Radio active={myFavoriteFilter} onClick={handleMyFavoriteToggle}>
                <RadioCircle active={myFavoriteFilter} />
              </Radio>
            </ToggleRow>

            <ModalFilterBlock>
              <label>Price Range</label>
              <PriceRangeContainer>
                <PriceInput
                  type="text"
                  placeholder="From ($)"
                  value={priceRange.from}
                  onChange={(e) => handlePriceChange(e, "from")}
                />
                <span>-</span>
                <PriceInput
                  type="text"
                  placeholder="To ($)"
                  value={priceRange.to}
                  onChange={(e) => handlePriceChange(e, "to")}
                />
              </PriceRangeContainer>
            </ModalFilterBlock>

            <ModalApplyButton onClick={closeMobileFilterModal}>
              Apply Filters
            </ModalApplyButton>
          </MobileFilterContent>
        </MobileFilterModal>
      )}
    </>
  );
};

export default Header;
