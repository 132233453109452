import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useNavigate } from "react-router-dom";
import ProjectCard from "./ProjectCard";

// Fix for Leaflet default icon
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41], // Align the icon correctly
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapView = ({
  projects,
  toggleFavorite,
  favoriteProjects,
  onCloseMap,
}) => {
  const navigate = useNavigate();

  // Parse coordinates from the URL
  const parseCoordinates = (url) => {
    if (!url) return null;
    const latMatch = url.match(/!3d([\d.-]+)/);
    const lngMatch = url.match(/!2d([\d.-]+)/);
    if (!latMatch || !lngMatch) return null;
    const lat = parseFloat(latMatch[1]);
    const lng = parseFloat(lngMatch[1]);
    return [lat, lng];
  };

  return (
    <div style={{ height: "100vh", width: "100%", position: "relative" }}>
      {/* Close Map Button */}
      <button
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1000,
          backgroundColor: "#fff",
          color: "#333",
          border: "1px solid #ccc",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: "28px",
          cursor: "pointer",
        }}
        onClick={onCloseMap}
      >
        ✕
      </button>

      <MapContainer
        center={[25.276987, 55.296249]} // Dubai coordinates
        zoom={12}
        style={{ height: "100%", width: "100%" }}
      >
        {/* Mapbox Tile Layer */}
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
          attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a> contributors'
        />
        {projects.map((project) => {
          const coordinates = parseCoordinates(project.mapUrl);
          if (!coordinates) return null;

          return (
            <Marker key={project.id} position={coordinates}>
              <Popup maxWidth={400} minWidth={300} maxHeight={400}>
                <div style={{ width: "100%", overflow: "auto" }}>
                  <ProjectCard
                    title={project.title}
                    location={project.location}
                    price={project.price}
                    completion={project.completionDate}
                    imageUrl={project.imageUrl}
                    statusBadge={project.statusBadge}
                    recommendationText={
                      project.recommended ? "Recommended" : ""
                    }
                    commissionBadge={project.commissionBadge}
                    favorite={!!favoriteProjects[project.id]} // Show favorite state
                    onFavoriteToggle={() => toggleFavorite(project.id)} // Handle favorite toggle
                    onClick={() => {
                      onCloseMap(); // Ensure the map view closes
                      navigate(`/property/${project.id}`); // Navigate to the PropertyDetails page
                    }} // Navigate to details
                  />
                </div>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default MapView;
