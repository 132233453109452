// src/components/MainContent.js
import React from "react";
import styled from "styled-components";
import ProjectCard from "./ProjectCard";
import { useNavigate } from "react-router-dom";

const MainContainer = styled.div`
  padding: 2rem;
  background-color: #f0f0f5;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const MainContent = ({ projects, favoriteProjects, toggleFavorite }) => {
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/property/${id}`);
  };

  // Add a check to ensure `projects` is defined and is an array
  if (!projects || !Array.isArray(projects)) {
    return <div>Loading...</div>; // Add a loading message or spinner
  }
  return (
    <MainContainer>
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          title={project.title}
          location={project.location}
          price={project.price}
          completion={project.completionDate}
          imageUrl={project.imageUrl}
          statusBadge={project.statusBadge}
          commissionBadge={project.commissionBadge}
          recommendationText={project.recommendationText}
          favorite={favoriteProjects ? !!favoriteProjects[project.id] : false} // Ensure favoriteProjects is defined
          onFavoriteToggle={() => toggleFavorite(project.id)} // Toggle favorite
          onClick={() => handleCardClick(project.id)}
        />
      ))}
    </MainContainer>
  );
};

export default MainContent;
