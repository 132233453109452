// src/components/ProjectCard.js
import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  background-color: #fff;
  border-radius: 12px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border: ${(props) =>
    props.favorite ? "2px solid #264f8e" : "1px solid #e0e0e0"};
`;

const ImageContainer = styled.div`
  position: relative;
  height: 200px;
  background: #f0f0f0;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Badge = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
`;

const CommissionBadge = styled(Badge)`
  top: 10px;
  right: 10px;
  background-color: #0c1244;
  color: #fff;
`;

const RecommendationBadge = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #264f8e;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 0.9rem;
  font-weight: 700;
`;

const ContentContainer = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Location = styled.p`
  color: #555;
  font-size: 0.9rem;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const PriceAndCompletion = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #777;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const Price = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StarIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 24px;
    height: 24px;
    fill: ${(props) =>
      props.favorite ? "#FFD700" : "#CCC"}; // Gold for favorite, gray otherwise
    transition: fill 0.2s;
  }
`;

const ProjectCard = ({
  title,
  location,
  price,
  completion,
  imageUrl,
  statusBadge,
  commissionBadge,
  recommendationText,
  onClick,
  favorite,
  onFavoriteToggle, // New Prop
}) => {
  return (
    <CardContainer onClick={onClick} favorite={favorite}>
      <ImageContainer>
        <Image src={imageUrl} alt={title} />
        {statusBadge && <Badge>{statusBadge}</Badge>}
        {commissionBadge && (
          <CommissionBadge>{commissionBadge}</CommissionBadge>
        )}
        {recommendationText && (
          <RecommendationBadge>{recommendationText}</RecommendationBadge>
        )}

        {/* Add the favorite star */}
        <StarIcon
          favorite={favorite}
          onClick={(e) => {
            e.stopPropagation(); // Prevent navigating to the project
            onFavoriteToggle();
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 .587l3.668 7.431 8.332 1.209-6.001 5.853 1.417 8.27L12 18.896l-7.416 3.884 1.417-8.27-6.001-5.853 8.332-1.209z" />
          </svg>
        </StarIcon>
      </ImageContainer>
      <ContentContainer>
        <Title>{title}</Title>
        <Location>{location}</Location>
        <PriceAndCompletion>
          <Price>Price from {price}</Price>
          <div>Completion {completion}</div>
        </PriceAndCompletion>
      </ContentContainer>
    </CardContainer>
  );
};

export default ProjectCard;
