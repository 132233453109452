// src/components/Units.js
import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  LocationBenefits,
  LocationBlockContainer,
  TitleFlex,
  TitleIcon,
} from "./LocationBlock";
import { PropertyTitle } from "./PropertyDetails";
import architectureIcon from "../assets/architecture.svg";

const UnitsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: 840px) {
    flex-wrap: wrap;
    gap: 0.7rem;
  }
`;

const ToggleButton = styled.button`
  background-color: ${(props) => (props.active ? "#000" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  @media (max-width: 840px) {
    // flex-wrap: wrap;
    padding: 6px 10px;
    font-size: 0.8rem;
  }
`;

const UnitCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const UnitImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 30rem;
  @media (max-width: 840px) {
    height: 15rem;
  }
`;

const UnitInfo = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: column;
`;

const Tag = styled.span`
  background-color: #f2f2f6;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 12px;
  margin-right: 8px;
  display: inline-block;
  margin-top: 4px;
`;

const PriceRange = styled.div`
  margin: 4px 0;
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

const UnitArea = styled.div`
  color: #7f7f7f;
  font-size: 12px;
`;

const CarouselContainer = styled.div`
  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background: #000;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    color: #fff;
  }
  .slick-slide {
    padding: 0 8px; /* Add spacing between slides */
    box-sizing: border-box; /* Prevent unwanted scaling issues */
  }

  .slick-dots li button:before {
    color: #000;
  }
`;

const Units = ({
  units,
  unitType,
  measurementType,
  onUnitTypeToggle,
  onMeasurementToggle,
}) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <UnitsHeader>
        <TitleFlex>
          <TitleIcon>
            <img width="100%" src={architectureIcon} alt="Architecture" />
          </TitleIcon>
          <PropertyTitle>Architecture</PropertyTitle>
        </TitleFlex>
        <UnitsHeader>
          <div>
            <ToggleButton
              active={unitType === "AED"}
              onClick={() => onUnitTypeToggle("AED")}
            >
              AED
            </ToggleButton>
            <ToggleButton
              active={unitType === "USD"}
              onClick={() => onUnitTypeToggle("USD")}
            >
              USD
            </ToggleButton>
            <ToggleButton
              active={measurementType === "SQFT"}
              onClick={() => onMeasurementToggle("SQFT")}
            >
              SQFT
            </ToggleButton>
            <ToggleButton
              active={measurementType === "SQM"}
              onClick={() => onMeasurementToggle("SQM")}
            >
              M²
            </ToggleButton>
          </div>
        </UnitsHeader>
      </UnitsHeader>
      <LocationBlockContainer>
        <CarouselContainer>
          <Slider {...carouselSettings}>
            {units.map((unit, index) => (
              <UnitCard key={index}>
                <UnitImage src={unit.imageUrl} alt={unit.type} />
                <UnitInfo>
                  <div>
                    <Tag>{unit.type}</Tag>
                    <Tag>{unit.bedrooms} bedroom</Tag>
                  </div>
                  <PriceRange>
                    {unitType === "AED" ? unit.priceRange : unit.priceRangeUSD}
                  </PriceRange>
                  <UnitArea>
                    {measurementType === "SQFT" ? unit.size : unit.sizeSqm}
                  </UnitArea>
                </UnitInfo>
              </UnitCard>
            ))}
          </Slider>
        </CarouselContainer>
        <LocationBenefits>
          <ol>
            <li>
              Estamos falando de um apartamento com mármore de verdade no chão.
            </li>
            <li>cozinha equipada com Eletros da marca siemens</li>
            <li>
              Um projeto de interiores extremamente requintado, o que vai te
              impulsionar na venda futura e facilidade de locação.
            </li>
            <li>
              Um projeto que contem cigar lounge, london SPA, Saunas, Zen garden
              para meditação, academia, cinema, kid's play area, Party hall,
              Library, área de games, ping pong, Billars.
            </li>
            <li>
              Além disso conta com serviços como Business center, housekeeping -
              o que também é mais um recurso para locação short term, Branded
              concierge, Valet e segurança 24/7
            </li>
          </ol>
        </LocationBenefits>
      </LocationBlockContainer>
    </div>
  );
};

export default Units;
