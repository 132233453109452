// src/components/OfferLinkPage.js
import React, { useState } from "react";
import styled from "styled-components";
import projectData from "../projectData"; // Import project data or fetch as needed
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  ContentWrapper,
  PropertyDetailsContainer,
  PropertyTitle,
  Section,
  // Import other styled components used in PropertyDetails
  // or define new styled components if needed.
} from "./PropertyDetails";
// Import the new components
import Visualisation from "./Visualisation";
// import Overview from "./Overview";
import LocationBlock from "./LocationBlock";
import Facilities from "./Facilities";
import Units from "./Units";
import PaymentPlan from "./PaymentPlan";
import HeaderSection from "./HeaderSection";

const HeaderContainer = styled.div`
  position: fixed;
  margin top: 2rem;
`;
const BackButton = styled.button`
  background-color: #ffffff;
  color: #000;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  padding: 8px 12px;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 5;
  margin-top: 1rem;
  margin-left: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #00000017;
  }

  @media screen and (min-width: 1280px) {
    text-decoration: none;
  }
`;

const GridOffer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 6rem;
`;

const ContactBoxLast = styled.div`
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

const TitleBlock = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 130%;
  @media (max-width: 840px) {
    font-size: 1.4rem;
  }
`;

const ArrowTextOffer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

const ArrowText = styled.div`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
`;

const ArrowBlock = styled.div`
  background-color: #fff;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AgentCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
  position: relative;
`;

const AvatarBox = styled.div`
  margin-top: -64px;
`;

const AgentAvatar = styled.img`
  border-radius: 50%;
  width: 96px;
  height: 96px;
  object-fit: cover;
`;

const AgentName = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 8px;
`;

const AgentSignature = styled.p`
  color: #8a8a8d;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const TagsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

const VerifiedAgent = styled.div`
  background-color: #0062ff1a;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

const VerifiedLogo = styled.img`
  width: 16px;
  margin-right: 4px;
`;

const VerifiedText = styled.span`
  color: #0084ff;
  font-weight: 500;
`;

const ContactsBox = styled.div`
  margin-top: 16px;
  border-top: 1px solid #000;
  padding-top: 16px;
`;

const ContactField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const FieldName = styled.span`
  color: #8a8a8d;
  font-size: 14px;
`;

const FieldData = styled.span`
  color: #000;
  font-size: 14px;
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`;

const WhatsappButton = styled.a`
  background-color: #00d757;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  width: 100%;
`;

const CallButton = styled.a`
  background-color: #000;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
`;

const CallButtonBottom = styled.a`
  background-color: #000;
  border-radius: 8px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  width: 100%;
`;

const ButtonIcon = styled.img`
  width: 20px;
`;

// const HeaderImage = styled.div`
//   background-image: url(${(props) => props.imageUrl});
//   background-size: cover;
//   background-position: center;
//   height: 16rem;
//   border-radius: 20px;
//   position: relative;
// `;

// const PropertyHeaderOverlay = styled.div`
//   object-fit: fill;
//   background-color: #0009;
//   border-radius: 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   inset: 0;
// `;

// const CompletionDateBlock = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
// `;

// const PropertyHeaderCompletionDate = styled.div`
//   color: #fff;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 16px;
// `;

// const PropertyHeaderName = styled.h1`
//   color: #fff;
//   font-size: 24px;
//   font-weight: 500;
//   margin: 12px 0;
//   text-align: center;
// `;

// const PropertyHeaderLocation = styled.div`
//   color: #fff9;
//   font-size: 14px;
//   font-weight: 400;
// `;

// const InvestorViewsCounterBox = styled.div`
//   background-color: #fff;
//   border: 1px solid #ccff3a;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   height: 48px;
//   margin-top: 16px;
//   padding-left: 8px;
// `;

// const ViewsCounter = styled.span`
//   margin-left: 6px;
//   font-size: 14px;
//   font-weight: 600;
// `;

// const EyeIcon = styled.img`
//   width: 32px;
// `;

// const DeveloperBlock = styled.div`
//   background-color: #fff;
//   border-radius: 12px;
//   padding: 16px;
//   display: flex;
//   align-items: center;
//   margin-top: 3rem;
// `;

// const DeveloperImage = styled.img`
//   object-fit: cover;
//   border-radius: 8px;
//   width: 64px;
//   height: 64px;
// `;

// const TextName = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-left: 10px;
// `;

// const DeveloperText = styled.span`
//   color: #0085ff;
//   font-weight: 500;
// `;

// const DeveloperName = styled.span`
//   color: #000;
//   font-weight: 500;
// `;

const ShareOfferButtonBlock = styled.div`
  z-index: 100;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  padding: 8px;
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  gap: 8px;
`;

const ButtonOffer = styled.button`
  color: #fff;
  text-align: center;
  background-color: #0e2169;
  border: 0px solid #e0e0e0;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-weight: 500;
  display: flex;
  cursor: pointer;
`;

const OfferLinkPage = () => {
  const { id } = useParams(); // Get the project ID from the URL
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Architecture");
  const property = projectData.find((project) => project.id === parseInt(id)); // Fetch property data
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  // const [language, setLanguage] = useState("en");
  const [unitType, setUnitType] = useState("AED"); // Default to AED
  const [measurementType, setMeasurementType] = useState("SQFT"); // Default to SQFT
  const [buttonText, setButtonText] = useState("Copy sales offer link");

  if (!property) {
    return <Container>Property not found</Container>;
  }

  const agent = {
    name: "Weverton Arj",
    title: "Your Property Expert",
    phone: "+971 55 660 5815",
    email: "weverton@escodelar.ae",
    company: "Escodelar",
    photoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/Yf9p5QNcw6lwe7Ut7C0wDqTxo8M/fdvibg../IMG_1244.jpeg",
    verified: true,
  };

  const handleBackClick = () => {
    navigate(`/property/${id}`);
  };
  // Add a new function for handling measurement type toggle
  const handleMeasurementToggle = (type) => {
    setMeasurementType(type);
  };
  // const handleLanguageChange = (lang) => {
  //   setLanguage(lang);
  // };

  const handleToggleClick = (type) => {
    setUnitType(type);
  };

  const handleShareClick = () => {
    // Copy current page URL to clipboard
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        // Change button text to "Link copied!"
        setButtonText("Link copied!");

        // Optionally, revert the text back after a delay (e.g., 3 seconds)
        setTimeout(() => {
          setButtonText("Copy sales offer link");
        }, 3000);
      })
      .catch(() => {
        // Handle error if copying fails
        alert("Failed to copy the link.");
      });
  };

  return (
    <Container>
      <HeaderContainer>
        <BackButton onClick={handleBackClick}>← Back</BackButton>
      </HeaderContainer>

      <ContentWrapper>
        <Section>
          <GridOffer>
            <div>
              <TitleBlock>Hello! This is my project offer for you.</TitleBlock>
              <ArrowTextOffer>
                <ArrowBlock>
                  <img
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/646e23116fc42a55d1467a27_Vector%20189.svg"
                    alt="Arrow"
                  />
                </ArrowBlock>
                <ArrowText>Scroll down to view your offer</ArrowText>
              </ArrowTextOffer>
            </div>
            <AgentCard>
              <AvatarBox>
                <AgentAvatar src={agent.photoUrl} alt={agent.name} />
              </AvatarBox>
              <AgentName>{agent.name}</AgentName>
              <AgentSignature>{agent.title}</AgentSignature>
              <TagsBlock>
                <VerifiedAgent>
                  <VerifiedLogo
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/646f523b52f3bf36c05d6926_Group%201408.png"
                    alt="Verified"
                  />
                  <VerifiedText>Verified Agent</VerifiedText>
                </VerifiedAgent>
              </TagsBlock>
              <ContactsBox>
                <ContactField>
                  <FieldName>Phone</FieldName>
                  <FieldData>{agent.phone}</FieldData>
                </ContactField>
                <ContactField>
                  <FieldName>Email</FieldName>
                  <FieldData>{agent.email}</FieldData>
                </ContactField>
                <ContactField>
                  <FieldName>Company</FieldName>
                  <FieldData>{agent.company}</FieldData>
                </ContactField>
              </ContactsBox>
              <SocialButtons>
                <WhatsappButton
                  href={`https://wa.me/${agent.phone}`}
                  target="_blank"
                >
                  <img
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/6417f5a04e0fbd292fb8bda2_WhatsApp.svg"
                    alt="WhatsApp"
                    className="margin-right-8"
                  />
                  <span className="button-text">WhatsApp</span>
                </WhatsappButton>
                <CallButton href={`tel:${agent.phone}`}>
                  <ButtonIcon
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/6417f63c570b0d970d6f08ac_call.svg"
                    alt="Call"
                  />
                </CallButton>
              </SocialButtons>
            </AgentCard>
          </GridOffer>
        </Section>
        <Section>
          {/* <HeaderImage imageUrl={property.imageUrl}>
            <PropertyHeaderOverlay>
              <CompletionDateBlock>
                <PropertyHeaderCompletionDate>
                  Completion - {property.completionDate}
                </PropertyHeaderCompletionDate>
              </CompletionDateBlock>
              <PropertyHeaderName>{property.title}</PropertyHeaderName>
              <PropertyHeaderLocation>
                {property.location}
              </PropertyHeaderLocation>
            </PropertyHeaderOverlay>
          </HeaderImage>
          <InvestorViewsCounterBox>
            <EyeIcon
              src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/64748af32e8512e0d4ad6b1d_view.png"
              alt="View Icon"
            />
            <ViewsCounter>
              1256 investors are viewing this project now
            </ViewsCounter>
          </InvestorViewsCounterBox> */}
          <HeaderSection
            imageUrl={property.imageUrl}
            developerLogoUrl={property.developerLogoUrl}
            developer={property.developer}
          />
        </Section>
        <Section>
          <PropertyDetailsContainer>
            <PropertyTitle>{property.title}</PropertyTitle>
          </PropertyDetailsContainer>
        </Section>
        <Section>
          <Visualisation
            selectedTab={selectedTab}
            onTabClick={handleTabClick}
            images={
              property.visualizations.find(
                (visual) => visual.type === selectedTab
              )?.images || []
            }
            video={property.videoUrl}
          />
        </Section>
        {/* <Section>
          <Overview
            overview={property.overview[language]}
            language={language}
            onLanguageChange={handleLanguageChange}
          />
        </Section> */}
        <Section>
          <LocationBlock
            location={property.location}
            mapUrl={property.mapUrl}
            nearbyPlaces={property.nearbyPlaces}
          />
        </Section>
        <Section>
          <Facilities facilities={property.facilities} />
        </Section>
        <Section>
          <Units
            units={property.units}
            unitType={unitType}
            measurementType={measurementType}
            onUnitTypeToggle={handleToggleClick}
            onMeasurementToggle={handleMeasurementToggle}
          />
        </Section>
        {/* <Section>
          <DeveloperBlock>
            <DeveloperImage
              src={property.developerLogoUrl}
              alt={property.developer}
            />
            <TextName>
              <DeveloperText>Developer</DeveloperText>
              <DeveloperName>{property.developer}</DeveloperName>
            </TextName>
          </DeveloperBlock>
        </Section> */}
        <PaymentPlan />
        <Section>
          <ContactBoxLast>
            <AgentCard>
              <AvatarBox>
                <AgentAvatar src={agent.photoUrl} alt={agent.name} />
              </AvatarBox>
              <AgentName>{agent.name}</AgentName>
              <AgentSignature>{agent.title}</AgentSignature>
              <TagsBlock>
                <VerifiedAgent>
                  <VerifiedLogo
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/646f523b52f3bf36c05d6926_Group%201408.png"
                    alt="Verified"
                  />
                  <VerifiedText>Verified Agent</VerifiedText>
                </VerifiedAgent>
              </TagsBlock>
              <ContactsBox>
                <ContactField>
                  <FieldName>Phone</FieldName>
                  <FieldData>{agent.phone}</FieldData>
                </ContactField>
                <ContactField>
                  <FieldName>Email</FieldName>
                  <FieldData>{agent.email}</FieldData>
                </ContactField>
                <ContactField>
                  <FieldName>Company</FieldName>
                  <FieldData>{agent.company}</FieldData>
                </ContactField>
              </ContactsBox>
              <SocialButtons>
                <WhatsappButton
                  href={`https://wa.me/${agent.phone}`}
                  target="_blank"
                >
                  <img
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/6417f5a04e0fbd292fb8bda2_WhatsApp.svg"
                    alt="WhatsApp"
                    className="margin-right-8"
                  />
                  <span className="button-text">WhatsApp</span>
                </WhatsappButton>
                <CallButtonBottom href={`tel:${agent.phone}`}>
                  <ButtonIcon
                    src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/6417f63c570b0d970d6f08ac_call.svg"
                    alt="Call"
                  />{" "}
                  Phone call
                </CallButtonBottom>
              </SocialButtons>
            </AgentCard>
          </ContactBoxLast>
        </Section>
        {/* You can reuse other components such as Units, DeveloperDetails, etc., from PropertyDetails */}
      </ContentWrapper>
      <ShareOfferButtonBlock>
        <ButtonOffer onClick={handleShareClick}>{buttonText}</ButtonOffer>
      </ShareOfferButtonBlock>
    </Container>
  );
};

export default OfferLinkPage;
