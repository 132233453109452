// src/components/LocationBlock.js
import React from "react";
import styled from "styled-components";
import { PropertyTitle, Section } from "./PropertyDetails";
import locationIcon from "../assets/location.svg";

const LocationBlockContainer = styled.div`
  background-color: #fff;
  border-radius: 12px;
  margin-top: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

const LocationIframe = styled.iframe`
  width: 100%;
  height: 30rem;
  border-radius: 8px;
  border: 0,
  margin-top: 1rem;
  @media (max-width: 840px) {
    height: 15rem;
  }
`;

const LocationBenefits = styled.div`
  margin: 1rem 1rem 0rem 1rem;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: left;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
`;

const TitleFlex = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const TitleIcon = styled.div`
  width: 2rem;
  height: 2rem;
  margin-bottom: 0;

  @media (max-width: 840px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
// const NameBox = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const AddressText = styled.p`
//   margin-top: 16px;
//   margin-bottom: 16px;
//   font-weight: 500;
// `;

// const NameTimeLocation = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 0;
//   margin-bottom: 12px;
// `;

// const NearbyIcon = styled.img`
//   width: 32px;
//   height: 32px;
//   margin-right: 12px;
// `;

// const LocationName = styled.span`
//   font-size: 16px;
//   font-weight: 600;
// `;

// const DistanceText = styled.span`
//   color: #7f7f7f;
//   font-weight: 500;
// `;

const LocationBlock = ({ mapUrl }) => {
  return (
    <Section>
      <TitleFlex>
        <TitleIcon>
          <img width="100%" src={locationIcon} alt="Location" />
        </TitleIcon>
        <PropertyTitle>Location</PropertyTitle>
      </TitleFlex>
      <LocationBlockContainer>
        <LocationIframe
          src={mapUrl}
          allowFullScreen=""
          title="Project Map"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></LocationIframe>

        {/* <AddressText>{location}</AddressText>

      {nearbyPlaces.map((place, index) => (
        <NameTimeLocation key={index}>
          <NameBox>
            <NearbyIcon
              src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/63c5585ff31e74467d46a176_Group%201529-1.svg"
              alt="Nearby Place Icon"
            />
            <LocationName>{place.name}</LocationName>
          </NameBox>
          <DistanceText>{place.distance}</DistanceText>
        </NameTimeLocation>
      ))} */}

        <LocationBenefits>
          <ol>
            <li>
              A maioria dos projetos nessa região começam em 3 milhões por 1
              quarto.
            </li>
            <li>
              2 min andando do Marina walk onde você tem centenas de
              restaurantes, café e shopping.
            </li>
            <li>
              Um dos prédios mais novos da região, principalmente no que diz
              respeito aos próximos 3 anos.
            </li>
            <li>10 minutes dirigindo até palm Jumeirah.</li>
            <li>
              A região é a área turística mais frequentada de Dubai, o que torna
              esse projeto excelente para locação short therm como Airbnb e
              booking.com
            </li>
            <li>10 minutos andando da Barasti beach.</li>
            <li>8 minutos andando da estação de trem 15 minutos do metrô.</li>
          </ol>
        </LocationBenefits>
      </LocationBlockContainer>
    </Section>
  );
};

export default LocationBlock;
export { LocationBlockContainer, LocationBenefits, TitleFlex, TitleIcon };
