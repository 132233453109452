// src/projectData.js
const projectData = [
  {
    id: 1,
    title: "Al Habtoor Tower",
    developer: "Al Habtoor Group",
    location: "Al Habtoor City",
    city: "Dubai",
    price: "$783,104",
    completionDate: "Q3 2026",
    lastUpdated: "Nov 11, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/mKcualb4TgxrwSoiuU3SUXvN3SM/uH1d-Q../habtoor-tower-dubai.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "On Sale",
    recommended: false,
    videoUrl: "https://youtube.com/embed/4B11dbvEBWk",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55287.49343313313!2d55.253244!3d25.182582!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69ff1e5a1a7f%3A0x73cfc7a24fe9a06b!2sAl%20Habtoor%20Tower!5e1!3m2!1sen!2sus!4v1731878911046!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/_nrnwj9N24xXFppwDwin8EuawiI/p83q8w../1519906006791.jpg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "Al Safa Park", distance: "3.4 km" },
      { name: "Bay Avenue Mall", distance: "4.7 km" },
      { name: "Jumeirah Beach", distance: "4.7 km" },
      { name: "Horizon English School Dubai", distance: "4.9 km" },
      { name: "Downtown", distance: "6.4 km" },
      { name: "Dubai International Airport", distance: "16.5 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/tX9XjBn9T7jaTKv2O7YCy3EVoqw/eLtLng../habtoor-tower-dubai.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/kV9mUgT3BjwpErvMF3lxkzFPukE/trTc5w../habtoor-2.ashx.jpg",
          "	https://api.reelly.io/vault/ZZLvFZFt/nsCpzDh5LCo9ET3ZwQ3Otxs3ksE/pr0HmA../4.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/s0iqm2_Kt8sNEfLtsJIi4a5rWGw/QW-qXg../0.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Kge_i3uT0TwTvXnDrhwxtkkojPQ/MNhl-w../8.jpg",
          "	https://api.reelly.io/vault/ZZLvFZFt/cUHXKv7jTpBYwJ6I-h2BDtsN2ps/SEBc4g../51.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/4Ld53NXXIthKPTEn3y5QOvPZrHM/MwlnYg../17.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/MH56qL6vzUCqWIFv5B672i1dWxg/bhoaIg../18.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/pbE3hpMT1JUqcqyTahmdz4JVbMI/13EQ_w../19.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/wsi_LvMlIAi53BXXp8NqCexcE74/OMIJTA../21.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/P1sNg_DkfOQ6mJg3VAXOfMmW4T0/GSZTAA../22.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6wST-eTM7Ooq9lrUoTWUOOODczY/KQLRsA../23.jpg",
        ],
      },
      {
        type: "Lobby",
        images: [
          "	https://api.reelly.io/vault/ZZLvFZFt/7PdjsK5YqdDZVp8YFm9tAHl0U8s/9WBzxQ../35.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Gracing the famed Dubai skyline, the newest addition to Al Habtoor City — Al Habtoor Tower — awaits. This majestic tower in the most coveted neighborhood, on the banks of the Dubai Water Canal, represents exceptional living elevated to the highest level of luxury in an all-inclusive setting, boasting stunning views of the city and sea.",
          "Al Habtoor Tower is more than a place to call home. It is an ecosystem of opulence — a vertical city where lifestyle takes on a definition entirely its own. From state-of-the-art fitness centers and gravity-defying infinity pools to elegant, spacious apartment homes with breathtaking views of the Dubai skyline, it’s a masterpiece of modern living.",
        ],
        finishingMaterials: "Modern finishes made with high-quality materials",
        kitchenAppliances:
          "Fully equipped kitchens with all necessary furnishings and appliances",
        furnishing: "No",
        locationBenefits: [
          "Al Habtoor City is a prominent multi-use development offering a mix of residential, hospitality, and entertainment facilities. It is located along Sheikh Zayed Road, near the Dubai Water Canal and downtown Dubai.",
          "The centerpiece of Al Habtoor City is the La Perle by Dragone, a state-of-the-art theater hosting a mesmerizing aquatic and acrobatic show by renowned director Franco Dragone. The area also features a vibrant culinary scene, retail outlets, and a waterfront promenade along the canal, providing picturesque settings for leisure and outdoor activities.",
        ],
      },
      pt: {
        generalFacts: [
          "Dominando o famoso horizonte de Dubai, a mais nova adição à Al Habtoor City — a Al Habtoor Tower — está pronta. Esta majestosa torre no bairro mais cobiçado, às margens do Canal de Água de Dubai, representa um estilo de vida excepcional elevado ao mais alto nível de luxo em um ambiente tudo-em-um, com vistas deslumbrantes da cidade e do mar.",
          "A Al Habtoor Tower é mais do que um lugar para chamar de lar. É um ecossistema de opulência — uma cidade vertical onde o estilo de vida ganha uma definição totalmente própria. De centros de fitness de última geração e piscinas infinitas a apartamentos elegantes e espaçosos com vistas de tirar o fôlego do horizonte de Dubai, é uma obra-prima da vida moderna.",
        ],
        finishingMaterials:
          "Acabamentos modernos feitos com materiais de alta qualidade",
        kitchenAppliances:
          "Cozinhas totalmente equipadas com todos os móveis e eletrodomésticos necessários",
        furnishing: "Não",
        locationBenefits: [
          "A Al Habtoor City é um desenvolvimento multiuso proeminente que oferece uma combinação de instalações residenciais, de hospitalidade e de entretenimento. Está localizada ao longo da Sheikh Zayed Road, perto do Canal de Água de Dubai e do centro de Dubai.",
          "O destaque da Al Habtoor City é o La Perle by Dragone, um teatro de última geração que apresenta um show aquático e acrobático hipnotizante criado pelo renomado diretor Franco Dragone. A área também oferece uma cena culinária vibrante, lojas de varejo e um calçadão à beira do canal, proporcionando cenários pitorescos para lazer e atividades ao ar livre.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/aKAF6kA9B0ZJwo29uyaY9QOw9Ac/NOskkA../%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2023-06-22+%D0%B2+17.55.37.png",
    buildings: [
      {
        name: "Skyscrapper",
        description: "1, 2 & 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/mj1CbOzXgRNPkyLZLovab6vV_mI/8V1w1w../habtoor-2.ashx.jpg",
      },
    ],
    facilities: [
      {
        name: "Infinity Pools with 270° Views of the Dubai Skyline",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/6ao5GKThj3v-Z0OgBrNHJhfGXPQ/pQKsxA../1.jpg",
      },
      {
        name: "Multiple Gyms and Fitness Spaces",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Ff3a-OVNPhIXbY9VtgREavIxah4/1won4Q../11.jpg",
      },
      {
        name: "Padel Tennis Courts",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/KDhT0KDmZ6BX6H8R3OI6M_CFeNc/J_RfbQ../10.jpg",
      },
      {
        name: "Luxurious Spa, Steam and Sauna Facilities",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Jz29EHL2gFmwICOCWG-sEIZVKE8/cJ9O8w../13.jpg",
      },
      {
        name: "Kids Play Areas, Designed with Safety in Mind",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xooQbl_d9S7Y_x20xk2hKtyuTsE/xke16Q../14.jpg",
      },
      {
        name: "Sports Cafe",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/9xuPcBSOku2NS8bj4Q4rTotBg7Q/MxJbDw../15.jpg",
      },
      {
        name: "Sky Restaurant",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/0H4lsRWWY-9BC4oe2dmKmR6ACQ8/YFhMIg../16.jpg",
      },
    ],
    units: [
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 2,873,995 — AED 3,917,373",
        priceRangeUSD: "$782,400 — $1,066,500",
        size: "1045 sqft — 1285 sqft",
        sizeSqm: "97.1 sqm — 119.4 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/LkQ6VM8JEAqhNjzZD-FkJKjT184/kmtHgg../st+al.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 3,873,381 — AED 5,595,015",
        priceRangeUSD: "$1,054,500 — $1,523,200",
        size: "1468 sqft — 2267 sqft",
        sizeSqm: "136.4 sqm — 210.6 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/r1A8YtBdDwMRxU5d-NigNvworFI/UAeEeA../1+al.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 7,319,953 — AED 14,677,840",
        priceRangeUSD: "$1,992,900 — $3,996,000",
        size: "2170 sqft — 3167 sqft",
        sizeSqm: "201.6 sqm — 294.2 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/6hd8GMhvbZnKFoylfmkNfkMFIk0/6QSRbQ../2+al.png",
      },
      {
        type: "Sky Villa",
        bedrooms: 3,
        priceRange: "AED 20,693,977 — AED 26,785,974",
        priceRangeUSD: "$5,633,900 — $7,292,500",
        size: "3349 sqft — 4334 sqft",
        sizeSqm: "311.1 sqm — 402.6 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/kKe1Po6MKEFHorqBrdAPAW7TFWY/edwX7w../3b+SV.png",
      },
    ],
    developerDetails: {
      name: "Al Habtoor Group",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/_nrnwj9N24xXFppwDwin8EuawiI/p83q8w../1519906006791.jpg",
      description:
        "The Al Habtoor Group has grown with the United Arab Emirates. What started out as a small engineering firm in 1970, is today one of the region’s most respected conglomerates with interests in the hospitality, automotive, real estate, education, and publishing sectors.",
      website: "https://www.habtoor.com/en/",
      email: "phine@alhabtoorrealestate.com",
      address: "Al Habtoor Group Head Office, Al Safa 2, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        weekends: "Saturday - Sunday",
        weekendHours: "Day off",
      },
    },
    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 2,
    title: "Habtoor Grand Residences",
    developer: "Al Habtoor Group",
    location: "Dubai Marina",
    city: "Dubai",
    completionDate: "MAR 2027",
    lastUpdated: "Nov 11, 2024",
    price: "$3,022,238",
    imageUrl: "/images/1.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "Recommended",
    recommendationText: "Recommended",
    recommended: true,
    videoUrl: "https://www.youtube.com/embed/az8dvdAx9Mc",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57814.6875092546!2d55.139592!3d25.087709!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b003d26e78d%3A0x35b576c5daaa93bb!2sThe%20Residences%2C%20Al%20Habtoor%20Grand!5e0!3m2!1sen!2sus!4v1729714423899!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/_nrnwj9N24xXFppwDwin8EuawiI/p83q8w../1519906006791.jpg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    notice: "The development comprises four individual buildings...",
    nearbyPlaces: [
      { name: "Atlantis", distance: "1.2 km" },
      { name: "JBR Beach", distance: "1.7 km" },
      { name: "Blossom Marina Nursery Dubai", distance: "2.8 km" },
      { name: "Dubai Marina Mall", distance: "5.2 km" },
      { name: "Downtown Dubai", distance: "23.3 km" },
      { name: "Dubai International Airport", distance: "35.9 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/kMNJUCRl5rEKZ6P4k1lVp43mHUA/wkSgzQ../0.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/BLq5tJOyFzHddSjAdsY16yJ0aHM/Dbe2TA../1.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dHgBekLzq_7OK3r_-UfEtYshQ9s/Uu120g../habtoor_grand_residences_1_35f137012e.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/ezOH4TunuQDX5GDWJJFFbVg9k8o/0ZLiNg../habtoor_grand_residences_6_318aa5aab4.jpeg",

          "https://api.reelly.io/vault/ZZLvFZFt/tNV8Sn0TVAJxdeeLx4Xr692M2OE/njlyFQ../WhatsApp+Image+2024-01-11+at+12.00.31+%281%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/B0BrbOUeIClhcY7s1WuhrjAg2lo/0WZj1g../WhatsApp+Image+2024-01-11+at+12.00.31+%282%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/ekEieoOjpQH7BzOQmKHR0DL4vGQ/Y85g3w../WhatsApp+Image+2024-01-11+at+12.00.31.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/DrBBLW8ZlCzHQ15DrVz3sfGIp94/3qYPow../WhatsApp+Image+2024-01-11+at+12.00.32+%281%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/JdZlOS-CETvBFHlJnaqywb_PIUw/skCz-w../WhatsApp+Image+2024-01-11+at+12.00.32+%282%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/2Hpn5gMnPvwjtxxylNDESPpdotw/8wUvhg../WhatsApp+Image+2024-01-11+at+12.00.32.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/ioeB8EekCx2DK6zdaqhcUeg4hdk/6Xg08g../WhatsApp+Image+2024-01-11+at+12.00.33+%281%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/Fmmt22yH6YHeocVAEMG-lN1-sGE/5Pl0Cw../WhatsApp+Image+2024-01-11+at+12.00.33.jpeg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Indulge in opulent living at Habtoor Grand Residences, a luxury tower nestled in the heart of Dubai Marina, within the renowned 5-star Al Habtoor Grand Resort Hotel on Jumeirah Beach Residence (JBR). This exclusive residence offers direct access to the pristine beach and boasts breathtaking views of the sea, Bluewaters, Palm Jumeirah, and Dubai Marina.",
          "Designed for ultimate comfort and sophistication, each residential unit provides seamless access to the hotel's premium amenities, enhancing the unparalleled lifestyle experience. Enjoy the epitome of luxury, tranquility, and convenience in every aspect of your waterfront residence at Habtoor Grand Residences.",
        ],
        finishingMaterials: "Modern Finishing",
        kitchenAppliances: "Equipped kitchen",
        furnishing: "No",
        locationBenefits: [
          "Dubai Marina is an iconic and prestigious waterfront community situated along the shoreline of Dubai. As one of the city's most sought-after neighborhoods, it offers a captivating blend of urban sophistication, leisure, and breathtaking views.",
          "The centerpiece of Dubai Marina is the man-made canal, which stretches over three kilometers and is surrounded by a collection of stunning high-rise buildings and luxurious waterfront residences. This vibrant development is known for its modern architecture and impressive skyline, making it a distinctive landmark in the city.",
        ],
      },
      pt: {
        generalFacts: [
          "Desfrute de uma vida opulenta no Habtoor Grand Residences, uma torre de luxo situada no coração de Dubai Marina, dentro do renomado hotel 5 estrelas Al Habtoor Grand Resort na Jumeirah Beach Residence (JBR). Esta residência exclusiva oferece acesso direto à praia imaculada e possui vistas deslumbrantes do mar, Bluewaters, Palm Jumeirah e Dubai Marina.",
          "Projetado para o máximo conforto e sofisticação, cada unidade residencial oferece acesso direto às comodidades premium do hotel, aprimorando a experiência de vida incomparável. Desfrute do auge do luxo, tranquilidade e conveniência em todos os aspectos de sua residência à beira-mar no Habtoor Grand Residences.",
        ],
        finishingMaterials: "Acabamento moderno",
        kitchenAppliances: "Cozinha equipada",
        furnishing: "Não",
        locationBenefits: [
          "Dubai Marina é uma comunidade icônica e prestigiada à beira-mar situada ao longo da costa de Dubai. Como um dos bairros mais procurados da cidade, oferece uma mistura cativante de sofisticação urbana, lazer e vistas deslumbrantes.",
          "O destaque de Dubai Marina é o canal artificial, que se estende por mais de três quilômetros e é cercado por uma coleção de arranha-céus impressionantes e residências luxuosas à beira-mar. Este desenvolvimento vibrante é conhecido por sua arquitetura moderna e horizonte impressionante, tornando-se um marco distintivo na cidade.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/I_sjF4a3S6hOo5_VcLMvfHsRB74/bpfCsA../AHGR_Location+map_LS_page-0001.jpg",
    buildings: [
      {
        name: "Tower",
        description:
          "2, 3, 4 bedroom apartments & 3, 4 bedroom townhouses & 4 bedroom penthouses",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/GvtkyudiluEh2MzrcPKBZqE7EuI/B-qxbA../Frame+171.png",
      },
    ],
    facilities: [
      {
        name: "Waterfront property rooftop infinity pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/5GtvyIV7QbeVl8YoU4PjLrY1Sl0/5-vY_w../14.jpg",
      },
      {
        name: "Multiple restaurants & bars",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/7z7lCaVjWXflUrwCvtiPpNj7hOk/C2_-WA../27.jpg",
      },
      {
        name: "Gymnasium",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/MT-J7o-ONVVI0vLJGGSn4pjZhpI/sEPf_w../BackToGymSWEATf1f07a7f6f79e7b880.jpg",
      },
      {
        name: "Kids play area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/BpqdwySN1GsLTr0pP7O-KPcT9Mc/MNSV6A../child-on-playground-swing-kids-p.jpg",
      },
      {
        name: "Steam, sauna, jacuzzi & spa",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Y0_PBfP_QsPTsk74L389TXtonvE/i7ByIg../attractive-african-woman-enjoyin.jpg",
      },
      {
        name: "Outdoor tennis court",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/mE9T6t-Jg7xOFejR3CZ2HW9zTEg/kNOtHg../56.jpg",
      },
    ],
    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 11,091,615 — AED 12,170,846",
        priceRangeUSD: "$3,000,000 — $3,300,000",
        size: "2101 sqft — 2323 sqft",
        sizeSqm: "195 sqm — 216 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/w7wIvd4z-CF_EuUIUvesKSxKCxA/Fdo0sw../2.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 13,937,907 — AED 18,092,416",
        priceRangeUSD: "$3,800,000 — $4,925,000",
        size: "2844 sqft — 3358 sqft",
        sizeSqm: "264 sqm — 312 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/b7rClsoYeqD_V1xFwLIfECSIQ18/jCgU1Q../3.png",
      },
      {
        type: "Townhouse",
        bedrooms: 3,
        priceRange: "AED 17,590,113 — AED 0",
        priceRangeUSD: "$4,790,000 — $0",
        size: "4258 sqft — 0 sqft",
        sizeSqm: "396 sqm — 0 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xIAlAo79iTRreSc4CSdOTnEObhU/m5yg1w../3th.png",
      },
      {
        type: "Apartments",
        bedrooms: 4,
        priceRange: "AED 20,839,349 — AED 23,172,246",
        priceRangeUSD: "$5,675,000 — $6,300,000",
        size: "4115 sqft — 4618 sqft",
        sizeSqm: "382 sqm — 429 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/okDpgi4zOvy38cJ691d2UV1dCDI/ZWgc0w../4.png",
      },
      {
        type: "Townhouse",
        bedrooms: 4,
        priceRange: "AED 23,694,767 — AED 31,182,547",
        priceRangeUSD: "$6,450,000 — $8,490,000",
        size: "4592 sqft — 7989 sqft",
        sizeSqm: "427 sqm — 742 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Z2-kt4sAcR2XiDKhdA17CNHCfwo/2hjMTw../4th.png",
      },
      {
        type: "Penthouse",
        bedrooms: 4,
        priceRange: "AED 60,000,000 — AED 70,000,000",
        priceRangeUSD: "$16,335,000 — $19,080,000",
        size: "6330 sqft — 6940 sqft",
        sizeSqm: "588 sqm — 645 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ellCYuUlDM9k2KS3NQWCpvIiXD8/yquYgg../4ph.png",
      },
    ],
    developerDetails: {
      name: "Al Habtoor Group",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/_nrnwj9N24xXFppwDwin8EuawiI/p83q8w../1519906006791.jpg",
      description:
        "The Al Habtoor Group has grown with the United Arab Emirates. What started out as a small engineering firm in 1970, is today one of the region’s most respected conglomerates with interests in the hospitality, automotive, real estate, education, and publishing sectors.",
      website: "https://www.habtoor.com/en/",
      email: "phine@alhabtoorrealestate.com",
      address: "Al Habtoor Group Head Office, Al Safa 2, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        weekends: "Saturday - Sunday",
        weekendHours: "Day off",
      },
    },
    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 3,
    title: "Armani Beach Residence",
    developer: "Arada",
    location: "Palam Jumeriah",
    city: "Dubai",
    completionDate: "DEC 2026",
    lastUpdated: "Nov 18, 2024",
    price: "$6,267,029",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/9eZTplVTFHjZUkBaBj1Rs4MIFRE/O_tyAw../2.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "On Sale",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/JbfVnBr_DcE",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d67723.546244467!2d55.146562!3d25.138312!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b7e14ae1e25%3A0xd867a188dd73833a!2sArmani%20Beach%20Residences%20at%20Palm%20Jumeirah!5e1!3m2!1sen!2sus!4v1732015702789!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/RObCfhjql25tFXXIjxXQ0-akKRM/wft6Gw../1537897749528.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    notice: "The development comprises four individual buildings...",
    nearbyPlaces: [
      { name: "Nakheel Mall", distance: "7.5 km" },
      { name: "Al Ittihad Park", distance: "8.7 km" },
      { name: "Asya's Nursery", distance: "11.1 km" },
      { name: "Downtown Dubai", distance: "29.3 km" },
      { name: "Dubai International Airport", distance: "43.1 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/VPQFk1qubVjeKF7A_Ai1Q7rkFVU/qzGTuw../2.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dwtiQWCXkMoH4sDXZ0jfGPK61rw/3rQFUw../4.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/du4k8tCaeSnZLT3NaUoWtE1GmUY/jv2dRw../21.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/SAxrfoS6erYkG3nn8HQ7BZ7b8_k/6lF6MA../0.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/_Bm5az6JOBe3BZ7sq30tfRC-5bc/fuM58Q../12.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6tF8LbOoShTVR-atRpznZlbQTmA/fh2ESw../13.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/AyEl9s13mdjBptcNRQ2qLDunUkc/u-aIgg../22.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/kWHWCw86ZTpT7xXHJBjC-o0mQBQ/1P6aBg../23%20(2).jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/zIzl-l2hZZ_wEfRWZDh02VyRHLA/P4Pr6A../23.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/KnQ9OMaKmOu60VMMeGTze6YzC-8/2Oyyxw../24%20(2).jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/SkeZJoTrvXfzR0sXloqYHpsATFE/sj1lMw../24.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6WlghUbDChD8iNX7C1ows9ILpR8/gyD3ew../25%20(2).jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/b3NtKGri_1hNHpCdnIGdmrWbC6A/OeXfmw../25.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XK6gkRynRWR3JWiH_gmswGKia5A/6jqK7Q../26%20(2).jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/P8Becq3H_e-cCjxsQ5wmY0C-WOM/Brj2pw../26.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/DsC2x--u0Me2FmidVs8zhiUJU8s/ZJO5Rw../27.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ECbxpv07ue2FRQg9G3oWKuChKWE/kA3BqQ../28.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/qQ2xSlY1HAHopvQGkG4_hm7NFWI/4oFR-A../29.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/1JfbX9g30Lqv6ex8R38FiPT-m3k/Q5SZdg../30.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/vR_9BBxAPb6xm_odbNCCTgXlRBM/bzKYVw../31.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/JJKfgGyL73MBGGjupsB5zl-1a5A/8t--vA../32.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/K8h3idriQOjfzXl9u7T455JnbaI/THW74w../33.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/eFVmKFwJjtlG3BHKfvBaa_ecna0/u41kew../34.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/kK1Schdbn8zRMkvUsyGFS9b6FHk/m9Q5zw../35.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Ha1YNutfJcKPNA1k6j8nFexvOJo/DNsd2A../36.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Ytjx2UoF6GcG6ObPryam10y3QgU/Rz6tsA../37.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/l16XOudnbIJ_778M8ZwXfjIqxuE/35lcTg../38.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/gAH8_y961ggrLLn3HbARDe3t31A/cYr5ZQ../6.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/v7JJeTQj-pfOlAdMbAvf0u5dliY/wsmXiw../7.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Armani Beach Residences at Palm Jumeirah marks a historic collaboration between Giorgio Armani and Tadao Ando, brought together by master developer Arada. This is their first and only joint residential project, epitomizing ultra-luxury living on the shores of Dubai’s iconic Palm Jumeirah.",
          "Boasting panoramic views of the Arabian Gulf and Dubai’s skyline, the design harmonizes architecture, the seascape, and the senses. Featuring exemplary architecture, elevated entryways, stylish design elements, and water features, the residences redefine opulence.",
          "Residents enjoy exclusive amenities, including a premium spa, function room, cigar lounge, private beach, and more. Interiors feature limited-edition pieces designed by Giorgio Armani, creating a distinct and sophisticated aesthetic.",
        ],
        finishingMaterials: [
          "High-end fittings provided by Armani/Casa, including designed kitchens, bathrooms, wallpaper with metal profiles, metal skirting, and spotlights.",
          "Owners can choose between a light or dark design palette. Circular or linear foyers serve as transitional spaces, and living areas feature ceilings at least 3 meters high.",
        ],
        kitchenAppliances: [
          "Open kitchens feature joinery and accessories from Armani/Casa's Checkers Collection (light palette) or Bridge Collection (dark palette).",
          "Closed kitchens include an oven, fridge/freezer, dishwasher, washing machine/dryer.",
        ],
        furnishing:
          "Unfurnished apartments with optional Armani-branded furnishings for presidential suites.",
        locationBenefits: [
          "Palm Jumeirah is an iconic community located on a man-made island shaped like a palm tree. It offers a mix of luxury apartments, villas, and townhouses.",
          "The community boasts stunning views, pristine beaches, and world-class amenities, including Atlantis, The Palm resort with dining and entertainment options, water sports, and more.",
        ],
      },
      pt: {
        generalFacts: [
          "Armani Beach Residences em Palm Jumeirah marca uma colaboração histórica entre Giorgio Armani e Tadao Ando, promovida pelo desenvolvedor Arada. Este é o primeiro e único projeto residencial conjunto, simbolizando um estilo de vida ultra-luxuoso nas margens do icônico Palm Jumeirah em Dubai.",
          "Com vistas panorâmicas do Golfo Árabe e do horizonte de Dubai, o design harmoniza arquitetura, a paisagem marítima e os sentidos. Apresentando arquitetura exemplar, entradas elevadas, elementos de design sofisticados e recursos aquáticos, as residências redefinem a opulência.",
          "Os residentes desfrutam de comodidades exclusivas, incluindo um spa premium, sala de eventos, lounge de charutos, praia privada e muito mais. Os interiores apresentam peças de edição limitada projetadas por Giorgio Armani, criando um estilo distinto e sofisticado.",
        ],
        finishingMaterials: [
          "Acabamentos de alto padrão fornecidos pela Armani/Casa, incluindo cozinhas, banheiros, papel de parede com perfis metálicos, rodapés metálicos e iluminação embutida.",
          "Os proprietários podem escolher entre uma paleta de design clara ou escura. Foyers circulares ou lineares servem como espaços de transição, e as áreas de estar apresentam tetos com pelo menos 3 metros de altura.",
        ],
        kitchenAppliances: [
          "Cozinhas abertas apresentam marcenaria e acessórios da Coleção Checkers (paleta clara) ou Coleção Bridge (paleta escura) da Armani/Casa.",
          "Cozinhas fechadas incluem forno, geladeira/freezer, lava-louças e máquina de lavar/secar.",
        ],
        furnishing:
          "Apartamentos não mobiliados com opcionais de móveis com a marca Armani para suítes presidenciais.",
        locationBenefits: [
          "Palm Jumeirah é uma comunidade icônica localizada em uma ilha artificial em forma de palmeira. Oferece uma mistura de apartamentos de luxo, vilas e casas geminadas.",
          "A comunidade oferece vistas deslumbrantes, praias imaculadas e comodidades de classe mundial, incluindo o resort Atlantis, The Palm, com opções de gastronomia, entretenimento, esportes aquáticos e muito mais.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/LDqjcPNyzbhyNf8Yf2AhjeKC9w4/-AbcxA../WhatsApp+Image+2024-05-22+at+10.49.09.jpeg",
    buildings: [
      {
        name: "Building",
        description:
          "2, 3, 4, 5 bedroom apartments & 5 bedroom penthouses & 5 bedroom presidental suites",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xj-ha2B17yc977BzDiy05HB7Y-U/HO7O_w../2.jpg",
      },
    ],
    facilities: [
      {
        name: "Gym",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/T-ZeFUUtaRtbKVcnqR2U3RaFtHM/MSa0Ww../BackToGymSWEATf1f07a7f6f79e7b880.jpg",
      },
      {
        name: "Sauna & Ice-bath",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/pnsJNRDmXqsgGzq_VdCuVsfFUlE/qbzoAA../WhatsApp+Image+2024-05-22+at+10.49.11+%281%29.jpeg",
      },
      {
        name: "Indoor Swimming Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0fVsg2g6J5zrevgU2YLJl493FM/j-9mDw../39.jpg",
      },
      {
        name: "Lushly Landscaped Deck Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/CEfOPu5Phb4ekA5tlZdNhcGakl0/0ka34g../40.jpg",
      },
      {
        name: "Large-scale Infinity Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/QuoEe8k2f9nrnk8flblLOShdl9M/uZpgHQ../42.jpg",
      },
      {
        name: "Separate Children’s Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/fXGX0h39oFom_E3Sk7tH4hFmefs/_cvBqw../summer-5399447_1280.jpg",
      },
      {
        name: "Cigar Lounge",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/MR9YwHfg-r7BYOGU7NTKUkrvzWA/N7_tBA../Cigar-lounge-design-Ritz-Carlton.jpg",
      },
      {
        name: "Private Beach",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/KPREAet2YAUPV0Ski--_k0nbKk4/5Uhrxw../waldorf-astoria-dubai.jpg",
      },
      {
        name: "Movie Theatre",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/s3SlvO7OsKY48WQkkG-QEfbYTCk/wZLmNQ../53.jpg",
      },
      {
        name: "Children’s Playroom",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/IzAx6cOJCvgm07pGnd8GOtWgGnM/7bIF2A../Jane_Beiles-4812.jpg",
      },
    ],
    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 23,000,000 — AED 27,000,000",
        priceRangeUSD: "$6,261,800 — $7,350,800",
        size: "2657 sqft — 3373 sqft",
        sizeSqm: "246.8 m² — 313.4 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Yb8bXnb447jLj-bGsM5VuE-xjSM/fPrmTQ../2.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 37,500,000 — AED 46,500,000",
        priceRangeUSD: "$10,209,400 — $12,659,600",
        size: "4652 sqft — 5790 sqft",
        sizeSqm: "432.2 m² — 537.9 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/NyOYkIR0ahaC3Q4cdqDxDM80q1k/j4KqaA../3.png",
      },
      {
        type: "Apartments",
        bedrooms: 4,
        priceRange: "AED 57,500,000 — AED 0",
        priceRangeUSD: "$15,654,400 — $0",
        size: "7191 sqft — 0 sqft",
        sizeSqm: "668.1 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ZXmBF4FrLpdCLw7TArJtHG9-avE/fy6EcA../4.png",
      },
      {
        type: "Apartments",
        bedrooms: 5,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "7500 sqft — 8500 sqft",
        sizeSqm: "696.8 m² — 789.7 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/-vz-Vd0cgq4cSHx0YVO0XPAFUMc/JuVRfQ../5.png",
      },
      {
        type: "Penthouse",
        bedrooms: 5,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "9500 sqft — 11600 sqft",
        sizeSqm: "882.6 m² — 1,077.7 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/yiiI5O3_GLyHwDjLU9awf8NL9Tg/6JLzJg../5ph.png",
      },
      {
        type: "Suite",
        bedrooms: 5,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "30949 sqft — 43830 sqft",
        sizeSqm: "2,875.3 m² — 4,071.9 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/yvbfcrRxQSsaezuSEkrLFPE9WL4/zhXnMw../5ps.png",
      },
    ],
    developerDetails: {
      name: "Arada",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/RObCfhjql25tFXXIjxXQ0-akKRM/wft6Gw../1537897749528.jpeg",
      description:
        "Arada is a prominent real estate developer based in the UAE, known for creating master-planned communities that blend modern design with a strong focus on sustainability. Founded in 2017, Arada quickly established itself as a leader in the region by developing innovative projects aimed at providing high-quality homes and fostering a vibrant, inclusive community experience. The company’s flagship developments, such as Aljada and Masaar in Sharjah, showcase their commitment to smart urban living, incorporating green spaces, cutting-edge amenities, and convenient access to essential services. Driven by a vision to create spaces that inspire and enhance quality of life, Arada emphasizes sustainable architecture and energy-efficient technologies. Their projects are designed to offer a balance between luxury and affordability, attracting a diverse range of residents. With a reputation for timely project delivery and customer-centric service, Arada continues to expand its portfolio, shaping the future of urban living in the UAE and beyond.",
      website: "https://www.arada.com/en/",
      email: "info@arada.com",
      address: "Al Suyoh Suburb, Tilal, Sharjah, UAE",
      workingTime: {
        weekdays: "Monday - Sunday",
        weekdayHours: "10 AM - 7 PM",
      },
    },
    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 4,
    title: "Binghatti Hillviews",
    developer: "Binghatti",
    location: "Dubai Science Park",
    city: "Dubai",
    price: "$272,479",
    completionDate: "Q1 2025",
    lastUpdated: "Nov 11, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/SJG57j9m3Q7BDOwjPVGWHWbSXNM/Mz_TrA../cover.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "On Sale",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/WDiZAhc4apg", //PlaceHolder Video
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55409.88531679916!2d55.248944!3d25.078556!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f4a1f005589%3A0x8c3f659aba57948!2sBinghatti%20Hillviews!5e1!3m2!1sen!2sus!4v1732018611347!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "Green Escape Park", distance: "1.5 km" },
      { name: "Smart Start Kindergarten", distance: "1.9 km" },
      { name: "My City Centre Al Barsha", distance: "2.2 km" },
      { name: "Public Beach", distance: "11.8 km" },
      { name: "Downtown Dubai", distance: "18.7 km" },
      { name: "Dubai International Airport", distance: "33.5 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/j1BCSggGsVb1rb9V1J2OuAjKqLo/fusDJQ../img22.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/KWccPhGDRI9gRAKsfqb1z8TH9Zo/rXa_HQ../img46.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/zcAwYNIw5_f5M3DBj0FLwAu6NoU/Utb2eg../img94.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/36bZ1l2PDF5MaBXu4Gj5yEybhJM/jYVCPg../img32.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/m8nz1ia4dF5yALglug-kdZNxEPo/3t-Clw../img131.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/aqgAoETP_UUu2AW1MigUAAtg7NA/6YZs8A../img157.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/QMVQRB5yT7Sabh4zR7Gc8fygVEU/lUNabw../img182.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/xHfuiaAK8l4Pfzt2tJBGe52Kbn0/rYi1Fw../img323.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/lYXi3-dPhoJ8zx8Jj8bvO1tQfy8/K90ScQ../img34.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XjAwqK-BxOj0G2h4ElKvhCAG-Kk/Jm25rw../img38.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/2PA4aWXpcfNLt3NIpAi22FsUvy8/Bt6QjQ../img108.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XdYNXIEoi-qMEekch3CTP6DQVSA/3ENKoQ../img102.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Binghatti Hillviews is a visionary project set within the vibrant Dubai Science Park, where ambition and innovation converge. This vertical tower of progress is designed for those who seek more than just a home—it offers a lifestyle that thrives at the intersection of community, technology, and sustainability. The building’s striking façade, crafted from a blend of organic materials and satin chrome, exudes sustainable elegance, reflecting the forward-thinking spirit of the project. Each structural element is a nod to the future, creating spaces where refinement and limitless potential come together.",
          "Inside Binghatti Hillviews, residents are greeted by open-plan residences that are flooded with natural light, thanks to expansive windows that offer sweeping views of Dubai’s skyline. These thoughtfully designed homes blur the lines between indoor and outdoor living, providing a serene yet dynamic environment where comfort meets aspiration. Whether you choose a cozy studio or an expansive two-bedroom apartment, the spaces are designed to inspire personal growth and offer a refined, modern lifestyle.",
          "Beyond the living spaces, Hillviews fosters a sense of community and well-being through a range of world-class amenities. Residents can relax by the sun-drenched pool deck, rejuvenate in the outdoor fitness area or basketball court, or find peace in the yoga garden surrounded by lush greenery. For families, there are dedicated kids’ pools and play areas, creating a welcoming environment for all. With every detail thoughtfully considered, Hillviews offers a place where personal ambitions can thrive alongside a balanced, fulfilling lifestyle.",
        ],
        finishingMaterials:
          "The project features a luxurious blend of materials, including wood porcelain, fluted travertine, champagne metal, porcelain tiles, travertine marble, Calacatta Greek stone, wood veneer, and glass, all complemented by refined accents like Corian, fabric, and mirrors.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No.",
        locationBenefits: [
          "Dubai Science Park is a vibrant hub for innovation and research, dedicated to fostering the growth of the scientific, healthcare, and pharmaceutical industries. Strategically located in the heart of Dubai, the area is home to cutting-edge laboratories, research centers, and a wide array of businesses focused on life sciences, healthcare, and technology. The community is designed to support collaboration and innovation, making it an ideal location for entrepreneurs, researchers, and companies aiming to push the boundaries of scientific discovery.",
          "Beyond its business and research infrastructure, Dubai Science Park offers a well-balanced lifestyle for its residents. The area features a variety of modern residential options, ranging from stylish apartments to family-friendly homes, all designed to complement the professional atmosphere of the district. The park-like setting, with green spaces and landscaped areas, creates a peaceful environment where residents can unwind, while the close proximity to essential services such as clinics, pharmacies, and wellness centers adds to the convenience of living in the area.",
          "Dubai Science Park's prime location provides excellent connectivity to the rest of the city. It is just a short drive from major areas like Dubai Marina, Jumeirah Beach, and Mall of the Emirates, making it easy for residents and professionals to enjoy the best of what Dubai has to offer. The combination of a forward-thinking professional environment and a comfortable, modern lifestyle makes Dubai Science Park a unique destination for those looking to live and work at the forefront of innovation.",
        ],
      },
      pt: {
        generalFacts: [
          "Binghatti Hillviews é um projeto visionário situado no vibrante Dubai Science Park, onde a ambição e a inovação se encontram. Esta torre vertical de progresso é projetada para aqueles que buscam mais do que apenas uma casa—ela oferece um estilo de vida que prospera na interseção entre comunidade, tecnologia e sustentabilidade. A fachada impressionante do edifício, feita de uma mistura de materiais orgânicos e cromo acetinado, exala elegância sustentável, refletindo o espírito inovador do projeto. Cada elemento estrutural é uma referência ao futuro, criando espaços onde o refinamento e o potencial ilimitado se encontram.",
          "Dentro do Binghatti Hillviews, os residentes são recebidos por residências de plano aberto inundadas de luz natural, graças a janelas expansivas que oferecem vistas panorâmicas do horizonte de Dubai. Estas casas, cuidadosamente projetadas, borram as linhas entre o interior e o exterior, proporcionando um ambiente sereno, mas dinâmico, onde o conforto encontra a aspiração. Seja escolhendo um estúdio aconchegante ou um apartamento de dois quartos espaçoso, os espaços são projetados para inspirar o crescimento pessoal e oferecer um estilo de vida refinado e moderno.",
          "Além dos espaços residenciais, o Hillviews fomenta um senso de comunidade e bem-estar por meio de uma série de comodidades de classe mundial. Os residentes podem relaxar no deck da piscina ensolarado, rejuvenescer na área de fitness ao ar livre ou na quadra de basquete, ou encontrar paz no jardim de yoga cercado por vegetação exuberante. Para as famílias, há piscinas dedicadas para crianças e áreas de recreação, criando um ambiente acolhedor para todos. Com cada detalhe cuidadosamente considerado, o Hillviews oferece um lugar onde as ambições pessoais podem prosperar junto a um estilo de vida equilibrado e gratificante.",
        ],
        finishingMaterials:
          "O projeto apresenta uma mistura luxuosa de materiais, incluindo porcelanato de madeira, travertino estriado, metal champanhe, pisos de porcelanato, mármore travertino, pedra Calacatta grega, folheado de madeira e vidro, todos complementados por acabamentos refinados como Corian, tecido e espelhos.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não.",
        locationBenefits: [
          "O Dubai Science Park é um centro vibrante de inovação e pesquisa, dedicado ao crescimento das indústrias científica, de saúde e farmacêutica. Localizado estrategicamente no coração de Dubai, a área abriga laboratórios de ponta, centros de pesquisa e uma ampla gama de empresas focadas em ciências da vida, saúde e tecnologia. A comunidade é projetada para apoiar a colaboração e a inovação, tornando-se um local ideal para empreendedores, pesquisadores e empresas que buscam ultrapassar os limites da descoberta científica.",
          "Além da infraestrutura de negócios e pesquisa, o Dubai Science Park oferece um estilo de vida bem equilibrado para seus residentes. A área conta com uma variedade de opções residenciais modernas, que vão de apartamentos estilosos a casas voltadas para famílias, todas projetadas para complementar a atmosfera profissional do distrito. O ambiente de parque, com espaços verdes e áreas paisagísticas, cria um ambiente tranquilo onde os residentes podem relaxar, enquanto a proximidade com serviços essenciais, como clínicas, farmácias e centros de bem-estar, adiciona conveniência à vida na área.",
          "A localização privilegiada do Dubai Science Park oferece excelente conectividade com o resto da cidade. Fica a apenas uma curta distância de áreas importantes como Dubai Marina, Jumeirah Beach e Mall of the Emirates, facilitando o acesso dos residentes e profissionais ao melhor que Dubai tem a oferecer. A combinação de um ambiente profissional inovador e um estilo de vida moderno e confortável torna o Dubai Science Park um destino único para quem busca viver e trabalhar na vanguarda da inovação.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/lQ-gd-ekZD_7YcTdf5v8fKrgO0U/nplh5g../image+%281%29.png",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Swimming Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/P8frGb6WiUbzXAeXE353cFSVtbw/kQ4lxA../img268.jpg",
      },
      {
        name: "Sunlit Terrace",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/LUn_Tn--df8d3LuHpbApc4eWE_I/Z2hvWg../img164.jpg",
      },
      {
        name: "Outdoor Fitness Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/X1qi7xk-aIl4LuSL2CStE0U-lOU/Yoctqg../Biofilico-Gym.png",
      },
      {
        name: "Basketball Court",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Q98f49z09AOWGhywMLXe5ALv2-o/1cgzfw../58.jpg",
      },
      {
        name: "Kids Play Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/8PzCf3v1GvEJ0uHJ1HYC8Xx8PpE/sjAelg../child-on-playground-swing-kids-p.jpg",
      },
      {
        name: "Yoga Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ZyC6hB-KtPQEsWrpNF9PZSFAeUM/fqxSKQ../48.jpg",
      },
    ],
    units: [
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 999,999 — AED 1,212,499",
        priceRangeUSD: "$272,200 — $330,100",
        size: "386 sqft — 494 sqft",
        sizeSqm: "35.9 m² — 45.9 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/sCt-bfc08nNCY8qveR24KYC3NVI/xhbSZQ../st.png",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 1,499,999 — AED 1,699,999",
        priceRangeUSD: "$408,400 — $462,800",
        size: "805 sqft — 887 sqft",
        sizeSqm: "74.8 m² — 82.4 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/2_Apcta6y1rovhTzJSQaZBcAeD4/DsQshQ../1br.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 2,499,999 — AED 3,137,499",
        priceRangeUSD: "$680,600 — $854,200",
        size: "1116 sqft — 1476 sqft",
        sizeSqm: "103.7 m² — 137.1 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/pCPOry75T8amDLUonyXgTN9hQ70/poqbVA../2br.png",
      },
    ],

    developerDetails: {
      name: "Binghatti",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
      description:
        "Binghatti is one of the largest property developers and holding companies in the UAE founded in 2008. Binghatti hyper-properties are designed specifically for those with genuine appreciation of fine architecture, giving their residents the experience of how modern and thoughtful design, combined with cutting-edge technology can make a property more than just a place you live in.",
      website: "https://www.binghatti.com/",
      email: "sales@binghatti.com",
      address: "Binghatti Sales Center, Al Manara, Dubai, OAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "10 AM - 7 PM",
        weekends: "Saturday",
        weekendHours: "10 AM - 4:30 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 5,
    title: "Binghatti Skyrise",
    developer: "Binghatti",
    location: "Business Bay",
    city: "Dubai",
    price: "$395,095",
    completionDate: "Q4 2026",
    lastUpdated: "Nov 19, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/LQ3c8ICOhSbAZWjeYLg1Ins6Wgk/xwrG-A../cover.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "Start of sales",
    recommended: true,
    recommendationText: "Recommended",
    videoUrl: "https://www.youtube.com/embed/WDiZAhc4apg", //PlaceHolder Video
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55363.120676451115!2d55.273062!3d25.181688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6912b56d88b3%3A0xdd086b8cf58681d8!2sBinghatti%20Skyrise!5e1!3m2!1sen!2sus!4v1732021567309!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "Marasi Drive Park", distance: "0.3 km" },
      { name: "Creative Kids Early Learning Centre", distance: "1 km" },
      { name: "Al Faris Mall", distance: "3.3 km" },
      { name: "Downtown Dubai", distance: "3.6 km" },
      { name: "Jumeirah Beach", distance: "6.5 km" },
      { name: "Dubai International Airport", distance: "18.1 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/43WxUUBIkDYEbG8q7_3ItfADhL8/FklRXg../3.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/bB3BfCpWgpaPtAaO6PzFgxq3Azw/M59YrA../img78.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ocVnr_ncxXwt6P1Wke85LvqUgEc/gdYJ_Q../78.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/lT0FXXgn70XmVcq1XmnrwyUuYdY/aSh0DQ../6.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/X4SDFRexn0SAvsw1LjHZ9r5HdHs/kyd4hw../7.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/IMszJnTM63QdG5yY3LmdiVxPe28/Yz-oZA../img333.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/YPJ5Lnuoqd5Af-Qxl_xgUnPhuYw/6jOdxQ../img276.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/OlfKryqm6u2SdBVsElK9-PCrg78/O2BWeg../img252.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/Vey2aoBcakGBjWJs6wltu1ZUzdA/u7CXQg../img317.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/hSJ9b03Qg5lg-2BGLSsJIwPDyMA/1IZUVA../img289.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/naN3iEoQhGL5vtZd_frqOdi51eY/XgPxYA../img310.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ITPdjFsNN6AHLD0cQ46ZwygHzKI/JZwwFg../img324.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/egaEoahuyjTnXPo0EeMWp_0l4is/U0PKng../img296.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/HgJjNdgXjzFzh53pxwX9MLCRclc/LJ5yCw../img303.jpg",
        ],
      },
    ],

    overview: {
      en: {
        generalFacts: [
          "Binghatti Skyrise is a stunning high-rise development that redefines modern urban living, rising gracefully above the city skyline. This iconic project features three elegant towers offering a range of studios, 1, 2, and 3-bedroom apartments, all designed with Binghatti’s signature architectural flair and meticulous attention to detail.",
          "Residents at Binghatti Skyrise enjoy a wide array of world-class amenities that cater to an active and luxurious lifestyle. Whether you're relaxing at the man-made beach, enjoying a game of tennis or basketball, or jogging along the scenic track, there is something for everyone. The development also includes a golf course with a serene lake, paddle and badminton courts, landscaped gardens, and both indoor and outdoor workout areas.",
          "Binghatti Skyrise is the perfect blend of design excellence and modern sophistication, providing residents with an unparalleled living experience in the heart of the city.",
        ],
        finishingMaterials: "Modern finishing with high-quality materials.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No.",
        locationBenefits: [
          "Business Bay, Dubai, can be confusing due to its name. However, it’s not just office spaces and buildings. There are hotel towers, amazing restaurants and wonderful residential properties here as well. It is known to be a very stylish and up-to-date in the latest trends and gadgets kind of community. The life here is fast-paced. Business Bay is on the south side of Downtown Dubai. This area usually hosts plenty of events to keep things lively. There are music festivals, Mother’s Day celebrations, New Year’s celebrations and many others as well. The lifestyle that Business Bay offers has many advantages.",
          "Famous and international schools, medicals, supermarkets, banks and finance centers are a few minutes from your space. The place simply elevates your life standard and way of living with easy and intuitive options in the proximity. Come and seek refuge in this wonderful place full of potential elements of pleasures, success and fun.",
        ],
      },
      pt: {
        generalFacts: [
          "Binghatti Skyrise é um deslumbrante empreendimento de alto padrão que redefine a vida urbana moderna, elevando-se graciosamente acima do horizonte da cidade. Este projeto icônico apresenta três elegantes torres, oferecendo uma gama de estúdios, apartamentos de 1, 2 e 3 quartos, todos projetados com o toque arquitetônico característico da Binghatti e atenção meticulosa aos detalhes.",
          "Os residentes do Binghatti Skyrise desfrutam de uma ampla gama de comodidades de classe mundial que atendem a um estilo de vida ativo e luxuoso. Se você está relaxando na praia artificial, jogando tênis ou basquete, ou correndo pela pista cênica, há algo para todos. O empreendimento também inclui um campo de golfe com um sereno lago, quadras de paddle e badminton, jardins paisagísticos e áreas de treino internas e externas.",
          "Binghatti Skyrise é a combinação perfeita de excelência no design e sofisticação moderna, proporcionando aos residentes uma experiência de vida incomparável no coração da cidade.",
        ],
        finishingMaterials:
          "Acabamento moderno com materiais de alta qualidade.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não.",
        locationBenefits: [
          "Business Bay, em Dubai, pode ser confuso devido ao seu nome. No entanto, não se trata apenas de espaços e edifícios de escritório. Há torres de hotéis, restaurantes incríveis e propriedades residenciais maravilhosas aqui também. É conhecido por ser uma comunidade muito estilosa e atualizada nas últimas tendências e gadgets. A vida aqui é agitada. Business Bay está no lado sul do Downtown Dubai. Esta área geralmente recebe muitos eventos para manter as coisas animadas. Há festivais de música, celebrações do Dia das Mães, celebrações de Ano Novo e muitos outros também. O estilo de vida que Business Bay oferece tem muitas vantagens.",
          "Famosas escolas internacionais, médicos, supermercados, bancos e centros financeiros estão a poucos minutos do seu espaço. O local simplesmente eleva seu padrão de vida e forma de viver com opções fáceis e intuitivas na proximidade. Venha buscar refúgio neste lugar maravilhoso, cheio de elementos potenciais de prazer, sucesso e diversão.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/Cq8wekKESttv5Bkkp5EoIwkKK00/j-EiMw../Group+371069+%281%29+%281%29+%281%29.png",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Golf Course With Lake",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ubEDQs1SmL0ShUYfF2Zszfxv4W4/Jq-KGA../img241.jpg",
      },
      {
        name: "Paddle and Tennis Court",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/AViSC0M0pTAMTLYJPjT86Iab9I0/HGHMYw../img233.jpg",
      },
      {
        name: "Badminton Court",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ew5FPpnQYMTB9RyrgF49bOuL0Eg/-5aRmQ../badminton-court-1.jpg",
      },
      {
        name: "Basketball Court",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/jr2KprD8ayahwMoXlsjylLS3M9M/aMxoFw../58.jpg",
      },
      {
        name: "Jogging Track",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/h90L0ZEUMI2xyxCzuLbqlXRTM7M/k3RV8g../img245.jpg",
      },
      {
        name: "Landscaped Gardens",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/TRYuoOAjfo6XKFDXD6B1YCONDq4/hmpWpA../1453832175598.jpg",
      },
      {
        name: "Indoor & Outdoor Gyms",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/2_wG-l5y1kKwPbKlGpU1as3kJao/TGyULQ../img235.jpg",
      },
      {
        name: "Amphitheatre",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/pdk-Q2U6UtlUr2AAvUaqlAqgf5k/LCzXsQ../37190.jpg",
      },
      {
        name: "Sunken Seating",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/6pXwUv0HZks6-CdPpHjGhz6eU2I/IYMQLg../img66.jpg",
      },
      {
        name: "Outdoor Seating Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/R7xtWfaqJswldXYSab4a10yDJtY/dB4rhg../9e2517fb2e4afbf6564917de774a1ed3.jpg",
      },
      {
        name: "Multipurpose Pavilion",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xMZW1rX9W5ty-PfshynNY0JM2cg/Pd-mgg../2e82284d13519e8a31a181cbd3544f95.jpg",
      },
      {
        name: "Private Pool with Jacuzzi",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/wcmFtRXvFhl9KtfhHwxNmTMCS14/lcK5hA../images+%282%29.jpg",
      },
      {
        name: "Kids Pool and Aquatic Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/3ui79RqKihaezp1T_vwP1X3qBFU/c3pRdg../img116.jpg",
      },
      {
        name: "Skate Park",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ExFTFYt230olfOpiR1fcSVwpctI/zZevUg../img243.jpg",
      },
    ],
    units: [
      {
        type: "Apartments",
        bedrooms: 0,
        priceRange: "AED 1,449,999 — AED 1,724,999",
        priceRangeUSD: "$394,800 — $469,600",
        size: "422 sqft — 477 sqft",
        sizeSqm: "39.2 m² — 44.3 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/bZuNfslO2mGpLoHx4aaFNEFYTkw/2PTWBw../st.png",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 2,549,999 — AED 2,962,499",
        priceRangeUSD: "$694,200 — $806,500",
        size: "831 sqft — 1022 sqft",
        sizeSqm: "77.2 m² — 94.9 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/0KiV9ON3uJ__DXBSSWqYxd1Vemg/9AZyjQ../1.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "0 sqft — 0 sqft",
        sizeSqm: "0 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/sCPz2PRXcbSxTWXfDmsCZv3F_Pg/s_fImg../3.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "0 sqft — 0 sqft",
        sizeSqm: "0 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Q9KQWxUtpi3idkjaQAb7qXltY_w/9ybj_Q../2.png",
      },
    ],
    developerDetails: {
      name: "Binghatti",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
      description:
        "Binghatti is one of the largest property developers and holding companies in the UAE founded in 2008. Binghatti hyper-properties are designed specifically for those with genuine appreciation of fine architecture, giving their residents the experience of how modern and thoughtful design, combined with cutting-edge technology can make a property more than just a place you live in.",
      website: "https://www.binghatti.com/",
      email: "sales@binghatti.com",
      address: "Binghatti Sales Center, Al Manara, Dubai, OAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "10 AM - 7 PM",
        weekends: "Saturday",
        weekendHours: "10 AM - 4:30 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 6,
    title: "Binghatti Hills",
    developer: "Binghatti",
    location: "Al Barsha South",
    city: "Dubai",
    price: "$272,799",
    completionDate: "Q4 2026",
    lastUpdated: "Nov 19, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/nIXPFrE2JFc6HyDksQqxd1qMj3I/sELcfg../2.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "Start of sales",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/EusYHVPuRso",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55413.30901153561!2d55.248801!3d25.07099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ffda5c9b53d%3A0xa20f6de57fba29f0!2sBinghatti%20Hills!5e1!3m2!1sen!2sus!4v1732023777956!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "My City Centre Al Barsha", distance: "1.1 km" },
      { name: "Green Escape Park", distance: "1.5 km" },
      { name: "Repton School Al Barsha", distance: "2.2 km" },
      { name: "Sufouh Beach", distance: "13.4 km" },
      { name: "Downtown Dubai", distance: "19 km" },
      { name: "Dubai International Airport", distance: "34 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/IayhXHWMXt2Zrl6gDiXpZ9UQfU0/LTOYmA../2.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/0fLvtpYTBZb8_sIklo_J-sIdUuM/fCcOFA../4.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/m--eOcg8G6L_NvaVjVHisN47BXk/_ud0XQ../6.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/xlRDcdG7avTFO1O1jW-0t0Ngi9o/Y4ddDA../7.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/3MdmhbKRVY0Hiq0VLPmjJ_l4_jM/fWj7Lg../8.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/NooJOweKPQQGtrR4_di5D06L-k8/YvVCVg../9.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/rzZyYAIOFuLUQzKabFI6m32rK4g/DhZYPA../44.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/3pCMnoJT4ZOmXG1pksRYfZhdQnE/GGMAMw../53.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/2l2HOWDfNaj47I_7TDU7VG44PmQ/m0j43A../54.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/P10HQjAkJ5LHV8FTerOn0CKcpmQ/EywP7A../62.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6lqbnKHo5LSO9VavuzvOrH0eTPo/3XArgQ../63.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/GVcOKOwZZssz3aOYNR7gLauTYeo/wgDIug../64.jpg",
        ],
      },
    ],

    overview: {
      en: {
        generalFacts: [
          "Binghatti Hills is a fresh residential development nestled in the heart of Al Barsha South within Dubai Science Park. Symbolizing contemporary elegance and opulent living, Binghatti Hills offers a variety of residences ranging from one to three-bedroom apartments. This latest residential venture showcases Binghatti’s commitment to real estate excellence, offering expatriates an exclusive opportunity to embrace a luxurious lifestyle adorned with modern comforts and premium facilities.",
          "With its captivating design, Binghatti Hills sets a new benchmark for luxury, evident in every aspect of the property. Residents can indulge in state-of-the-art leisure amenities, creating an ideal retreat for relaxation and revitalization. Positioned strategically along Umm Suqeim Road, Binghatti Hills ensures effortless access to key areas of Dubai, facilitating seamless daily commutes.",
        ],
        finishingMaterials: "State-of-the-art interiors and finishes.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No.",
        locationBenefits: [
          "Al Barsha South is a vibrant community located in the southern region of Dubai, United Arab Emirates. It's known for its dynamic mix of residential, commercial, and recreational spaces, making it an attractive area for both residents and visitors alike.",
          "Al Barsha South is primarily a residential neighborhood, offering a diverse range of housing options including villas, townhouses, and apartment complexes. The community is designed to accommodate families, professionals, and individuals seeking modern living spaces within a well-planned environment.",
          "The infrastructure in Al Barsha South is characterized by modern amenities and well-developed facilities. Residential buildings are equipped with state-of-the-art features, and the area is serviced by efficient transportation networks, ensuring connectivity to other parts of Dubai.",
        ],
      },
      pt: {
        generalFacts: [
          "Binghatti Hills é um novo empreendimento residencial localizado no coração de Al Barsha South, dentro do Dubai Science Park. Simbolizando elegância contemporânea e uma vida luxuosa, o Binghatti Hills oferece uma variedade de residências que vão de apartamentos de um a três quartos. Este mais recente projeto residencial mostra o compromisso da Binghatti com a excelência imobiliária, oferecendo aos expatriados uma oportunidade exclusiva de adotar um estilo de vida luxuoso, adornado com confortos modernos e instalações premium.",
          "Com seu design fascinante, o Binghatti Hills estabelece um novo marco para o luxo, evidente em todos os aspectos da propriedade. Os residentes podem desfrutar de comodidades de lazer de última geração, criando um refúgio ideal para relaxamento e revitalização. Localizado estrategicamente ao longo da Umm Suqeim Road, o Binghatti Hills garante fácil acesso às principais áreas de Dubai, facilitando os deslocamentos diários.",
        ],
        finishingMaterials: "Interiores e acabamentos de última geração.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não.",
        locationBenefits: [
          "Al Barsha South é uma comunidade vibrante localizada na região sul de Dubai, Emirados Árabes Unidos. É conhecida por sua mistura dinâmica de espaços residenciais, comerciais e recreativos, tornando-a uma área atraente tanto para residentes quanto para visitantes.",
          "Al Barsha South é predominantemente um bairro residencial, oferecendo uma gama diversificada de opções de moradia, incluindo vilas, casas geminadas e complexos de apartamentos. A comunidade foi projetada para acomodar famílias, profissionais e indivíduos que buscam espaços modernos de vida dentro de um ambiente bem planejado.",
          "A infraestrutura de Al Barsha South é caracterizada por comodidades modernas e instalações bem desenvolvidas. Os edifícios residenciais são equipados com características de última geração, e a área é servida por eficientes redes de transporte, garantindo conectividade com outras partes de Dubai.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/SWLZHzOUkelNM_81H6S3vnBe_-w/K18JUg../%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2024-05-02+%D0%B2+10.18.01.png",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Paddle Tennis",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/bnXq4DyOaIeDnvCeV3oiTmltkxY/7ar9YA../22.jpg",
      },
      {
        name: "Basketball Court",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/OClEsGhWeJojQNhM0LntgMDbS_E/raMxJw../hoops.avif.jpg",
      },
      {
        name: "Kids Swimming Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/7BCx1CC-uAgSgzVwPxeecN_rhfE/M8zYJw../istockphoto-1293821481-612x612.jpg",
      },
      {
        name: "Jogging Areas",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/HzXn-vba_ISXhq6a-7nTKdSpKDQ/nZOILw../track.jpg",
      },
      {
        name: "Infinity Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/oGZHby77wDEfwg3HrRPl9601dMo/3-v7Gg../5.jpg",
      },
      {
        name: "Gym",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/rEwqZO37w1ypf2FzXmp3j_BffKA/F77NTg../BackToGymSWEATf1f07a7f6f79e7b880.jpg",
      },
      {
        name: "Yoga Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ngZg6eDy7II_hphOyQzAJwQv32M/EGk4nA../48.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 0,
        priceRange: "AED 1,001,175 — AED 1,141,175",
        priceRangeUSD: "$272,600 — $310,700",
        size: "446 sqft — 509 sqft",
        sizeSqm: "41.4 m² — 47.3 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/SD0erzFc7s2R_MNtKQ93rlrWp10/UiM4pg../st.png",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 1,424,836 — AED 1,523,528",
        priceRangeUSD: "$387,900 — $414,800",
        size: "751 sqft — 1007 sqft",
        sizeSqm: "69.8 m² — 93.6 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Ogsfj-7k4H1j2jNz1HoWiW4YeJo/yewqng../1.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 2,858,822 — AED 3,999,999",
        priceRangeUSD: "$778,300 — $1,089,000",
        size: "1346 sqft — 3671 sqft",
        sizeSqm: "125 m² — 341 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/m0VcJlcHp_kmGq3TxMuomCHFukQ/xbOMKA../2.png",
      },
    ],
    developerDetails: {
      name: "Binghatti",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
      description:
        "Binghatti is one of the largest property developers and holding companies in the UAE founded in 2008. Binghatti hyper-properties are designed specifically for those with genuine appreciation of fine architecture, giving their residents the experience of how modern and thoughtful design, combined with cutting-edge technology can make a property more than just a place you live in.",
      website: "https://www.binghatti.com/",
      email: "sales@binghatti.com",
      address: "Binghatti Sales Center, Al Manara, Dubai, OAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "10 AM - 7 PM",
        weekends: "Saturday",
        weekendHours: "10 AM - 4:30 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 7,
    title: "Mercedes-Benz Places by Binghatti",
    developer: "Binghatti",
    location: "Downtown Dubai",
    city: "Dubai",
    price: "$2,806,539",
    completionDate: "Q4 2026",
    lastUpdated: "Nov 19, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/_UBqSN0YB8ctWztYeGEIN_C-vtE/rQKcZQ../12.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "On Sale",
    recommended: true,
    recommendationText: "Recommended",
    videoUrl: "https://www.youtube.com/embed/WDiZAhc4apg", //PlaceHolder Video
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57766.990238488805!2d55.279983!3d25.188485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6918ae62e4e1%3A0x3cb292fbea11cdd2!2sMercedes%20Benz%20Places%20by%20Binghatti!5e0!3m2!1sen!2sus!4v1732020390375!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "City School Holdings International", distance: "1 km" },
      { name: "Dubai Mall", distance: "1.1 km" },
      { name: "Burj Khalifa Park", distance: "2.1 km" },
      { name: "Mercato Beach", distance: "7.6 km" },
      { name: "Dubai International Airport", distance: "16.7 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/9x4iOZU226YkI_8SoKnppmJCaNE/njm0Lw../14.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Bx88XHzrwnVdaSkvZ1xIiMx1Wng/ltQTxg../16.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/4nRnpoS3slacJFtYpb-t8sm4zfE/gDABjg../17.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XbVuDddL4GlVLdtsBW_Mm4ow-dI/Hs4Tyg../48.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/D6U42kQUkePys_GNStPdvnrs9Fw/-Vh2NA../67.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/X4PgJPGuUXe-YTBBzKMd5FTs3BE/F9e9eg../69.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dFYC2iWw3OIRaiNdIkt3xRVfQLA/XV4BFQ../6.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/iesd0c5zj_pzRhxeMGAsQ6Bx9O4/5rq6RQ../8.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/W6BxOs3ef9e7pcZtaoLwd2KPpps/N4m8GA../11.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/xRSmmlORes6j4r3NNkWlpcXmxVc/gNeGXw../12.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/aR0Wb6_SIJjvCMH3L2y_c9pLYt0/kPfbxA../46.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/pbNDIqnHtwUDHKMPTJz7muN-m-Y/8Jsa-A../49.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Xq7ROmDOki-0jPcBcmJz__8_XeE/jhoUFg../56.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6QiXdCS2Fh4ueJl9NHbyhHyRA9E/qtpW6A../61.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/GNpfLkox2FQWFucdJLWoRNV6K2A/QDjYEw../26.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/88WfCFuPSmM4WVHU9GAHjYQhRj0/QhgnMw../35.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Introducing Mercedes-Benz Places | Binghatti: A gateway to experience new grounds for extraordinary moments. A combination of intelligence and emotion, glamour and simplicity, and historic legacies eager towards a future of infinite possibilities. Mercedes-Benz Places | Binghatti explores the creation of concepts that are from tomorrow. In line with the philosophy of Sensual Purity, there is created spectacular proportions that combine beauty and efficiency.",
          "Mercedes-Benz Places | Binghatti embodies the spirit of an icon that is quintessentially brilliant: an archetype of outstanding design. The deeply rooted design philosophy that shapes the hyper-tower harmonizes with its recognizable design DNA of Sensual Purity, making it unmistakably iconic.",
          "Defined as an epochal architectural symbol, the hyper-tower’s design supremacy is brought to life by the amalgamation of multiple design languages. The use of intricate strokes, mingled with the candescent pattern of the Mercedes-Benz three-pointed star create a form that lives and breathes the spirit of revolutionary architecture and craftsmanship.",
        ],
        finishingMaterials:
          "Mercedes-Benz Places | Binghatti is designed with interior details that live and breathe the spirit of wholeness and serenity. The details include fine hues that echo tones of a holistic lifestyle that is both elegant and harmonious.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "Furnished & Unfurnished",
        locationBenefits: [
          "As the name suggests, Downtown Dubai is the centre of Dubai, constantly evolving at a fast pace. Home to many landmarks and world-famous attractions, including Burj Khalifa, the Dubai Mall and Dancing Fountains, the area is a hub of activities. The prime location keeps Downtown busy throughout the year. Constantly attracting tourists, there’re always some new things to do in Downtown Dubai.",
          "The area is best known for offering a luxurious lifestyle. It used to be a vertical community, but many villas have also been added to the real estate market. As per Bayut’s 2020 Rental Market Report, it is the second most popular area offering luxury apartments for rent after Dubai Marina. Downtown is well connected to other key commercial and residential areas in the city. Strategically located along Sheikh Zayed Road, E11, across Al Wasl and bordering Business Bay, it is a pretty active and bustling neighbourhood. Downtown is one of Dubai’s best areas for non-drivers and frequent travellers. Many amenities, including upscale hotels, chic leisure points and massive shopping centres, make Downtown Dubai a sought-after address and “the centre of now”.",
        ],
      },
      pt: {
        generalFacts: [
          "Apresentando Mercedes-Benz Places | Binghatti: Um portal para experimentar novos horizontes para momentos extraordinários. Uma combinação de inteligência e emoção, glamour e simplicidade, e legados históricos ansiosos por um futuro de possibilidades infinitas. Mercedes-Benz Places | Binghatti explora a criação de conceitos que são do amanhã. Em sintonia com a filosofia da Pureza Sensorial, foram criadas proporções espetaculares que combinam beleza e eficiência.",
          "Mercedes-Benz Places | Binghatti incorpora o espírito de um ícone que é fundamentalmente brilhante: um arquétipo de design excepcional. A filosofia de design profundamente enraizada que molda o hiper-tower harmoniza-se com seu DNA de design reconhecível de Pureza Sensorial, tornando-o inconfundivelmente icônico.",
          "Definido como um símbolo arquitetônico épico, a supremacia do design do hiper-tower ganha vida pela fusão de várias linguagens de design. O uso de traços intrincados, misturados com o padrão incandescente da estrela de três pontas da Mercedes-Benz, cria uma forma que vive e respira o espírito da arquitetura e do artesanato revolucionários.",
        ],
        finishingMaterials:
          "Mercedes-Benz Places | Binghatti é projetado com detalhes internos que vivem e respiram o espírito de plenitude e serenidade. Os detalhes incluem matizes finas que ecoam tons de um estilo de vida holístico, elegante e harmonioso.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Mobilado & Não Mobilado",
        locationBenefits: [
          "Como o nome sugere, Downtown Dubai é o centro de Dubai, evoluindo constantemente a um ritmo acelerado. Lar de muitos marcos e atrações mundialmente famosas, incluindo o Burj Khalifa, o Dubai Mall e as Fontes Dançantes, a área é um centro de atividades. A localização privilegiada mantém Downtown ocupado durante o ano todo. Constantemente atraindo turistas, sempre há algo novo para fazer em Downtown Dubai.",
          "A área é mais conhecida por oferecer um estilo de vida luxuoso. Antigamente era uma comunidade vertical, mas muitas villas também foram adicionadas ao mercado imobiliário. De acordo com o Relatório de Mercado de Aluguel de 2020 da Bayut, é a segunda área mais popular que oferece apartamentos de luxo para alugar, depois de Dubai Marina. Downtown está bem conectado a outras áreas comerciais e residenciais importantes da cidade. Localizado estrategicamente ao longo da Sheikh Zayed Road, E11, cruzando Al Wasl e fazendo divisa com Business Bay, é um bairro bem ativo e movimentado. Downtown é uma das melhores áreas de Dubai para não-motoristas e viajantes frequentes. Muitas comodidades, incluindo hotéis de luxo, pontos de lazer sofisticados e enormes shoppings, fazem de Downtown Dubai um endereço cobiçado e 'o centro do agora'.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/C5SZ1HJJyg2zW0kGx6PWjc6SPok/eoODMg../Mercedes-Benz-Places-Digital-Brochure_page-0001+%281%29+%281%29.jpg",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Sky Infinity Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/yevZtPkPiImcJG5-sRa_YStADRU/NgxIxQ../68.jpg",
      },
      {
        name: "Private Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/sqhFXuy581kDMkedXQ5o8AvMC1U/hsAkug../47.jpg",
      },
      {
        name: "SPA",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/wyPDfyvGbTPH-47u6sv0iLD-VRE/Z5eppA../attractive-african-woman-enjoyin.jpg",
      },
      {
        name: "Podium Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/tUQdtOPswmICKUwRnb0aPGcUG_s/AXSo-g../15.jpg",
      },
      {
        name: "Gym",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/I6TyqzbhUzNcHtPu0EI91OK5s3k/-93DFg../BackToGymSWEATf1f07a7f6f79e7b880.jpg",
      },
      {
        name: "Concierge Service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Um8GQSmRUUy9sU3Jqt_ZKLvq4y8/xEi6tA../15-Luxury-Concierge-Services-You.jpg",
      },
      {
        name: "Food & Beverage",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/PB5E7qbf1tgr_toxCt5apjwcgBs/vxB0jA../i-know-exactly-what-you-can-tell.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 10,299,999 — AED 14,799,999",
        priceRangeUSD: "$2,804,200 — $4,029,300",
        size: "1836 sqft — 1836 sqft",
        sizeSqm: "170.6 m² — 170.6 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/B1owaOBnbR-hANBxxNZareOXQ64/ixKAcQ../2.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 19,999,999 — AED 26,699,999",
        priceRangeUSD: "$5,445,000 — $7,269,100",
        size: "2619 sqft — 3285 sqft",
        sizeSqm: "243.3 m² — 305.2 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/wFGOFYyW63RdZUtzVQPV3dmfJGU/Lh_jGw../3.png",
      },
      {
        type: "Penthouse",
        bedrooms: 4,
        priceRange: "AED 35,299,999 — AED 36,199,999",
        priceRangeUSD: "$9,610,400 — $9,855,400",
        size: "3662 sqft — 3785 sqft",
        sizeSqm: "340.2 m² — 351.6 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/15iYZWpdNJFLy3Clzzw_ivch_Qw/Nt8Npw../4p.png",
      },
    ],

    developerDetails: {
      name: "Binghatti",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
      description:
        "Binghatti is one of the largest property developers and holding companies in the UAE founded in 2008. Binghatti hyper-properties are designed specifically for those with genuine appreciation of fine architecture, giving their residents the experience of how modern and thoughtful design, combined with cutting-edge technology can make a property more than just a place you live in.",
      website: "https://www.binghatti.com/",
      email: "sales@binghatti.com",
      address: "Binghatti Sales Center, Al Manara, Dubai, OAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "10 AM - 7 PM",
        weekends: "Saturday",
        weekendHours: "10 AM - 4:30 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 8,
    title: "Bugatti Residences by Binghatti",
    developer: "Binghatti",
    location: "Business Bay",
    city: "Dubai",
    price: "$5,201,634",
    completionDate: "Q4 2026",
    lastUpdated: "Nov 19, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/PAe8EUfCGjEmU21sEvfnQL6TPlo/jqE6UQ../BUGATTI+RESIDENCES+BY+BINGHATTI+_C8.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    // commissionBadge: "On Sale",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/Pqm9BnwBxbc",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57767.28470510716!2d55.26542!3d25.187864000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4209d6adc719%3A0xafd8c2fdec670b3a!2sSafa%20Park!5e0!3m2!1sen!2sus!4v1732022371186!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "Scholars International Group", distance: "2 km" },
      { name: "Dubai Mall", distance: "2 km" },
      { name: "Burj Park", distance: "2.9 km" },
      { name: "Safa Park", distance: "3.7 km" },
      { name: "Jumeirah Beach", distance: "6.7 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/bE51gYZl7dQDqHk-ulDhMrJareY/iN7DjQ../BUGATTI+RESIDENCES+BY+BINGHATTI++C1.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/UUgNBBexApNhMUiTPkcttva5Pm4/FVmlCA../BUGATTI+RESIDENCES+BY+BINGHATTI++C4.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/SjxkNXdH1VRbeHznz-MJo0zXgnM/vnx3CQ../BUGATTI+RESIDENCES+BY+BINGHATTI++C5.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/mW5rtID1jfqsPF2Y7EHV84ut6uk/enb_bA../BUGATTI+RESIDENCES+BY+BINGHATTI++C7+.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/c2zopnt7Ha-bdBhWJ4eQH2-1tRg/UGcBow../BUGATTI+RESIDENCES+BY+BINGHATTI++C9.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dngylGWJKzOWEGTslf1E6nOGVFA/vldhAg../BUGATTI+RESIDENCES+BY+BINGHATTI++C12+.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/sj9TEoBM9JzIMBr-gZtTzArVPdA/qEiRuw../BUGATTI+RESIDENCES+BY+BINGHATTI+_C2.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Q4IZPnxFE9tQxDl6CXP-R84g27A/UdUMug../BUGATTI+RESIDENCES+BY+BINGHATTI+_C8.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/r2herT0YaPAeoN-oV_O4hxNTYdU/oy3skQ../BUGATTI+RESIDENCES+BY+BINGHATTI+Exterior.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/J8FmxYpRRZUychGVe4NnzGr_SAM/Azj0qA../BUGATTI+RESIDENCES+BY+BINGHATTI+Valet+Service.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/_G43UxS7fbrJMUUHIZI8q55BqxQ/8Jyigg../BUGATTI+RESIDENCES+BY+BINGHATTI++Bedroom.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/cSnsyHQ6IQ7wDdXiTxyxKySN6Z8/cnnDzQ../BUGATTI+RESIDENCES+BY+BINGHATTI++Penthouse.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/0QFlSzFumb3GAilqLozUH5xIY5Q/nlygxA../BUGATTI+RESIDENCES+BY+BINGHATTI++SPA.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/7io4KHL_LZfISpPBtpPbWknyJYQ/sVnVfA../BUGATTI+RESIDENCES+BY+BINGHATTI+_Kitchen.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/mnsrj847NFFOqkCa-ep_AUDlTyw/gELmuA../BUGATTI+RESIDENCES+BY+BINGHATTI+Bathroom.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dCOxVmXO1HqE53A8xWxnh0ab-to/_5MXRQ../BUGATTI+RESIDENCES+BY+BINGHATTI+Dining.png",
          "https://api.reelly.io/vault/ZZLvFZFt/EPWPZl14aK6NlVUbxvaNR0z_dZY/I6uNoQ../BUGATTI+RESIDENCES+BY+BINGHATTI+Living+room.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "The birth of an icon - the hyper tower is poised to become a landmark of architectural prowess, derived from the Bugatti and Binghatti DNA. Inspired by the world’s most eminent structural masterpieces, Bugatti Residences by Binghatti is not a mere addition to city’s skyline but a new icon that will stand the test of time. Born from the fluidity of nature, Bugatti Residences by Binghatti is designed with inventive exuberance, reflecting sheer opulence. The facade is characterized by fluid lines, and subtle contours reminiscent of the French Riviera – a resort-inspired lifestyle in the heart of the metropolis.",
          "The design amalgamates modern and natural elements, with exquisite moulding and sleek contemporary fixtures. Every inch of the space exudes quality, refinement and excellence.",
        ],
        finishingMaterials:
          "The design amalgamates modern and natural elements, with exquisite moulding and sleek contemporary fixtures. Every inch of the space exudes quality, refinement and excellence.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "Furnished / Unfurnished",
        locationBenefits: [
          "Business Bay, Dubai, can be confusing due to its name. However, it’s not just office spaces and buildings. There are hotel towers, amazing restaurants and wonderful residential properties here as well. It is known to be a very stylish and up-to-date in the latest trends and gadgets kind of community. The life here is face-paced. Business Bay is on the south side of Downtown Dubai. This area usually hosts plenty of events to keep things lively. There are music festivals, Mother’s Day celebrations, New Year’s celebrations and many others as well. The lifestyle that Business Bay offers has many advantages.",
          "Famous and international schools, medicals, supermarkets, banks and finance centers are a few minutes from your space. The place simply elevates your life standard and way of living with easy and intuitive options in the proximity. Come and seek refuge in this wonderful place full of potential elements of pleasures, success and fun.",
        ],
      },
      pt: {
        generalFacts: [
          "O nascimento de um ícone - a hyper torre está prestes a se tornar um marco de destreza arquitetônica, derivada do DNA da Bugatti e Binghatti. Inspirada nas mais eminentes obras estruturais do mundo, o Bugatti Residences by Binghatti não é apenas uma adição ao horizonte da cidade, mas um novo ícone que resistirá ao teste do tempo. Nascido da fluidez da natureza, o Bugatti Residences by Binghatti é projetado com exuberância inventiva, refletindo pura opulência. A fachada é caracterizada por linhas fluidas e contornos sutis que lembram a Riviera Francesa - um estilo de vida inspirado em resorts no coração da metrópole.",
          "O design combina elementos modernos e naturais, com moldagem requintada e acessórios contemporâneos elegantes. Cada centímetro do espaço exala qualidade, refinamento e excelência.",
        ],
        finishingMaterials:
          "O design combina elementos modernos e naturais, com moldagem requintada e acessórios contemporâneos elegantes. Cada centímetro do espaço exala qualidade, refinamento e excelência.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Mobilado / Não Mobilado",
        locationBenefits: [
          "Business Bay, em Dubai, pode ser confuso devido ao seu nome. No entanto, não é apenas espaço de escritórios e edifícios. Há torres de hotéis, restaurantes incríveis e maravilhosas propriedades residenciais também. É conhecido por ser uma comunidade muito estilosa e atualizada nas últimas tendências e gadgets. A vida aqui é acelerada. Business Bay está no lado sul do Downtown Dubai. Esta área costuma sediar muitos eventos para manter as coisas animadas. Há festivais de música, celebrações do Dia das Mães, celebrações de Ano Novo e muitos outros também. O estilo de vida que Business Bay oferece tem muitas vantagens.",
          "Escolas famosas e internacionais, serviços médicos, supermercados, bancos e centros financeiros estão a poucos minutos do seu espaço. O lugar eleva simplesmente seu padrão de vida e forma de viver com opções fáceis e intuitivas nas proximidades. Venha e procure refúgio neste maravilhoso lugar cheio de potenciais elementos de prazer, sucesso e diversão.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/LRILbDgvJKQnb8sQS1MoZr8909k/ocWTYw../%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2023-05-25+%D0%B2+09.28.28.png",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Private Garage",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/SmMjvAItWVrFJG86RHM2IWLGkLc/IQrcXQ../BUGATTI+RESIDENCES+BY+BINGHATTI++Garage.jpg",
      },
      {
        name: "Private Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/J44Td2CYKMqBlCdQSFCCJ2vpncQ/hnv2Xw../BUGATTI+RESIDENCES+BY+BINGHATTI++C12+.jpg",
      },
      {
        name: "Private Chef Service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/4W5seUltcTr7kXJDulTgALWdWl0/aHpRRg../19%C2%A0%E2%80%94+%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F+3.jpg",
      },
      {
        name: "Concierge Service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/RF6GX9OOAR8wwQDqVq-PAGUhY7c/blb24w../19%C2%A0%E2%80%94+%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F.jpg",
      },
      {
        name: "Valet Service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/H4MT-P-vWNXH0OWqnKAX2ITpYZ0/Jjrxhw../19.jpg",
      },
      {
        name: "House Keeping",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Rr3sNo1gn7dpu7KaK2iyklr2SXg/014KpQ../19%C2%A0%E2%80%94+%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F+2.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 19,090,000 — AED 34,000,000",
        priceRangeUSD: "$5,197,300 — $9,256,500",
        size: "2018 sqft — 4544 sqft",
        sizeSqm: "187.5 m² — 422.2 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/anOfPEWQMEQntG2AbfMdMvwHqCU/nXxIaw../2+BR.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 36,600,000 — AED 43,400,000",
        priceRangeUSD: "$9,964,400 — $11,815,700",
        size: "4072 sqft — 6469 sqft",
        sizeSqm: "378.3 m² — 601 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/tMS9Dm2Mu7IQnYYe1eho0V_9Jh4/1Zmwkg../3+BR.png",
      },
      {
        type: "Apartments",
        bedrooms: 4,
        priceRange: "AED 52,000,000 — AED 175,000,000",
        priceRangeUSD: "$14,157,000 — $47,643,800",
        size: "6891 sqft — 10161 sqft",
        sizeSqm: "640.2 m² — 944 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/l7vFg8PB9VPW6NYGzO2vFG_3-Dg/RJxsOg../4BR.png",
      },
      {
        type: "Penthouse",
        bedrooms: 5,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "9920 sqft — 22934 sqft",
        sizeSqm: "921.6 m² — 2130.6 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/jp6y0ywj0ZpjVPAH-lM_AVJ5gT8/EWSPwQ../5+PH.png",
      },
      {
        type: "Penthouse",
        bedrooms: 8,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "47110 sqft — 47110 sqft",
        sizeSqm: "4376.7 m² — 4376.7 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/0AKbGvpzPHeFasc9ZIMZcmurMco/OiqHpQ../8+PH.png",
      },
    ],

    developerDetails: {
      name: "Binghatti",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
      description:
        "Binghatti is one of the largest property developers and holding companies in the UAE founded in 2008. Binghatti hyper-properties are designed specifically for those with genuine appreciation of fine architecture, giving their residents the experience of how modern and thoughtful design, combined with cutting-edge technology can make a property more than just a place you live in.",
      website: "https://www.binghatti.com/",
      email: "sales@binghatti.com",
      address: "Binghatti Sales Center, Al Manara, Dubai, OAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "10 AM - 7 PM",
        weekends: "Saturday",
        weekendHours: "10 AM - 4:30 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 9,
    title: "Burj Binghatti Jacob and Co Residences",
    developer: "Binghatti",
    location: "Business Bay",
    city: "Dubai",
    price: "$2,310,626",
    completionDate: "Q4 2026",
    lastUpdated: "Nov 19, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/_OGDABypPea1s2D3OjcHh81Ciac/s-l31g../7.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "On Sale",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/JS0_-6PIsvI",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57767.759820511405!2d55.292952!3d25.186862!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69df02b866af%3A0x14b5a3b340fc651c!2sBURJ%20BINGHATTI%20JACOB%20%26%20CO%20RESIDENCES!5e0!3m2!1sen!2sus!4v1732023782765!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "Downtown Dubai", distance: "2.9 km" },
      { name: "Cinemacity Fountain Views", distance: "4 km" },
      { name: "The Block", distance: "8.2 km" },
      { name: "The Track Meydan Golf", distance: "9.7 km" },
      { name: "Dubai International Airport", distance: "15.7 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/rkkwKXOpM7ii1ivuZTUp5IeitRQ/d-qzJg../burj-binghatti-jacob-co-residences_9sEgV_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/2ztGaTeR46ZmMwnkl-rpi4qcvhQ/I6yu-Q../burj-binghatti-jacob-co-residences_3tl8C_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/DeBgEelLwb3NyZtK-EKsYXGQxFI/oylPqA../burj-binghatti-jacob-co-residences_GOoFf_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/r5iN2C6TazNEPmkqFGaej5nZvNk/uSRZuA../burj-binghatti-jacob-co-residences_jfhTr_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Fn-2OsjjCDnw_nTM8Er-S74Iinc/07cj_Q../burj-binghatti-jacob-co-residences_0pP6e_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/LE12XF68FcyvI0W9PsDpYUZ4n2Y/ZOYd1A../burj-binghatti-jacob-co-residences_AoCDQ_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/R465_J99QUytrraNcE2e0rOQwjE/2AteUQ../burj-binghatti-jacob-co-residences_n7v1T_xl.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/HZ9RRVTqVhh8pXyRMS0nuC2S_wU/dwjGzw../7.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ntR_P_M9sXg3YltUHx0P1pTJ0kY/_GIkrA../6.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/FSxiNrez7RMMggQPbU6OKsIy9V0/tTRq3g../8.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/qOPuk2A9fbgT7h5xpj20oYPCbb4/PyWqPg../19.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/O-afMr5nXKdekiGiV9kl8NO0mWY/wWWtEQ../15.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/3KUAuo7lZucLZau5MUJURVf_GaA/w4qy7g../17.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/UOONIvPnUF6eVs3hY3C_SOIsqrs/Dvv4jw../14.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/SLEHjEX4vPcCwHQNweh1GbvjaB4/RN_Y_A../28.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/5pzILX2TgzoLylaJVy55rbe8_EU/Bp0Ngg../33.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/LeKkV2UTklUl7nnGwQ-nr4fbZRA/piCKkw../29.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dacblJDOD9u8wxRUbYY-9ZWNTuo/NpxYgQ../31.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/z3VjRxrdqeqU4fwJIzdoIy2jhRM/oHKW-Q../16.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/UK3LFfDEog7VIffmAN-0JZE5jHI/p4iylw../20.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/NLBBfFhO2aZ2QaoEEPIZFlIlz4Y/_dqaDw../34.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/KZ9yk56h6LhwnShhSzP8ofgYNNE/QvbbAQ../21.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Sy2IigjHcPLjCnuicJB9vhrcIZY/Oas0cg../46.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/bdb30Go7KhTHPCdD_pbozBUzoNQ/WeMTNQ../23.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XQFn7_hsJt5BlV0wFq8t_S40UBU/ZII3Sw../40.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6pfHtmDuk7tGQT9GWCOCm4wLWxg/zei3Vw../43.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/aPck4piz-zAe-Wp9eCtTqJBOqac/whIzeQ../48.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/u2cXtpZpiRv5pVy5ZBIzLE_xdL4/9NKT2Q../44.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "The highest residential tower, Burj Binghatti at Business Bay, Dubai presenting luxury 2 & 3 bedroom Suites & Penthouses developed by Binghatti Developers. This one of a kind diamond-shaped at the peak of the tower reminiscent of an actual crown designed by Jacob & Co, which will have more than 112-storey and provide residents an iconic way of life.",
          "The Fleur de Jardin Sky Villa - an exceptional residence named after the iconic Fleurs de Jardin watch by Jacob and Co. This magnificent villa offers a truly luxurious living experience in the heart of Dubai. Boasting six spacious and opulent bedrooms, the Fleurs de Jardin Sky Villa occupies a full half-floor space spanning from levels 83 to 96, offering breathtaking 180-degree panoramic views of the city from every angle. Within this premium collection, there are two types of sky villas, each designed to cater to your unique preferences. The first type treats you to an awe-inspiring view of the world-famous Burj Khalifa, while the second type offers a panoramic vista of the waters of the Creek Harbor and the vibrant Dubai skyline.",
        ],
        finishingMaterials:
          "From the finest materials and finishes, every aspect of this magnificent residence has been meticulously crafted to exceed your expectations.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No",
        locationBenefits: [
          "Business Bay is a bustling commercial and residential district located in the heart of Dubai, United Arab Emirates. This area spans over 80 million square feet and is situated on the banks of the Dubai Water Canal, making it a prime location for businesses and residents alike.",
          "Business Bay is also known for its world-class hotels, upscale restaurants, and shopping destinations, making it a popular destination for tourists and residents alike. In addition to its commercial and entertainment offerings, the district also features a range of residential options, from high-end apartments to luxury villas. Business Bay is a thriving and dynamic community that offers a unique blend of business, leisure, and luxury living in one of the most vibrant cities in the world.",
        ],
      },
      pt: {
        generalFacts: [
          "A mais alta torre residencial, Burj Binghatti em Business Bay, Dubai, apresenta suítes e coberturas luxuosas de 2 e 3 quartos desenvolvidas pela Binghatti Developers. Esta torre única, em forma de diamante no pico, reminiscente de uma coroa real desenhada por Jacob & Co, terá mais de 112 andares e proporcionará aos residentes uma forma de vida icônica.",
          "O Fleur de Jardin Sky Villa - uma residência excepcional nomeada após o icônico relógio Fleurs de Jardin da Jacob & Co. Esta magnífica vila oferece uma experiência de vida verdadeiramente luxuosa no coração de Dubai. Com seis espaçosos e opulentos quartos, a Fleurs de Jardin Sky Villa ocupa um espaço de meio andar, abrangendo dos andares 83 a 96, oferecendo vistas panorâmicas de 180 graus da cidade de todos os ângulos. Dentro desta coleção premium, existem dois tipos de sky villas, cada um projetado para atender às suas preferências exclusivas. O primeiro tipo oferece uma vista deslumbrante do mundialmente famoso Burj Khalifa, enquanto o segundo oferece uma vista panorâmica das águas do Creek Harbor e do vibrante horizonte de Dubai.",
        ],
        finishingMaterials:
          "Dos melhores materiais e acabamentos, cada aspecto desta magnífica residência foi meticulosamente elaborado para superar suas expectativas.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não",
        locationBenefits: [
          "Business Bay é um distrito comercial e residencial agitado localizado no coração de Dubai, Emirados Árabes Unidos. Esta área ocupa mais de 80 milhões de pés quadrados e está situada às margens do Dubai Water Canal, tornando-a um local estratégico tanto para negócios quanto para moradores.",
          "Business Bay também é conhecida por seus hotéis de classe mundial, restaurantes sofisticados e destinos de compras, tornando-se um destino popular tanto para turistas quanto para moradores. Além das ofertas comerciais e de entretenimento, o distrito também oferece uma gama de opções residenciais, desde apartamentos de luxo até vilas de alto padrão. Business Bay é uma comunidade dinâmica e próspera que oferece uma mistura única de negócios, lazer e vida luxuosa em uma das cidades mais vibrantes do mundo.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/hzCCABfVLIh0UjM-_QlekXsfzl0/s5k2pQ../%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2023-05-15+%D0%B2+07.13.06.png",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Gymnasium",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/yDeQ7I4M-97gaMyDAxuYS5iH9KE/2pEcJQ../binghatti-canal-business-bay-gym.jpg",
      },
      {
        name: "Luxury spa",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/4HPEx_q4M6lyQ2Ls8vU5meNZI0U/msInoA../FOTO-1.jpg",
      },
      {
        name: "Swimming pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/suFiXXt27bbgbgEo0zjUc0fsfeY/b_hkEg../355166677.jpg",
      },
      {
        name: "Concierge service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/XNdlUKm9bx28u6gcJQxF2mJ4w1Y/YFKcJA../15-Luxury-Concierge-Services-You.jpg",
      },
      {
        name: "Chauffeur service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ft7OfnUqJUKxNXLirgWJnfnAEBI/VJmXKA../chauffeur-service-3-1.jpg",
      },
      {
        name: "Butler service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/fsCrVu1j-suZ54tOMLGVa4LVvKw/A58K9A../luxury-hotel-butler-services-bur.jpg",
      },
      {
        name: "Bodyguard service",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/VLBHkTtC4_isNW66DxQQ02mj19c/gxa9YQ../fd8b0f77d767f1f6640afba6916ff67c.jpg",
      },
      {
        name: "Lobby lounge",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/6_yU0BBwQ8Svz4OE8ikGgbngNSU/SjDiQg../10.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 8,480,000 — AED 11,360,000",
        priceRangeUSD: "$2,308,700 — $3,092,800",
        size: "3287 sqft — 3287 sqft",
        sizeSqm: "305.4 m² — 305.4 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/j7iieK7VSfgJ9zK6YXWU2K-ZPlA/8pMXvQ../2+BB.png",
      },
      {
        type: "Apartments",
        bedrooms: 2.5,
        priceRange: "AED 8,600,000 — AED 9,590,000",
        priceRangeUSD: "$2,341,400 — $2,610,900",
        size: "3302 sqft — 3302 sqft",
        sizeSqm: "306.8 m² — 306.8 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/I4OttwDmvEnZeBc3wNoHRN3zBEk/_2FROA../2.5+BB.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 13,999,999 — AED 17,169,999",
        priceRangeUSD: "$3,811,500 — $4,674,500",
        size: "3264 sqft — 3264 sqft",
        sizeSqm: "303.2 m² — 303.2 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/4pFamsvryBIMDEFECBxPj5HJ41Q/39_peA../3+BB.png",
      },
      {
        type: "Sky Villa",
        bedrooms: 6,
        priceRange: "AED 31,100,000 — AED 36,100,000",
        priceRangeUSD: "$8,467,000 — $9,828,200",
        size: "6900 sqft — 6915 sqft",
        sizeSqm: "641 m² — 642.4 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/6x1sdk_uN9KrP-boicyXKkNsDrE/_P5iEw../6+BB.png",
      },
      {
        type: "Sky Mansion",
        bedrooms: 5,
        priceRange: "AED 175,000,000 — AED 0",
        priceRangeUSD: "$47,643,800 — $0",
        size: "11794 sqft — 11797 sqft",
        sizeSqm: "1095.7 m² — 1096 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/aMXhH8BW1PfZqpTOZbYguH9SwDc/Cfv4IQ../Astronomia.png",
      },
      {
        type: "Sky Mansion",
        bedrooms: 7,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "22393 sqft — 22393 sqft",
        sizeSqm: "2080.4 m² — 2080.4 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/HhfdlTriFWSvbJkOhm3ilKOBmMk/DMaJKA../7b.png",
      },
    ],

    developerDetails: {
      name: "Binghatti",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/AAoYCQdfkOExO2ijE_aRjGkO5x4/FO7mhw../1660053760554.jpeg",
      description:
        "Binghatti is one of the largest property developers and holding companies in the UAE founded in 2008. Binghatti hyper-properties are designed specifically for those with genuine appreciation of fine architecture, giving their residents the experience of how modern and thoughtful design, combined with cutting-edge technology can make a property more than just a place you live in.",
      website: "https://www.binghatti.com/",
      email: "sales@binghatti.com",
      address: "Binghatti Sales Center, Al Manara, Dubai, OAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "10 AM - 7 PM",
        weekends: "Saturday",
        weekendHours: "10 AM - 4:30 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 10,
    title: "The Acres",
    developer: "Meraas",
    location: "Dubailand",
    city: "Dubai",
    price: "$4,259,673",
    completionDate: "FEB 2028",
    lastUpdated: "Nov 13, 2024",

    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/YOfoT5t6FfrR6Qb40SQB1NtFKGA/hjN1CA../The+Acres+Ext+02.jpeg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "On Sale",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/VGdHxUPMw-I",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55423.14166533693!2d55.296060000000004!3d25.049249000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f09421e30b5%3A0x483f37dc1a7e4496!2sThe%20Acres%20by%20Meraas!5e1!3m2!1sen!2sus!4v1732028808848!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/Ll2YU6m0x9iWTIIIYkihWQDbxQk/7cWlBQ../1641553785367.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },
    nearbyPlaces: [
      { name: "Blossom Serena Nursery Dubai", distance: "4.4 km" },
      { name: "The Sustainable City plaza", distance: "9.8 km" },
      { name: "Central Park Mudon", distance: "10.8 km" },
      { name: "Jumeira Public Beach", distance: "24.4 km" },
      { name: "Downtown Dubai", distance: "30 km" },
      { name: "Dubai International Airport", distance: "36.7 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/ILuI_UAK5ibhrq9GGTwD2SZVmMo/irTbHA../img4.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/1eQfYZ-g0N87-VR_akuwiEf98s8/nsoqHw../img17.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/qzvtP2q0g920smVwVqhmzrHkYsU/erj6dQ../The+Acres+Ext+01-4BR-Garden-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/jWsj-zFrlce9LoKTB_uT-v4rrxE/7Z8r8Q../The+Acres+Ext+01-5BR-Front-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/EZrKkWoTeV89JUIZ3gRwCtnC_eU/S3y5wQ../The+Acres+Ext+01-5BR-Garden-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Zk1rxPmC7WlPvcWqG9tbJxUXbNM/R4pjqA../The+Acres+Ext+02-3BR-Front-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Vu9IHoRtdri9pfdlnWDKwRCmhz0/3KiKTw../The+Acres+Ext+02-3BR-Garden-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/kwcoixz-Kl54YuF_APq3QC4skAo/626Nyw../The+Acres+Ext+02-4BR-Front-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/dbYGaAoHYYTHPPPn6Az905vqgIk/mUdBGA../The+Acres+Ext+02-4BR-Garden-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Sy4seswMKd5iTQMBqfuSh5bETdU/4eAs9A../The+Acres+Ext+02-5BR-Front-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/nf52oFJwARSQM1Q0nuoTXxsR0zk/5jXW_g../The+Acres+Ext+02-5BR-Garden-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/smOI4fzb4dQ2M6YR9UHCqlf9b3w/ufJuYQ../3BR+-A-Garden-4K-NoEntourage.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/4k_JG_OyNYPg7E72wcygChF7pPY/gGQt7g../3BR+-B-Garden-4K-NoEntourage.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ihN9Xlt3G-31HapwLS-oJn_qtJg/WLrsOg../The+Acres+Ext+01-3BR-Front-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/3-__cRaxZVRAlArcPLU1wLPPE1Q/AHj-3g../The+Acres+Ext+01-4BR-Front-View.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ABU4rKwo8H75tVUr_I0sxGYWMzA/Q4qdkQ../The+Acres+Ext+01.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/xz-BHAwxsFVx1zhKEKIbQznw2_8/qfZrgA../The+Acres+Ext+02.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/xBBFq0O7f3GhAPl5v70KRvpILIE/oeWimg../The+Acres+Ext+03.jpeg",

          "https://api.reelly.io/vault/ZZLvFZFt/oXmjDjTRNnLktlosztVvGKojLRk/Ua2mIA../The+Acres+5BR+FF+Living+Area.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6vdPXpZ-FlCdZJvpmyqdrxKCmzo/3-56Xw../The+Acres+5BR+Master+Bedroom.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/35vD3eHP2T0ZMCJR2evDv2ncb4k/FA3Teg../The+Acres+5BR+MB.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/j28nE_6en1BZrATjTVqu8bSCBiE/XuZcIQ../5+Beds+A+Living+%26+Dining.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/E5dTe_V8y_moYorrd2jNO8LQPww/4M_0Uw../5+Beds+B+Living.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/zX5bXCb6ym-MsCLMoVS8FE1qQT4/5vTx1g../The+Acres+3BR+F+LR.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XmwC4O8Q3rcL78i4vmvQxkA2NDo/ze5OoQ../The+Acres+3BR+LV+%26+D.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/ZvgiSI4daWC0RY31w-7oJgD814k/TnYOHA../The+Acres+4BR+SC.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/g4GpcU8BAwug3uuCFwvx9vqV0Bg/wGsMow../The+Acres+5BR+Courtyard.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/UppNdFGyk00KfYvanXLZusFstmk/1XvVSg../The+Acres+5BR+Dressing+Room.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Mnl7LuhnHasKP51e0qwQK2VJ92Y/HEGBUA../img5405.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/EBUt3n7_op8AV2LbK57CpMlXvkM/GJLC6Q../img5376.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/QBPfCgA8f8uZdNZy_fuQWOlqI8A/wTpDdw../img5397.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/xdM7SUwCJ3SVbMylzMm8CPN9zw0/JEPCbQ../img5365.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Welcome to The Acres, a brand-new community of standalone villas featuring impeccable surroundings enveloped by nature. Graced with serene gardens and azure lagoons, this exclusive neighbourhood harmonises with the undulating terrain, creating a space that’s connected by meandering pathways to every corner of this ever-flourishing environment.",
          "Discover an array of engaging amenities nestled within carefully curated gardens that foster enriching interactions with nature. These gardens strategically encircle the main park, creating a harmonious blend of natural beauty and recreation. Within this expansive and integrated green network, a variety of facilities and amenities, tailored to a diverse range of interests, are easily accessible for all to enjoy.",
          "Discover The Acres’ exquisite villas, distinguished by their signature outdoor rooms and rear gardens that merge with the nature around you and offer the most refined living space. The villas’ contemporary design, characterised by an open-plan layout, double-height living spaces and floor-to-ceiling windows, effortlessly brings the captivating flora indoors where nature is a natural part of everyday living.",
          "The architecture and design philosophy at The Acres is a testament to a harmonious alliance between modern luxury and nature. Inspired by the elements, the architecture proudly features stone, wood and glass crafting a visual symphony that mirrors the natural surroundings. The villas feature contemporary palettes that bathe the walls in light, while the sleek structures maximise exposure, creating a continuous interaction with the lush environment.",
        ],
        finishingMaterials:
          "Each interior touchpoint is carefully crafted for privacy, luxury, and a seamless connection with the natural tapestry of The Acres.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No",
        locationBenefits: [
          "Dubailand is a sprawling and diverse community located in Dubai, offering a multitude of entertainment, leisure, and residential opportunities. This vibrant community is renowned for its wide range of attractions and experiences, making it a unique destination that caters to residents and visitors of all ages. The community is home to numerous theme parks, including Dubai Parks and Resorts, which features Legoland, Motiongate, Bollywood Parks, and the immersive waterpark experience of Legoland Water Park. These attractions provide endless fun and entertainment for families, creating memorable experiences for both children and adults alike.",
          "Dubailand also boasts a variety of sporting facilities and outdoor spaces, catering to the needs of sports enthusiasts and those seeking an active lifestyle. Additionally, Dubailand features a wide range of retail and dining options. Dubailand is not only focused on entertainment and leisure but also emphasizes education and cultural enrichment. Additionally, Dubailand hosts cultural events, exhibitions, and art festivals that celebrate the region's rich heritage and promote cultural exchange.",
          "The community's convenient location ensures easy access to other parts of Dubai through a well-connected network of highways and public transportation options. Residents of Dubailand can explore the city's iconic landmarks, business districts, and cultural attractions with ease, making it a highly accessible community.",
        ],
      },
      pt: {
        generalFacts: [
          "Bem-vindo ao The Acres, uma nova comunidade de vilas independentes com um entorno impecável, envolvido pela natureza. Rodeado por jardins serenos e lagos azuis, este bairro exclusivo harmoniza-se com o terreno ondulado, criando um espaço conectado por caminhos sinuosos para todos os cantos deste ambiente sempre crescente.",
          "Descubra uma variedade de amenidades envoltas em jardins cuidadosamente planejados, que promovem interações enriquecedoras com a natureza. Esses jardins circundam estrategicamente o parque principal, criando uma mistura harmoniosa de beleza natural e recreação. Dentro dessa vasta e integrada rede verde, uma variedade de instalações e comodidades, adaptadas a uma ampla gama de interesses, são facilmente acessíveis para todos.",
          "Descubra as magníficas vilas do The Acres, caracterizadas por suas salas ao ar livre exclusivas e jardins traseiros que se fundem com a natureza ao seu redor, oferecendo os espaços de vida mais refinados. O design contemporâneo das vilas, caracterizado por um layout aberto, salas de estar de dois andares e janelas do chão ao teto, traz naturalmente a flora cativante para dentro de casa, onde a natureza se torna parte natural do cotidiano.",
          "A filosofia de arquitetura e design do The Acres é um testemunho de uma aliança harmoniosa entre o luxo moderno e a natureza. Inspirada pelos elementos, a arquitetura apresenta com orgulho pedra, madeira e vidro, criando uma sinfonia visual que reflete os arredores naturais. As vilas apresentam paletas contemporâneas que banham as paredes de luz, enquanto as estruturas elegantes maximizam a exposição, criando uma interação contínua com o ambiente exuberante.",
        ],
        finishingMaterials:
          "Cada ponto de contato interior é cuidadosamente elaborado para privacidade, luxo e uma conexão perfeita com o tapeçário natural do The Acres.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não",
        locationBenefits: [
          "Dubailand é uma comunidade vasta e diversificada localizada em Dubai, oferecendo uma multiplicidade de oportunidades de entretenimento, lazer e residenciais. Esta comunidade vibrante é conhecida por sua ampla gama de atrações e experiências, tornando-a um destino único que atende a residentes e visitantes de todas as idades. A comunidade abriga diversos parques temáticos, incluindo o Dubai Parks and Resorts, que apresenta o Legoland, Motiongate, Bollywood Parks e a experiência imersiva do parque aquático Legoland Water Park. Essas atrações oferecem diversão e entretenimento sem fim para as famílias, criando experiências memoráveis para crianças e adultos.",
          "Dubailand também possui uma variedade de instalações esportivas e espaços ao ar livre, atendendo às necessidades dos entusiastas de esportes e daqueles que buscam um estilo de vida ativo. Além disso, Dubailand oferece uma ampla gama de opções de varejo e gastronomia. Dubailand não se concentra apenas em entretenimento e lazer, mas também enfatiza a educação e o enriquecimento cultural. Além disso, Dubailand organiza eventos culturais, exposições e festivais de arte que celebram a rica herança da região e promovem o intercâmbio cultural.",
          "A localização conveniente da comunidade garante fácil acesso a outras partes de Dubai por meio de uma rede bem conectada de rodovias e opções de transporte público. Os residentes de Dubailand podem explorar os marcos icônicos da cidade, os distritos comerciais e as atrações culturais com facilidade, tornando-a uma comunidade altamente acessível.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/YTJe7K9gkVDDryzKfhNH2ZXrpps/UB8hiA../master.png",
    buildings: [
      {
        name: "Tower",
        description: "Studio, 1 & 2 bedroom apartments",
        imageUrl: "",
      },
    ],
    facilities: [
      {
        name: "Village Garden",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/_yzsqYkz41z5BVQ8n4ulbT4Fezg/Xzzz9w../The+Acres+Ext+07.jpeg",
      },
      {
        name: "Community Clubhouse",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ogB_OYU2pXKCDb8iROnabmhd8Cs/X5dXYw../The+Acres+Ext+13.jpg",
      },
      {
        name: "Sports Areas",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xAVwTqs0IFzhO8L72e4dlP8RCWQ/GBJL9A../The+Acres+Ext+11.png",
      },
      {
        name: "Event Lawn & Event Island",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/D5Fi4vD44pxUFhyLFWo8oBgUOfI/c_NS2g../The+Acres+Ext+11.png",
      },
      {
        name: "Swimmable Lagoons & Lakes",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/nQT8zCiK0cKAuoSLozH_KDGPK28/ZRm1gw../The+Acres+Ext+05.jpeg",
      },
      {
        name: "Lagoon Clubhouse",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/iyd1PMqofL_6nDSbfS2YBiXn1lI/TkOpIg../The+Acres+Ext+09.jpeg",
      },
      {
        name: "Jogging Tracks",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/IuwsVSsIZmGVb1wz19NPclRrQNw/rHp27g../The+Acres+Ext+06.jpeg",
      },
      {
        name: "Natural Ponds",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Q-HnqcWUe75sDgMc-qjBhy6pATg/lv2_lQ../The+Acres+Ext+12.jpg",
      },
      {
        name: "Kids’ Play Areas",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/zx4nzVOJEOf2tdGcVuO6w3_-SKA/7pMcig../17.jpg",
      },
      {
        name: "Perfume Garden",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/a1GtXhQMMR2jVdhz3wrwt3vANDA/CLSrdw../18.jpg",
      },
      {
        name: "Edible Garden",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/7PC7J33IzG_rYgUU3AYZVaorIyY/gdg_hw../19.jpg",
      },
    ],

    units: [
      {
        type: "Villa",
        bedrooms: 6,
        priceRange: "AED 15,633,000 — AED 0",
        priceRangeUSD: "$4,256,100 — $0",
        size: "7747 sqft — 0 sqft",
        sizeSqm: "719.7 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/c0OybW9mtbo0eMhrP6ZA0FfjGkQ/Bnayag../61.png",
      },
      {
        type: "Villa",
        bedrooms: 7,
        priceRange: "AED 21,734,000 — AED 0",
        priceRangeUSD: "$5,917,100 — $0",
        size: "10757 sqft — 0 sqft",
        sizeSqm: "999.4 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xc6RSoFrB9DPMQ2OYYOjZtrt0Wo/3Rjxmg../71.png",
      },
      {
        type: "Villa",
        bedrooms: 3,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "0 sqft — 0 sqft",
        sizeSqm: "0 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/-4zRfFp83BF8Zte_VrpXgVAmm5M/5tAumw../3.png",
      },
      {
        type: "Villa",
        bedrooms: 4,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "0 sqft — 0 sqft",
        sizeSqm: "0 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/bQ4VG5n-eKDJO3vJ0uMiY20hNns/iGebkA../4.png",
      },
      {
        type: "Villa",
        bedrooms: 5,
        priceRange: "AED 0 — AED 0",
        priceRangeUSD: "$0 — $0",
        size: "0 sqft — 0 sqft",
        sizeSqm: "0 m² — 0 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/0b0sGkPQNznj21Q9kg6VPOUDyjg/T1x7Qw../5.png",
      },
    ],

    developerDetails: {
      name: "Meraas",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/Ll2YU6m0x9iWTIIIYkihWQDbxQk/7cWlBQ../1641553785367.jpeg",
      description:
        "Meraas is a prominent Dubai-based developer known for its innovative approach to urban planning and real estate development. The company is recognized for creating modern, integrated communities and iconic landmarks that combine residential, commercial, and entertainment spaces. Meraas projects emphasize luxury, sustainability, and cutting-edge design, with a focus on enhancing the quality of life for residents and visitors. Their portfolio includes major developments like City Walk, Bluewaters Island, and Jumeirah Bay, showcasing a commitment to transforming Dubai's urban landscape into a vibrant, world-class destination.",
      website: "https://www.meraas.com/en",
      email: "customersupport@meraas.ae",
      address:
        "Meraas Sales Boutique, Building 09 City Walk Phase 2, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "8 AM - 5 PM",
        weekends: "Saturday - Sunday",
        weekendHours: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 11,
    title: "Design Quarter",
    developer: "Meraas",
    location: "Dubai Design District",
    city: "Dubai",
    completionDate: "MAR 2027",
    lastUpdated: "Nov 13, 2024",
    price: "$830,790",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/7oKIG4e-kvAREPQmSESodWoYrSU/zaC5gg../Rh7y_3J5dHE8IQEWeslxyX_aGTO6kMnT.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    recommended: true,
    recommendationText: "Recommended",
    videoUrl: "https://www.youtube.com/embed/slzWXhdkovo",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13858.611520282071!2d55.292149999474546!3d25.190251060607963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f68127b3134af%3A0x6f1a1389214ddbe5!2sBusiness%20Bay%20-%20Dubai%20Design%20District%20-%20Dubai%20-%20United%20Arab%20Emirates!5e1!3m2!1sen!2sus!4v1732037113112!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/Ll2YU6m0x9iWTIIIYkihWQDbxQk/7cWlBQ../1641553785367.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "The Dubai Mall", distance: "4 km" },
      { name: "Downtown", distance: "4 km" },
      { name: "International British Online School", distance: "4.4 km" },
      { name: "Jumeira Public Beach", distance: "8.8 km" },
      { name: "Dubai International Airport", distance: "12.2 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/XToFVnnItGUaZx4MKto6IW8x6e4/Q9mSmw../Design-Quarter+D3+EXT+03+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/6ZM7OUUAYKVpxCkXJ8Yer9e4_fU/Yy-UDg../Design-Quarter+D3+EXT+06+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/D3AC8bop7msBcz0LhmAEl2TzHCA/oMAvmw../Design-Quarter+D3+EXT+05+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Oz2qlzvwbZhLF1_AfOHnTRDWDOE/-H-IYA../Design-Quarter+D3+EXT+04+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/skN-_CZMMZJygp3HhATY8gKkUdk/7Ufmqw../Design-Quarter+D3+EXT+01+MAR+2023.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/5GA8ClTTXFzMcMUhDYrMMdxqLSA/-OgRKA../Design-Quarter+D3+INT+PA+02+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/yPMeO_S8WNiULftURGdmQf8NrfY/Pu-_aA../Design-Quarter+D3+INT+PA+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/N5dWsJ7nahx1gfrs_Y-zFXul-Cc/-9M0sw../Design-Quarter+D3+INT+LV+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/668hKHGoxytn0b2NA5rPYHuvC_4/r9Jclw../Design-Quarter+D3+INT+K+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/w-4BTDqHH__Z3fmX6HgWDH9ICPg/rJ-Hsw../Design-Quarter+D3+INT+LV+02+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/cy5eCZVBHYDXIyXYu3ZeiSh2paM/H_jZlQ../Design-Quarter+D3+INT+LV+04+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/qDsonPwv4ec9-lGALW-waTsORBk/au3fbQ../Design-Quarter+D3+INT+BT+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/gIjLFtae6Oq5RVA9DQdQkbZLafY/juNuPg../Design-Quarter+D3+INT+BR+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/91HEYaJoDXoMajkAPNc4ezxJcu0/O86Uuw../Design-Quarter+D3+INT+LV+03+MAR+2023.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Design Quarter by Meraas offers luxurious apartments located at Dubai Design District (D3). This development provides comfort with outstanding class, smart home features, and an ideal location in the heart of Dubai's cultural centre.",
          "A true reflection of creativity, Design Quarter offers 1 to 3-bedroom apartments with spectacular views and incredible amenities. This one-of-a-kind community is the first in the globally renowned Dubai Design District, catering to individuals with a creative spirit.",
          "The development showcases modern architecture and facilities that blend work and life seamlessly, with exceptional views of Burj Khalifa and the Dubai Water Canal, further enhancing the charm of this prime location.",
        ],
        finishingMaterials:
          "Elegant construction with carefully selected materials and superior finishes.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No",
        locationBenefits: [
          "Design Quarter is situated in Dubai's most enhanced community, Dubai Design District (D3), known for its brilliant designs and variety of homes.",
          "The area connects you to major landmarks, malls, schools, hospitals, and restaurants, making it an ideal location for everything you need.",
          "This location is perfect for residents looking for comfort, creativity, and a rich community life in the heart of Dubai.",
        ],
      },
      pt: {
        generalFacts: [
          "Design Quarter da Meraas oferece apartamentos luxuosos localizados no Dubai Design District (D3). Este empreendimento oferece conforto com classe excepcional, recursos de automação residencial e uma localização ideal no coração do centro cultural de Dubai.",
          "Uma verdadeira reflexão da criatividade, Design Quarter oferece apartamentos de 1 a 3 quartos com vistas espetaculares e comodidades incríveis. Esta comunidade única é a primeira no renomado Dubai Design District, voltada para pessoas com espírito criativo.",
          "O desenvolvimento apresenta arquitetura moderna e instalações que combinam trabalho e vida de maneira perfeita, com vistas excepcionais do Burj Khalifa e do Dubai Water Canal, aumentando ainda mais o charme desta localização privilegiada.",
        ],
        finishingMaterials:
          "Construção elegante com materiais cuidadosamente selecionados e acabamentos superiores.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não",
        locationBenefits: [
          "Design Quarter está situado na comunidade mais desenvolvida de Dubai, o Dubai Design District (D3), conhecido pelos seus designs brilhantes e variedade de residências.",
          "A área conecta você a marcos importantes, shoppings, escolas, hospitais e restaurantes, tornando-a uma localização ideal para tudo o que você precisa.",
          "Este local é perfeito para residentes que buscam conforto, criatividade e uma vida comunitária rica no coração de Dubai.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/4qMVUjSM8adq5y8A_Yvkvcptggs/U7wLVg../%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA+%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0+2023-05-10+%D0%B2+12.44.00.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Swimming Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/qfvtb6XyCzqmsEXiZxCNNHmHBkg/C12VmQ../Design-Quarter+D3+Pool+Area+View+EXT+11+MAR+2023.jpg",
      },
      {
        name: "Gymnasium",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/irT3U8ckAQ8U2LDypzpM3zXBivs/hrr4-w../Design-Quarter+D3+INT+GYM+01+MAR+2023.jpg",
      },
      {
        name: "Parks & Gardens",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/HiVwcJlkpguu60f2xGKGL_y98c0/pZRinQ../Design-Quarter+D3+EXT+14+MAR+2023.jpg",
      },
      {
        name: "Play Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/kn0kHT6rdkU8uI-dmPx3buWT7V8/AJ6cpA../Design-Quarter+D3+EXT+15+MAR+2023.jpg",
      },
      {
        name: "Yoga Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/NZYJNhw3W1i-sWz4LjGQbeYKkUU/xfj1rw../Design-Quarter+D3+EXT+16+MAR+2023.jpg",
      },
    ],
    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 3,049,000 — AED 3,098,000",
        priceRangeUSD: "$830,100 — $843,400",
        size: "1157 sqft — 1168 sqft",
        sizeSqm: "107.5 sqm — 108.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/JR64WVMQZ9FgK152Y48Oop9xqC4/rmefBA../2+DQ.png",
      },
      {
        type: "Duplex",
        bedrooms: 3,
        priceRange: "AED 7,025,000 — AED 0",
        priceRangeUSD: "$1,912,600 — $0",
        size: "2954 sqft — 3305 sqft",
        sizeSqm: "274.4 sqm — 307 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/K_eabPBqnXB93GN0tPuqc1tK2us/S1jSNw../3+DP+DQ.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 6,028,000 — AED 0",
        priceRangeUSD: "$1,641,100 — $0",
        size: "2019 sqft",
        sizeSqm: "187.6 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/fIeSWWzSH6qv9ooiy9TG3bMVvZ4/5t_pRA../3+DQ.png",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/J76S6ESmUodHbM2InQWQk6vjvD4/BjvNMw../1+DQ.png",
      },
    ],

    developerDetails: {
      name: "Meraas",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/Ll2YU6m0x9iWTIIIYkihWQDbxQk/7cWlBQ../1641553785367.jpeg",
      description:
        "Meraas is a prominent Dubai-based developer known for its innovative approach to urban planning and real estate development. The company is recognized for creating modern, integrated communities and iconic landmarks that combine residential, commercial, and entertainment spaces. Meraas projects emphasize luxury, sustainability, and cutting-edge design, with a focus on enhancing the quality of life for residents and visitors. Their portfolio includes major developments like City Walk, Bluewaters Island, and Jumeirah Bay, showcasing a commitment to transforming Dubai's urban landscape into a vibrant, world-class destination.",
      website: "https://www.meraas.com/en",
      email: "customersupport@meraas.ae",
      address:
        "Meraas Sales Boutique, Building 09 City Walk Phase 2, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "8 AM - 5 PM",
        weekends: "Saturday - Sunday",
        weekendHours: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 12,
    title: "Riwa",
    developer: "Meraas",
    location: "Madinat Jumeirah Living",
    city: "Dubai",
    completionDate: "AUG 2027",
    lastUpdated: "Nov 13, 2024",
    price: "$1,670,300",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/YdeJP5ZWMGiKrw2FvBiGd9OgGHQ/JYKSeA../MJL-Riwa-Ext-02+%281%29.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    recommended: true,
    recommendationText: "Recommended",
    videoUrl: "https://www.youtube.com/embed/0_GBWvYl5n8",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55461.883292787585!2d55.194685!3d25.129892!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b006bd7a739%3A0x57be3fec1a438e4b!2sMJL%20RIWA%20by%20Meraas!5e1!3m2!1sen!2sus!4v1732037944586!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/Ll2YU6m0x9iWTIIIYkihWQDbxQk/7cWlBQ../1641553785367.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "The Ripe Market- Academy Park", distance: "0.75 km" },
      { name: "Kings' School Dubai", distance: "1.2 km" },
      { name: "The Mall", distance: "1.8 km" },
      { name: "Jumeirah Public Beach", distance: "2.7 km" },
      { name: "GHS Juba", distance: "13.5 km" },
      { name: "Downtown Dubai", distance: "14.1 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/1O69lRtyvZA5SUioDDu0EMQeR9w/mZ96Vg../MJL-Riwa-Ext-01.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/TuIJWtSKvUGW5hx7jvwGJmhmmuU/jmdTAQ../MJL-Riwa-Ext-02.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/0CSlgZ0IINgIQLfOJoNzYUlpbQQ/YAS7mA../MJL-Riwa-Ext-04.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/bRAHG5KxWnqObka8tRNakV3RM6I/7kDzKg../MJL-Riwa-Ext-10.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/XQtGsC1cY-2vVV-WiDzRVK5s9V4/Ini-0w../MJL-Riwa-Ext-13.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/LYBzZn71czLCMG2DKzqREXpYqbc/bu_VYg../MJL-Riwa-Ext-09.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/mJtXe0urZjsrrvr_qugQbjwwUyY/VzPMHw../MJL-Riwa-Int-BR-01.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/sWRSc5O0i8x0qipejmtGMsbL3V4/_gb7Ow../MJL-Riwa-Int-BT-01.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/SBsie4DK65O4pa-KgiLq9Co-kbE/hA8FFw../MJL-Riwa-Int-Kitchen.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/jHlqn1HfQl2yHndXbA4QoZ7dWUo/Uags6g../MJL-Riwa-Int-LV-01.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/wKBpa4vFd4-X_EMOp0InCu6qXMc/vqjj2w../MJL-Riwa-Int-LV-02.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/cy5eCZVBHYDXIyXYu3ZeiSh2paM/H_jZlQ../Design-Quarter+D3+INT+LV+04+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/qDsonPwv4ec9-lGALW-waTsORBk/au3fbQ../Design-Quarter+D3+INT+BT+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/gIjLFtae6Oq5RVA9DQdQkbZLafY/juNuPg../Design-Quarter+D3+INT+BR+01+MAR+2023.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/91HEYaJoDXoMajkAPNc4ezxJcu0/O86Uuw../Design-Quarter+D3+INT+LV+03+MAR+2023.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Nestled among lush green landscapes, Riwa marks the newest addition to one of the most exclusive neighborhoods in Dubai. With a contemporary architectural ethos, Riwa offers bright, refined, and expansive living spaces ranging from 1, 2, and 3-bedroom residences and a 4-bedroom penthouse. Located between Al Wasl and Jumeirah Beach Roads, Riwa boasts a central location surrounded by iconic views of the Burj Al Arab. The residences provide easy access to luxury retail, gourmet dining, leisure, and entertainment, featuring exquisitely designed living spaces that redefine contemporary elegance.",
          "Modern design in light colors, high quality materials.",
        ],
        finishingMaterials:
          "Modern design in light colors, high quality materials.",
        kitchenAppliances: "Kitchen is fully equipped with modern appliances.",
        furnishing: "No.",
        locationBenefits: [
          "Madinat Jumeirah Living by Dubai Holding, more popularly known as MJL is a prime residential development in Jumeirah district, Dubai. The freehold development comprises 60 buildings and offers unsurpassed views of the sea and that of the city's glittering sky. Being a pedestrian and environment-friendly development, it does succeed in offering you to live in residences that at best can be termed as the epitome of comfort.",
          "As the safety of the residents is of great concern, car access is limited to designated drop off areas, which further contributes to a clean environment. Moreover, ample basement and parking space are made available for visitors and it does help to a great extent.",
          "Considering the abundant serene and open space along with the relaxing environment, you will get an opportunity to make a fresh start to your life. In fact, right about every corner, you will come across a major surprise and this is something that makes for an impressive lifestyle.",
        ],
      },
      pt: {
        generalFacts: [
          "Riwa está situada entre paisagens verdes exuberantes e marca a mais nova adição a um dos bairros mais exclusivos de Dubai. Com um ethos arquitetônico contemporâneo, Riwa oferece espaços habitacionais amplos, refinados e iluminados, variando de residências de 1, 2 e 3 quartos e um penthouse de 4 quartos. Localizada entre as ruas Al Wasl e Jumeirah Beach, Riwa ostenta uma localização central, rodeada por vistas icônicas do Burj Al Arab. As residências oferecem fácil acesso a varejo de luxo, restaurantes gourmet, lazer e entretenimento, com espaços de convivência projetados de forma primorosa que redefinem a elegância contemporânea.",
          "Design moderno em cores claras, materiais de alta qualidade.",
        ],
        finishingMaterials:
          "Design moderno em cores claras, materiais de alta qualidade.",
        kitchenAppliances:
          "Cozinha totalmente equipada com eletrodomésticos modernos.",
        furnishing: "Não.",
        locationBenefits: [
          "Madinat Jumeirah Living da Dubai Holding, mais conhecida como MJL, é um desenvolvimento residencial de primeira linha no distrito de Jumeirah, em Dubai. O desenvolvimento de propriedade integral é composto por 60 edifícios e oferece vistas imbatíveis do mar e do céu cintilante da cidade. Sendo um desenvolvimento pedonal e ambientalmente amigável, consegue oferecer residências que podem ser consideradas o auge do conforto.",
          "Como a segurança dos moradores é uma grande preocupação, o acesso de carros é limitado às áreas de desembarque designadas, o que contribui ainda mais para um ambiente limpo. Além disso, há amplo espaço no subsolo e estacionamento disponível para visitantes, o que ajuda consideravelmente.",
          "Considerando o espaço sereno e aberto abundante, juntamente com o ambiente relaxante, você terá a oportunidade de recomeçar sua vida. De fato, em cada canto, você encontrará uma grande surpresa e isso é algo que faz desta uma vida impressionante.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/pUki1maiLHrZXkhSGzvVFbRBD4I/98Eong../ri.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Swimming Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ZFhs3W4QR3MmYZuvG7fDVn-AflY/PmZT9g../MJL-Riwa-Ext-12.jpg",
      },
      {
        name: "Gym",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/tmuPvWqsIiWRJvtQhSUxEDSbbYs/sgzTgg../MJL-Riwa-Int-Gym.jpg",
      },
      {
        name: "Sports Park",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/sHQmtYEeO7fUVYracXmQvZ40XrM/PQDHag../MJL-Riwa-Ext-11.jpg",
      },
      {
        name: "Play Areas",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/_Onc2mSgaAWv4qTbbm_AbmFng7I/BIgTDg../MJL-Riwa-Ext-05.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 6,130,000 — AED 6,500,000",
        priceRangeUSD: "$1,668,900 — $1,769,600",
        size: "2001 sqft — 2327 sqft",
        sizeSqm: "185.9 sqm — 216.2 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/KU-tjAA6xBijvBApWoM4-brfF0s/TT0rXQ../3br.png",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/SX4pN3Fwww5nnXLktqpmmMiFzhg/3Z6wpg../1br.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/tZPtGbKVvDYG9CsoKr_cYHSeAnI/4s3Wew../2br.png",
      },
      {
        type: "Penthouse",
        bedrooms: 4,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/GR2ZZbziDC_561u1NIB3XQQD9KI/S9NDYw../4br.png",
      },
    ],

    developerDetails: {
      name: "Meraas",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/Ll2YU6m0x9iWTIIIYkihWQDbxQk/7cWlBQ../1641553785367.jpeg",
      description:
        "Meraas is a prominent Dubai-based developer known for its innovative approach to urban planning and real estate development. The company is recognized for creating modern, integrated communities and iconic landmarks that combine residential, commercial, and entertainment spaces. Meraas projects emphasize luxury, sustainability, and cutting-edge design, with a focus on enhancing the quality of life for residents and visitors. Their portfolio includes major developments like City Walk, Bluewaters Island, and Jumeirah Bay, showcasing a commitment to transforming Dubai's urban landscape into a vibrant, world-class destination.",
      website: "https://www.meraas.com/en",
      email: "customersupport@meraas.ae",
      address:
        "Meraas Sales Boutique, Building 09 City Walk Phase 2, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "8 AM - 5 PM",
        weekends: "Saturday - Sunday",
        weekendHours: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 13,
    title: "Bay Grove Residences",
    developer: "Nakheel",
    location: "Dubai Islands",
    city: "Dubai",
    completionDate: "Q3 2028",
    lastUpdated: "Oct 22, 2024",
    price: "No info",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/JSmi1LQHyOZVfZkOtcrLtHTpAL8/cnJVNQ../42.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    recommendationText: "Recommended",
    recommended: true,
    videoUrl: "https://www.youtube.com/embed/WDiZAhc4apg", //PlaceHolder Video
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55381.96414936197!2d55.31975400000001!3d25.305331!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5b0031a80c05%3A0x3aabfd68d9c1a2bf!2sBay%20Grove%20By%20Nakheel!5e1!3m2!1sen!2sus!4v1732038747126!5m2!1sen!2sus",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/3Pp7XAnJ2wEF7tvGMtUTkwoc9MU/GuHcCQ../1664000554247.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "Dubai Islands Beach", distance: "8.5 km" },
      { name: "Deira Island beach", distance: "9 km" },
      { name: "Spectrum Nursery", distance: "11.1 km" },
      { name: "Century Mall", distance: "13.2 km" },
      { name: "Dubai International Airport", distance: "14.6 km" },
      { name: "Downtown Dubai", distance: "25.5 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/e7SlVbYUZ2YQIb_EEuh5OX_k-3U/Qjkr6Q../42.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/AkEl0gWeB3ywnTBC4ahORmxKzEw/dlhQ0Q../44.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/2mBzWN_TEyakTDeBSKdcmDbZn0Q/nRn3Xw../52.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/w3MReTH0IMTOaIlyIiHpws6Z3ok/KPfyJQ../55.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/VfAbd2GPSJq0iYiEGVpMfdudEng/ANoMmg../60.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/GI2VB6MdqAcjGILfRscSLvJ5Mbs/LWGuRQ../61.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/DnHK-jtdmtc2XffwT5hXEEOvHdM/8KrFag../67.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/qEy8lzTfGR-kDyupPhxs6kdxivE/_AvuMw../73.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/w8owHQwipnsDauXQsZdPIqxrCks/2SoZXg../74.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/lRUFDzadmdAl6grbBhUz0-V2bfU/Z229Sw../96.jpg",
        ],
      },
    ],

    overview: {
      en: {
        generalFacts: [
          "Bay Grove Residences is a premium residential development located in one of Dubai’s most sought-after neighborhoods. The project offers a blend of modern design and luxury living, catering to those seeking a high-end lifestyle in the heart of the city. The sleek architecture of Bay Grove Residences, combined with its thoughtful layout and contemporary interiors, creates a sophisticated living space for residents. With a range of apartments that offer breathtaking views of the city skyline and the waterfront, this development stands out as a prime real estate destination.",
          "The development features an array of world-class amenities, including a state-of-the-art fitness center, an infinity swimming pool, and landscaped gardens that provide a serene escape from the bustling city. Bay Grove Residences also offers 24/7 concierge and security services, ensuring a comfortable and secure living experience. Additionally, residents have access to exclusive dining, shopping, and entertainment options within the complex, making it a self-contained urban haven for luxury living.",
          "Strategically located close to key landmarks, Bay Grove Residences offers easy access to major business districts, shopping malls, and leisure destinations in Dubai. The development is designed to meet the needs of both families and professionals, with a focus on creating a vibrant community that combines convenience, style, and comfort. Bay Grove Residences represents a new standard in upscale urban living, making it an ideal choice for those looking to invest in Dubai’s real estate market.",
        ],
        finishingMaterials: "Modern finishing with high-quality materials.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "No.",
        locationBenefits: [
          "Dubai Islands is a stunning new waterfront development that reflects Dubai's ambition to create iconic, world-class destinations. Located off the coast of Deira, this group of five interconnected islands offers an expansive 17 square kilometers of mixed-use land, designed to accommodate a blend of residential, commercial, and hospitality projects.",
          "The islands are strategically positioned to provide easy access to both Dubai's city center and the international airport, making it a prime location for residents, tourists, and businesses alike.",
          "The development includes a diverse array of attractions and amenities. Dubai Islands will feature luxury hotels, resorts, and beach clubs that provide an unparalleled experience of waterfront living. Additionally, there will be ample green spaces, parks, and marinas to cater to outdoor enthusiasts.",
          "Aimed at transforming the northern part of Dubai into a major tourism and lifestyle destination, Dubai Islands is expected to attract significant interest from investors and developers. The islands are set to host numerous cultural and entertainment venues, including theaters, museums, and shopping districts, which will make it a vibrant community for arts and culture.",
          "With sustainable and innovative infrastructure, Dubai Islands represents the future of luxury waterfront development, offering a unique lifestyle experience that blends modernity with nature.",
        ],
      },
      pt: {
        generalFacts: [
          "Bay Grove Residences é um desenvolvimento residencial premium localizado em um dos bairros mais procurados de Dubai. O projeto oferece uma combinação de design moderno e luxo, atendendo aqueles que buscam um estilo de vida sofisticado no coração da cidade. A arquitetura elegante do Bay Grove Residences, combinada com seu layout cuidadosamente planejado e interiores contemporâneos, cria um espaço de convivência sofisticado para os moradores. Com uma gama de apartamentos que oferecem vistas deslumbrantes do horizonte da cidade e da orla, este desenvolvimento se destaca como um destino imobiliário de primeira linha.",
          "O empreendimento apresenta uma gama de comodidades de classe mundial, incluindo um centro de fitness de última geração, uma piscina infinita e jardins paisagísticos que oferecem um refúgio sereno da cidade movimentada. O Bay Grove Residences também oferece serviços de concierge e segurança 24 horas, garantindo uma experiência de vida confortável e segura. Além disso, os moradores têm acesso a opções exclusivas de restaurantes, compras e entretenimento dentro do complexo, tornando-o um refúgio urbano auto-suficiente para o luxo.",
          "Estratégicamente localizado perto de pontos de referência chave, o Bay Grove Residences oferece fácil acesso aos principais distritos comerciais, shopping centers e destinos de lazer em Dubai. O desenvolvimento foi projetado para atender às necessidades de famílias e profissionais, com foco na criação de uma comunidade vibrante que combina conveniência, estilo e conforto. O Bay Grove Residences representa um novo padrão de vida urbana de alto nível, sendo uma escolha ideal para quem deseja investir no mercado imobiliário de Dubai.",
        ],
        finishingMaterials:
          "Acabamento moderno com materiais de alta qualidade.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Não.",
        locationBenefits: [
          "Dubai Islands é um novo e deslumbrante desenvolvimento à beira-mar que reflete a ambição de Dubai em criar destinos icônicos e de classe mundial. Localizado ao largo da costa de Deira, este grupo de cinco ilhas interconectadas oferece 17 quilômetros quadrados de terreno de uso misto, projetado para acomodar uma combinação de projetos residenciais, comerciais e de hospitalidade.",
          "As ilhas estão estrategicamente posicionadas para proporcionar fácil acesso ao centro da cidade de Dubai e ao aeroporto internacional, tornando-o uma localização ideal para moradores, turistas e empresas.",
          "O desenvolvimento inclui uma variedade diversificada de atrações e comodidades. Dubai Islands contará com hotéis de luxo, resorts e clubes de praia que proporcionarão uma experiência incomparável de vida à beira-mar. Além disso, haverá amplos espaços verdes, parques e marinas para atender aos entusiastas ao ar livre.",
          "Com o objetivo de transformar a parte norte de Dubai em um destino de turismo e estilo de vida de destaque, Dubai Islands deve atrair grande interesse de investidores e desenvolvedores. As ilhas terão uma série de locais culturais e de entretenimento, incluindo teatros, museus e distritos de compras, tornando-o uma comunidade vibrante para as artes e cultura.",
          "Com infraestrutura sustentável e inovadora, Dubai Islands representa o futuro do desenvolvimento à beira-mar de luxo, oferecendo uma experiência de vida única que combina modernidade e natureza.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/QT0oMTCjB-jsCs6EcrEAbgz_wcc/GI1BfQ../MasterPlan.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Adult and Kids’ Pools",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/nD727ebe004LY7AuQ5U_vWNrP_U/ZX_alA../Adult+and+Kids%E2%80%99+Pools.jpg",
      },
      {
        name: "Beach Boardwalk",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/u-ry28uJZiKYFxN53KbdTL1toME/qc2xUA../Beach+Boardwalk.jpg",
      },
      {
        name: "Clubhouse",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/2ESsG9RbTBhlVaENxTG6QYsSwEc/VN7MXA../Clubhouse.jpg",
      },
      {
        name: "Fitness Centre",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/i_5LxpGi9AcTBliyAZQcBASWcc8/YIAgeg../Fitness+Centre.jpg",
      },
      {
        name: "Indoor & Outdoor Kids’ Play Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/1EIjcJvxZ5wMgelprtRXRyZ-EfI/Dyly-Q../Indoor+%26+Outdoor+Kids%E2%80%99+Play+Area.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "871 sqft — 1110 sqft",
        sizeSqm: "80.9 sqm — 103.1 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/pp-UPI21MjM-jZ_ASjXLrJCVK3c/rT4tFA../1BR.jpeg",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "1280 sqft — 3851 sqft",
        sizeSqm: "118.9 sqm — 357.8 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/bNIupEEXJvi-dZxuRixXWADEayw/NkKoBg../2BR.jpeg",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "2121 sqft — 3884 sqft",
        sizeSqm: "197 sqm — 360.8 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ZzizIwpnm2wpkxu6Toa-Y5SrGhs/uQzcaw../3BR.jpeg",
      },
      {
        type: "Duplex",
        bedrooms: 4,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "3432 sqft — 3798 sqft",
        sizeSqm: "318.8 sqm — 352.8 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/RQ2Ka2sU2BNmOjUVCTcbfFT9ktE/f0at4A../4BR+D.jpeg",
      },
      {
        type: "Penthouse",
        bedrooms: 4,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "3405 sqft — 3780 sqft",
        sizeSqm: "316.3 sqm — 351.2 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/iW5G5WC30TG6dRS6xpFiXD7UBmc/BrjFYQ../4BR+PH.jpeg",
      },
    ],

    developerDetails: {
      name: "Nakheel",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/3Pp7XAnJ2wEF7tvGMtUTkwoc9MU/GuHcCQ../1664000554247.jpeg",
      description:
        "Dubai-based Nakheel is a world-leading master developer whose innovative, landmark projects form an iconic portfolio of master communities and residential, retail, hospitality and leisure developments that are pivotal to realising Dubai’s vision. Nakheel’s waterfront projects, including the world-famous, award-winning Palm Jumeirah, have added more than 300 kilometres to Dubai’s original, 70km coastline, paving the way for the development of hundreds of seafront homes, resorts, hotels and attractions.",
      website: "https://www.nakheel.com/",
      email: "customercare@nakheel.com",
      address:
        "King Salman Bin Abdul Aziz Al Saud Street, Al Sufouh 2, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "8:30 AM - 5 PM",
        weekends: "Saturday - Sunday",
        weekendHours: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 14,
    title: "Palm Jebel Ali Villas",
    developer: "Nakheel",
    location: "Palm Jebel Ali",
    city: "Dubai",
    completionDate: "Q4 2027",
    lastUpdated: "Nov 19, 2024",
    price: "$11,016,000",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/hpwSBe1WMBOua0ENcDXmM_HmlS4/ZvJojA../4.jpg",
    statusBadge: "Presale (EOI)",
    salesStatus: "Presale (EOI)",
    //commissionBadge: "1M AED Commission",
    recommended: false,
    videoUrl: "https://youtube.com/embed/S7IkWQLZg4w",
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=24.99648361525615, 54.982000131724334&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/3Pp7XAnJ2wEF7tvGMtUTkwoc9MU/GuHcCQ../1664000554247.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "Jebel Ali Kite Beach", distance: "9.7 km" },
      { name: "SHEIKHA Mahra Maryam Fatema", distance: "13.2 km" },
      { name: "Nikon School - Jebel Ali", distance: "15.7 km" },
      { name: "Al Maktoum International Airport", distance: "28.9 km" },
      { name: "Downtown Dubai", distance: "51.1 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/rwFPGiM-sX3_anvJMCQOB8xG7Jk/umfA4w../9.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Aa1_2HAvWHb7bpiXe6wRmdjLXgU/fB4s-Q../11.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/izaOg9kYf2V-gvT9mRvpUrYvl1Y/zef9nQ../14.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/2FCc30HxfEfQx1J7dkwjOARSTl0/Jg_5yg../16.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/SoPiW79MEbiHequsuH9pUthEEXE/KZ5JXw../18.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/b1wxv4f9ydn0ck0M39NtZySLpHc/3vCqGg../78.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/au88SoJMF3ucEXLhlDveR31O1d4/Mvy4-Q../79.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/KnGHYcUqqGAzI5UWg1e9KP47xjI/uhG8nA../82.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/CfOvJoVnbUBpfLrs3Tbf5U2hpO8/ZWTHzQ../84+%282%29.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/3skstfV-QhR1j4HLrk9w5StVFRE/inkzCQ../84.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Each exclusive frond within Palm Jebel Ali Villas forms its own meticulously planned private neighborhood, creating a sense of exclusivity and serenity that is second to none. As you explore these elegant streets, you'll be greeted by varying shades of green, where lush, layered flora effortlessly merges with carefully curated foliage, crafting a harmonious residential landscape that unfolds directly along the pristine beachfront.",
          "The heart of the frond community is a dedication to an active lifestyle, evident in the wide, walkable streets that offer a seamless connection for pedestrians and bikers to access the sun-kissed beaches through enchanting pocketed parks. Here, the streetscape is an oasis of green, with an intensely lush and fragrant landscape providing ample shade coverage. This verdant paradise is further enhanced by designated tree corridors alternating with gentle street lighting and an additional layer of planting buffer, creating a truly idyllic environment that invites residents to relax and unwind.",
          "Palm Jebel Ali Villas by Nakheel embody exceptional design principles and are set against the backdrop of a breathtaking beachfront location. Within this exclusive, private neighborhood, you will find a selection of Beach Villas, Coral Villas, and Premium Villa Plots, each designed to cater to the most discerning tastes.",
          "Beach Villas: Offering 5-6 bedroom villas with eight unique designs, including the Blue Horizon, Provence, Indigo Ocean, Sapphire, Acquamarina, Tropical Mist, Azure Blue, and Mediterranean, these homes combine contemporary elegance with beachfront living.",
          "Coral Villas: Featuring 7-bedroom villas with eight distinctive designs, such as Hibiscus, Coral Living, Red Aurora, Porcelain Roses, Cranberry Sky, Terracotta, Redwood, and Ruby Sunset, Coral Villas offer an unparalleled sense of luxury and spaciousness.",
          "Palm Jebel Ali Villas by Nakheel promise a lifestyle that merges natural beauty, thoughtful design, and the tranquility of an exclusive beachside community. Here, you'll discover the perfect blend of elegance and coastal living, making it the ideal place to call home.",
        ],
        finishingMaterials: "Bespoke finishing with high-quality materials.",
        kitchenAppliances:
          "Precious natural materials embellish the interiors of the show kitchen with appliances, whilst the extensive floor-to-ceiling glazing brings the outdoors inside so that every moment is enriched with natural sunlight.",
        furnishing: "No",
        locationBenefits: [
          "Palm Jebel Ali is a stunning man-made island located off the coast of Dubai, United Arab Emirates. Resembling a palm tree when viewed from above, this ambitious project is a testament to Dubai's innovation and engineering prowess.",
          "The development on Palm Jebel Ali is set to include a wide range of residential, commercial, and leisure facilities. It is designed to be a self-sustaining community, with infrastructure that includes roads, bridges, and utilities. The island is planned to be connected to the mainland of Dubai via a bridge and accessible through a monorail system.",
          "Palm Jebel Ali is a testament to Dubai's dedication to creating unique and luxurious living environments. With its stunning location and ambitious vision, it promises to offer a lifestyle that blends natural beauty, modern amenities, and the allure of coastal living. As it nears completion, it represents an exciting chapter in Dubai's ongoing development and innovation.",
        ],
      },
      pt: {
        generalFacts: [
          "Cada exclusivo fronde dentro de Palm Jebel Ali Villas forma seu próprio bairro privado meticulosamente planejado, criando uma sensação de exclusividade e serenidade incomparáveis. Ao explorar essas elegantes ruas, você será recebido por vários tons de verde, onde flora exuberante e em camadas se funde com folhagem cuidadosamente planejada, criando uma paisagem residencial harmoniosa que se desenrola diretamente ao longo da praia intocada.",
          "O coração da comunidade é dedicado a um estilo de vida ativo, evidente nas ruas largas e acessíveis para pedestres e ciclistas, que levam às praias ensolaradas por meio de parques encantadores. O cenário urbano é um oásis de verde, com paisagens intensamente exuberantes e perfumadas que oferecem ampla cobertura de sombra. Este paraíso verdejante é ainda aprimorado por corredores de árvores designados que alternam com iluminação suave e uma camada adicional de buffer vegetal, criando um ambiente verdadeiramente idílico que convida os moradores a relaxar.",
          "Palm Jebel Ali Villas by Nakheel incorporam princípios excepcionais de design e estão situadas em um deslumbrante local à beira-mar. Dentro deste exclusivo bairro privado, você encontrará uma seleção de Beach Villas, Coral Villas e Premium Villa Plots, cada uma projetada para atender aos gostos mais exigentes.",
          "Beach Villas: Oferecendo vilas de 5-6 quartos com oito designs únicos, incluindo Blue Horizon, Provence, Indigo Ocean, Sapphire, Acquamarina, Tropical Mist, Azure Blue e Mediterranean, essas casas combinam elegância contemporânea com vida à beira-mar.",
          "Coral Villas: Com vilas de 7 quartos e oito designs distintos, como Hibiscus, Coral Living, Red Aurora, Porcelain Roses, Cranberry Sky, Terracotta, Redwood e Ruby Sunset, as Coral Villas oferecem uma sensação inigualável de luxo e amplitude.",
          "Palm Jebel Ali Villas by Nakheel prometem um estilo de vida que mescla beleza natural, design cuidadoso e a tranquilidade de uma comunidade exclusiva à beira-mar. Aqui, você descobrirá a combinação perfeita de elegância e vida costeira, tornando este o lugar ideal para chamar de lar.",
        ],
        finishingMaterials:
          "Acabamento personalizado com materiais de alta qualidade.",
        kitchenAppliances:
          "Materiais naturais preciosos adornam os interiores da cozinha modelo com eletrodomésticos, enquanto as extensas janelas do chão ao teto trazem o exterior para dentro, enriquecendo cada momento com luz natural.",
        furnishing: "Não",
        locationBenefits: [
          "Palm Jebel Ali é uma impressionante ilha artificial localizada ao largo da costa de Dubai, Emirados Árabes Unidos. Lembrando uma palmeira vista de cima, este ambicioso projeto é um testemunho da inovação e capacidade de engenharia de Dubai.",
          "O desenvolvimento em Palm Jebel Ali incluirá uma ampla gama de instalações residenciais, comerciais e de lazer. Ele foi projetado para ser uma comunidade autossustentável, com infraestrutura que inclui estradas, pontes e serviços públicos. A ilha está planejada para ser conectada ao continente de Dubai por uma ponte e acessível por meio de um sistema de monotrilho.",
          "Palm Jebel Ali é um testemunho do compromisso de Dubai em criar ambientes de vida únicos e luxuosos. Com sua localização deslumbrante e visão ambiciosa, ele promete oferecer um estilo de vida que combina beleza natural, comodidades modernas e o encanto da vida costeira. À medida que se aproxima da conclusão, representa um capítulo emocionante no desenvolvimento e inovação contínuos de Dubai.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/Pn689hdM9o2r0QJVdQfkiNRRWr8/KpU3FQ../masterrr.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Private Pools",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/wMYE0xEAC5lg7vDEoOh1t2vQRmw/oS6IIw../61.jpg",
      },
      {
        name: "Private Beach",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Wn4mgRI1aYcExaBvGhciHmP6z5k/__toRg../59.jpg",
      },
    ],

    units: [
      {
        type: "Plot",
        bedrooms: null,
        priceRange: "AED 40,428,800 — AED 43,068,800",
        priceRangeUSD: "$11,006,700 — $11,725,500",
        size: "13,993 sqft",
        sizeSqm: "1,300 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Tp4wRVQ-XncPp9yOUeB-oEsZnfE/oUgYDA../Plot.png",
      },
      {
        type: "Villa",
        bedrooms: 5,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/pIEw92AtTFSG86iCIj8MAG9oanw/v602aQ../5.png",
      },
      {
        type: "Villa",
        bedrooms: 6,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Jj-fGm3eqkfHIgyLfsJEhrh4U9s/TPFJCw../6.png",
      },
      {
        type: "Villa",
        bedrooms: 7,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/CDiuR7hzLbcA1l-HV-CyYbUEJoA/xHpVGg../7.png",
      },
    ],
    developerDetails: {
      name: "Nakheel",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/3Pp7XAnJ2wEF7tvGMtUTkwoc9MU/GuHcCQ../1664000554247.jpeg",
      description:
        "Dubai-based Nakheel is a world-leading master developer whose innovative, landmark projects form an iconic portfolio of master communities and residential, retail, hospitality and leisure developments that are pivotal to realising Dubai’s vision. Nakheel’s waterfront projects, including the world-famous, award-winning Palm Jumeirah, have added more than 300 kilometres to Dubai’s original, 70km coastline, paving the way for the development of hundreds of seafront homes, resorts, hotels and attractions.",
      website: "https://www.nakheel.com/",
      email: "info@nakheel.com",
      address: "Nakheel Mall, Palm Jumeirah, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 5 PM",
        saturday: "Closed",
        sunday: "Closed",
      },
    },
    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 15,
    title: "Vela Viento",
    developer: "Omniyat",
    location: "Business Bay",
    city: "Dubai",
    completionDate: "Q1 2027",
    lastUpdated: "Nov 13, 2024",
    price: "$4,904,632",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/H7KsvRV5GkS3YYJxeJ7-cihhiE4/BZEdIA../4.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    //recommendationText: "Recommended by ambassadors",
    recommended: false,
    videoUrl: "https://youtube.com/embed/_kTZy8fagdM",
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=place_id:ChIJTcIZKaDo5ocR9V-L-4wHJFQ&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/YeqijxxKzCsVSEptgFbOiRNHPZU/Xkmd-A../1649070458016.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "Creative Kids Early Learning Centre", distance: "1.2 km" },
      { name: "Chinatown Dubai Mall", distance: "1.4 km" },
      { name: "Downtown Dubai", distance: "1.6 km" },
      { name: "Burj Khalifa Park by Emaar", distance: "2.3 km" },
      { name: "Mercato Beach", distance: "6 km" },
      { name: "Dubai International Airport", distance: "16.1 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/zcFeYWMQD7QuUWO-DDeMDX9Of5s/3UPkAw../4.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/j7EGUA65HLuOFF6cmiMqlNDnmOg/PcnOSw../6.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/Hgrz_7x5GvgICBdQMoPpgF3IL5A/0hXQgA../9.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/DETPj0z8cF2-hGsg03YwuJuZoq8/VxrfuA../15.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/09nMSnuGM9-vuD7d7qEXv0DjK-k/fKTK4Q../18.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/rW4rnHq7p-VRU1rZy1gjbEBDI7s/urOx9g../27.png",
          "https://api.reelly.io/vault/ZZLvFZFt/LoklMuyYtJUWtklNtc2-sLdZMNk/Y_U_xg../35.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/WLIfJ13_1qXGXZ75AJjJNESSq20/r3ELZw../37.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/5u9zUtYkaR7CDIA2BWI7vrJZWhY/7T6dVw../41.png",

          "https://api.reelly.io/vault/ZZLvFZFt/S7gFpVxcy9nt3jAgrgolrTVfI1c/FIRbKg../WhatsApp+Image+2023-11-21+at+08.06.31.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/pxM8cQqncx5Ls2I5Cl7pOgFN9fI/zMDn8w../WhatsApp+Image+2023-11-21+at+08.06.32+%282%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/V3RaYq492Tc938jXGV-IbhNbyy8/n4SGLg../WhatsApp+Image+2023-11-21+at+08.06.33+%281%29.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/DY5-cMjGzm8eLpxn9QFKNxMseuU/7EplGw../WhatsApp+Image+2023-11-21+at+08.06.33.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/b6Pye_712_yr4w0aEm0Rt3yV2OI/Jv4xFg../WhatsApp+Image+2023-11-21+at+08.06.34.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/yH5nIIDT54RZP2acrLyN3VMAKG8/t8r8TQ../WhatsApp+Image+2023-11-21+at+08.06.36+%281%29.jpeg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Continuing OMNIYAT’s commitment to imagining the extraordinary and building it into reality, VELA VIENTO was conceived by the finest designers who push boundaries to create global masterpieces. Award-winning practice Foster + Partners crafted VELA VIENTO’s horizontal lines and smooth curves into a fascinating rhythm that dances across OMNIYAT’s properties, from VELA VIENTO and VELA to The Lana and beyond. Parisian designer duo Gilles & Boissier have once again brought their signature style to an OMNIYAT property in an exquisite illustration of luxury interior design.",
          "The 92 residences range from two to four-bedroom apartments and are crowned by two extraordinary penthouses. The residences exemplify the delights of indoor-outdoor living, with select residences boasting private terrace pools and double-height spaces.",
          "Generous proportions create an ambiance of vastness, ideal for relaxation, entertainment, and savoring the panoramic views of Marasi Bay, Burj Khalifa, and Downtown Dubai. The spaces and bridges between the towers form ‘hanging’ dining rooms - an outstanding feature of three unique residences that turn a daily experience into a transcendent moment that blurs glass with sky, the earthly with the celestial.",
          "VELA VIENTO redefines the essence of luxury living with ethereal spaces that enhance self-care and well-being. Touching the sky at 100 meters high at over half of the height of the building, the ‘Sky Amenities Deck’ is an architectural and experiential feature with a spectacular infinity pool, double-height gym, yoga studio, and lounge areas, all overlooking the stunning vistas that surround.",
        ],
        finishingMaterials:
          "Distilling the essence of elegance and singularity into an art form, Gilles & Boissier seamlessly blend modernity with timeless allure in VELA VIENTO’s captivating spaces. The result is a harmonious juxtaposition of contemporary elements and classical features in a design concept that transcends trends and embodies everlasting beauty.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "Fully furnished and designed by Gilles & Boissier.",
        locationBenefits: [
          "Business Bay, Dubai, is not just office spaces and buildings. It also features hotel towers, amazing restaurants, and wonderful residential properties. The area is known for its stylish, up-to-date community with a fast-paced lifestyle.",
          "Business Bay is on the south side of Downtown Dubai and often hosts various lively events, such as music festivals, Mother’s Day celebrations, and New Year’s celebrations.",
        ],
      },
      pt: {
        generalFacts: [
          "Dando continuidade ao compromisso da OMNIYAT de imaginar o extraordinário e transformá-lo em realidade, o VELA VIENTO foi concebido pelos melhores designers, que ultrapassam limites para criar obras-primas globais. O renomado estúdio Foster + Partners projetou as linhas horizontais e curvas suaves do VELA VIENTO, criando um ritmo fascinante que permeia os empreendimentos da OMNIYAT, de VELA VIENTO e VELA a The Lana e além. A dupla de designers parisienses Gilles & Boissier trouxe novamente seu estilo característico para uma propriedade da OMNIYAT, ilustrando de forma requintada o design de interiores de luxo.",
          "As 92 residências variam de apartamentos de dois a quatro quartos e são coroadas por dois extraordinários penthouses. As residências exemplificam os encantos da vida interna e externa, com algumas oferecendo piscinas privativas em terraços e espaços com pé direito duplo.",
          "Proporções generosas criam uma atmosfera de amplitude, ideal para relaxamento, entretenimento e para apreciar as vistas panorâmicas da Marasi Bay, Burj Khalifa e Downtown Dubai. Os espaços e pontes entre as torres formam 'salas de jantar suspensas' - uma característica excepcional de três residências únicas, transformando experiências diárias em momentos transcendentes que fundem vidro e céu, o terreno e o celestial.",
          "VELA VIENTO redefine a essência da vida de luxo com espaços etéreos que promovem o cuidado pessoal e o bem-estar. A 100 metros de altura, acima de metade do edifício, o 'Sky Amenities Deck' é uma característica arquitetônica e experiencial com uma espetacular piscina infinita, academia com pé direito duplo, estúdio de ioga e áreas de lounge, todas com vistas deslumbrantes dos arredores.",
        ],
        finishingMaterials:
          "Destilando a essência da elegância e singularidade em uma forma de arte, Gilles & Boissier combinam perfeitamente modernidade e charme atemporal nos espaços cativantes do VELA VIENTO. O resultado é uma justaposição harmoniosa de elementos contemporâneos e características clássicas, criando um conceito de design que transcende tendências e incorpora beleza eterna.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing: "Totalmente mobiliado e projetado por Gilles & Boissier.",
        locationBenefits: [
          "Business Bay, em Dubai, não é apenas um local de escritórios e prédios comerciais. O bairro também oferece hotéis, restaurantes incríveis e propriedades residenciais maravilhosas. É conhecido por sua comunidade estilosa e atualizada, com um estilo de vida dinâmico.",
          "Business Bay está localizado ao sul de Downtown Dubai e frequentemente recebe vários eventos animados, como festivais de música, comemorações do Dia das Mães e celebrações de Ano Novo.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/jEDLqUVqC1y3fyrQRXvuLmGoA5M/DvdLzA../Group+371079+%281%29.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Stunning infinity-edge pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/mSL9I4gPKzFYXYbqVWfo_DNDLCY/SvTnxw../49.jpg",
      },
      {
        name: "Double height gym",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/etWAhruLdlWkKs-5WDD28f8MI3Y/PFUdWQ../56.jpg",
      },
      {
        name: "Dedicated yoga studio",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/uC5CKJtO4XfbpLSl2eYPwPtoRnk/u2OI5g../9k.jpg",
      },
      {
        name: "Indoor lap pool & children’s pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/uoCuWYkjppnDH-ViDFPyMa9CW_o/CDkNyg../Swimming-tips_MobileHomeFeature.jpg",
      },
      {
        name: "Private spa",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/DMMchWbusZjI8JyLK2AGvPbvuLw/1FTiDQ../attractive-african-woman-enjoyin.jpg",
      },
      {
        name: "Meeting rooms",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Ve3KI8ULyfNEtoWtDUhavajvN20/owh5QA../%D0%91%D0%B5%D0%B7+%D0%BD%D0%B0%D0%B7%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.jpeg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 2,
        priceRangeAED: "AED 18,000,000 — AED 0",
        priceRangeUSD: "$4,900,500 — $0",
        sizeSqft: "2791 sqft — 5070 sqft",
        sizeSqm: "259.3 m² — 471 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/7OVrsPVV9smu4lQsXZeT3ZAFEVc/r2wFtQ../2.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRangeAED: "AED 22,400,000 — AED 0",
        priceRangeUSD: "$6,098,400 — $0",
        sizeSqft: "3995 sqft — 5511 sqft",
        sizeSqm: "371.1 m² — 512 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/j1V0bKTVOC73vw_2HvqMUP401EY/A0ImJA../3.png",
      },
      {
        type: "Duplex",
        bedrooms: 4,
        priceRangeAED: "Price on request",
        priceRangeUSD: "Price on request",
        sizeSqft: "6090 sqft — 8387 sqft",
        sizeSqm: "565.8 m² — 779.2 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/WX-p1RA3lTw21_39G3cbZ7fwRu0/HvPQxA../4dup.png",
      },
      {
        type: "Penthouse",
        bedrooms: 4,
        priceRangeAED: "Price on request",
        priceRangeUSD: "Price on request",
        sizeSqft: "13822 sqft — 15952 sqft",
        sizeSqm: "1,284.1 m² — 1,482 m²",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/viyZfm0yV-fMaWtwa06v6bvtFME/Lv_VPQ../28.png",
      },
      {
        type: "Sky Villa",
        bedrooms: 4,
        priceRangeAED: "Sold out",
        priceRangeUSD: "Sold out",
        sizeSqft: "10032 sqft — ",
        sizeSqm: "932 m² — 0",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/d_VraAxlPfNVMdUJBXfeBOk1uQQ/IGC5pg../%D1%81%D0%BA%D0%B0%D0%B8%CC%86.png",
      },
    ],
    developerDetails: {
      name: "Omniyat",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/YeqijxxKzCsVSEptgFbOiRNHPZU/Xkmd-A../1649070458016.jpeg",
      description:
        "Omniyat, founded in 2005, is a visionary Dubai-based developer known for redefining luxury through architecture and design. Driven by a concept they call 'The Art of Elevation,' Omniyat creates immersive living experiences that combine creativity, quality, and rarity. Each project is a curated work of art, merging purpose, design, and innovation to push the boundaries of luxury living. Collaborating with industry icons like Zaha Hadid and Norman Foster, as well as partnering with hospitality leaders such as the Dorchester Collection, Omniyat has set new standards for excellence both in the UAE and globally. This commitment to exceptionalism has established Omniyat as a key player in shaping Dubai’s reputation as a destination for the world’s most discerning residents and investors.",
      website: "https://www.omniyat.com/",
      email: "",
      address:
        "OMNIYAT Sales Gallery, The Opus by OMNIYAT, Designed by Zaha Hadid, Business Bay Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6:30 PM",
        saturday: "Day off",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 16,
    title: "Beach Residences at Siniya Island",
    developer: "Sobha",
    location: "Umm Al Quwain",
    city: "Umm Al Quwain",
    completionDate: "Q4 2027",
    lastUpdated: "Nov 12, 2024",
    price: "$301,544",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/kW4Gm7jhZiNA6wSP8X9I8gm5Y_U/MKnMgQ../photo_2024-09-10_03-34-42.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    //recommendationText: "Recommended by ambassadors",
    recommended: false,
    videoUrl: "https://www.youtube.com/embed/WDiZAhc4apg", //PlaceHolder Video
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=place_id:ChIJ0URVbSL99T4R9YJ8uocEfzg&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "First Avenue Mall", distance: "0.3 km" },
      { name: "Jebel Ali Village Early Childhood Centre", distance: "1.1 km" },
      { name: "Eastland Park", distance: "1.9 km" },
      { name: "Sufouh Beach", distance: "13.4 km" },
      { name: "Downtown Dubai", distance: "21.1 km" },
      { name: "Al Maktoum International Airport", distance: "31.4 km" },
    ],

    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/NXDIW2oAiGTPdnzjyIVrgRo5l_o/T65VNA../0.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/R4z3j4nwdrqVJPFJEtQwV5j8YUU/G0DCGA../1.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/w04GddxW_mDaLcqygPGl_jfCVkw/QhSuNQ../9.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/LfwrficaV8NF-SdxilTgbUOEmU4/iharDQ../8.png",
          "https://api.reelly.io/vault/ZZLvFZFt/ZtMlomuQmh0z7hXtBFHUXENh4Mo/sZF6HA../18.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/NWTSuaF-0l00gbZovZDzHjOFufg/04ClxA../20.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/m4inmq4czvzFzh6-IpjjuW5o8QM/wHMUQQ../29.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/vTVSl8nZPdKD4f_P3ENKgK0tQKs/4oltEg../Bathroom.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Beach Residences at Siniya Island is a premium waterfront project by Sobha Realty, featuring three luxurious residential complexes: Delphine, Selene, and Coraline. The development consists of a total of four towers, including two 7-story Towers A and C, one 9-story Tower B, and one intimate 2-story Tower D. Located on the scenic Siniyah Island in Umm Al Quwain, the project offers tranquil coastal living, where modern architectural aesthetics blend harmoniously with the island’s natural beauty. The residences across all three complexes boast elegant interiors and contemporary designs, providing a luxurious and serene lifestyle.",
          "Residents of Beach Residences enjoy an array of top-tier amenities, including infinity swimming pools, state-of-the-art fitness centers, and beautifully landscaped parks. Each complex offers access to exclusive recreational facilities like jogging and cycling trails, water sports areas, and children's play zones, ensuring a well-rounded and active lifestyle. The natural beauty of the island, with its pristine beaches and lush mangroves, creates an idyllic backdrop for relaxation and outdoor pursuits.",
          "This development is part of the larger Sobha Siniya Island project, which is designed with eco-friendly principles and high-quality construction. The strategic location of Siniyah Island, with excellent transport links to Dubai and Sharjah, provides residents with the perfect balance of serene island life and urban convenience. Whether enjoying breathtaking views of the sea or exploring the island's vibrant community spaces, Beach Residences offers a unique and rewarding living experience.",
        ],
        finishingMaterials: "Modern finishing with high-quality materials.",
        kitchenAppliances: "Equipped kitchen.",
        furnishing: "Only the C towers in each complex are fully furnished.",
        locationBenefits: [
          "Siniyah Island, located in Umm Al Quwain, is a serene and picturesque destination renowned for its natural beauty and tranquil environment. This island offers a pristine escape from the hustle and bustle of city life, characterized by its lush landscapes, sparkling waters, and abundant wildlife. Its unspoiled beaches and gentle coastal breezes make it a perfect retreat for those seeking relaxation and a connection with nature.",
          "The island's strategic location provides easy access to major urban centers while maintaining a sense of seclusion. With its proximity to Dubai and Sharjah, Siniyah Island combines the benefits of a peaceful, secluded environment with the convenience of urban amenities. This makes it an ideal spot for both residents and visitors who wish to enjoy a balance of tranquility and accessibility.",
          "Siniyah Island is also known for its commitment to sustainable development and environmental preservation. The island features eco-friendly infrastructure and carefully planned urban spaces that respect the natural landscape. This dedication to sustainability ensures that Siniyah Island remains a beautiful and welcoming destination for years to come.",
        ],
      },
      pt: {
        generalFacts: [
          "Beach Residences em Siniya Island é um projeto de frente para o mar de luxo da Sobha Realty, composto por três complexos residenciais exclusivos: Delphine, Selene e Coraline. O empreendimento inclui um total de quatro torres, com duas torres de 7 andares (Torres A e C), uma torre de 9 andares (Torre B) e uma torre íntima de 2 andares (Torre D). Localizado na pitoresca Siniyah Island em Umm Al Quwain, o projeto oferece uma vida costeira tranquila, onde a estética arquitetônica moderna se mistura harmoniosamente com a beleza natural da ilha. As residências em todos os três complexos apresentam interiores elegantes e designs contemporâneos, proporcionando um estilo de vida luxuoso e sereno.",
          "Os residentes do Beach Residences desfrutam de uma série de comodidades de alto nível, incluindo piscinas infinitas, academias modernas e parques paisagísticos deslumbrantes. Cada complexo oferece acesso a instalações recreativas exclusivas, como trilhas para corrida e ciclismo, áreas para esportes aquáticos e zonas de lazer para crianças, garantindo um estilo de vida ativo e equilibrado. A beleza natural da ilha, com suas praias intocadas e manguezais exuberantes, cria um cenário idílico para relaxamento e atividades ao ar livre.",
          "Este desenvolvimento faz parte do maior projeto Sobha Siniya Island, que foi projetado com princípios ecológicos e construção de alta qualidade. A localização estratégica de Siniyah Island, com excelentes conexões de transporte para Dubai e Sharjah, oferece aos residentes o equilíbrio perfeito entre a vida tranquila da ilha e a conveniência urbana. Seja desfrutando de vistas deslumbrantes do mar ou explorando os vibrantes espaços comunitários da ilha, Beach Residences oferece uma experiência de vida única e gratificante.",
        ],
        finishingMaterials:
          "Acabamento moderno com materiais de alta qualidade.",
        kitchenAppliances: "Cozinha equipada.",
        furnishing:
          "Somente as torres C em cada complexo estão totalmente mobiliadas.",
        locationBenefits: [
          "Siniyah Island, localizada em Umm Al Quwain, é um destino sereno e pitoresco, conhecido por sua beleza natural e ambiente tranquilo. Esta ilha oferece uma fuga intocada do agito da vida urbana, caracterizada por suas paisagens exuberantes, águas cristalinas e abundante vida selvagem. Suas praias intocadas e brisas costeiras suaves fazem dela um refúgio perfeito para aqueles que buscam relaxamento e conexão com a natureza.",
          "A localização estratégica da ilha oferece fácil acesso aos principais centros urbanos, mantendo uma sensação de isolamento. Com sua proximidade a Dubai e Sharjah, Siniyah Island combina os benefícios de um ambiente pacífico e isolado com a conveniência de comodidades urbanas. Isso a torna um local ideal tanto para residentes quanto para visitantes que desejam desfrutar de um equilíbrio entre tranquilidade e acessibilidade.",
          "Siniyah Island também é conhecida por seu compromisso com o desenvolvimento sustentável e a preservação ambiental. A ilha possui infraestrutura ecológica e espaços urbanos cuidadosamente planejados que respeitam a paisagem natural. Esse compromisso com a sustentabilidade garante que Siniyah Island permaneça um destino bonito e acolhedor por muitos anos.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/jEDLqUVqC1y3fyrQRXvuLmGoA5M/DvdLzA../Group+371079+%281%29.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Adventure Water Sports",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/DpLAO87kCQ9AoJmDiQZ4TB8T8JQ/1pdVlQ../Adventure+Water+Sports.jpg",
      },
      {
        name: "Infinity Swimming Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/QpThrplwDpZHnbiq_niOP4UnVF8/1GGGPA../Infinity+Swimming+Pool.jpg",
      },
      {
        name: "Community Centre",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/gJvdFxAeV--U2wKvm6-Iv7wHbaw/xjHyMw../Communty+Centre.jpg",
      },
      {
        name: "State-Of-The-Art Fitness Centre",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/DVaMEeCMLVzQv_7qzP96NAmAHuk/5XZmBw../State-Of-The-Art+Fitness+Centre.jpg",
      },
      {
        name: "Children's Play Areas",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xq6Urj_NUfI2gStCevsoqKhrCkM/dh91HA../Children%27s+Play+Areas.jpg",
      },
      {
        name: "Landscaped Parks",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/W0-Gmu230Rb_xHWY26yAELXKPe8/chEJaw../img104.jpg",
      },
      {
        name: "Sky Gardens",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/vkIhBmxHT24DD_iRpsLNkwmsUqI/cyWl5w../Sky+Gardens.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 1,007,360 — AED 1,172,771",
        priceRangeUSD: "$274,300 — $319,300",
        size: "536 sqft — 576 sqft",
        sizeSqm: "49.8 sqm — 53.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/2ujHOoGTOmbaUfd17g0nk9uaL8o/ixKWOQ../1BR.png",
      },
      {
        type: "Apartments",
        bedrooms: 1.5,
        priceRange: "AED 1,227,072 — AED 1,358,369",
        priceRangeUSD: "$334,100 — $369,800",
        size: "637 sqft — 700 sqft",
        sizeSqm: "59.2 sqm — 65 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/mZFdGpq3eq1IjX_nEUUagJq_UJA/98Kg7Q../1.5br.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 2,080,140 — AED 2,312,409",
        priceRangeUSD: "$566,300 — $629,600",
        size: "1124 sqft — 1125 sqft",
        sizeSqm: "104.4 sqm — 104.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/KDWTz-ITLjNGxhxPkThq9chFDTc/NeuxRw../2BR.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 3,843,810 — AED 3,928,808",
        priceRangeUSD: "$1,046,500 — $1,069,600",
        size: "1889 sqft",
        sizeSqm: "175.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Jt5EwGXpVv4f69lNrsSa6omtqfA/AZiNEw../3BR.png",
      },
      {
        type: "Duplex",
        bedrooms: 2,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/eEYegVsqS_aSujy5NsSlQct2eJI/U6yhPg../2d.png",
      },
      {
        type: "Duplex",
        bedrooms: 3,
        priceRange: "Sold out",
        priceRangeUSD: "Sold out",
        size: "N/A",
        sizeSqm: "N/A",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/j2-0cnCBUejTZ3E51SnxuTqvoDI/xzKxeQ../3d.png",
      },
    ],
    developerDetails: {
      name: "Sobha",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
      description:
        "Sobha is an international luxury real estate developer committed to redefining the art of living by building sustainable communities. Established in 1976 as an interior decoration firm in Oman by the iconic PNC Menon — a visionary leader in the space, we have grown our presence world-over with developments and investments in the UAE, Oman, Bahrain, Brunei, and India.",
      website: "https://www.sobharealty.com/",
      email: "hello@sobharealty.com",
      address:
        "Sobha Sapphire Building, Al Khaleej Al Tejari 2 Street, Business Bay, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        saturday: "10 AM - 5 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 17,
    title: "Sobha Solis",
    developer: "Sobha",
    location: "Motor City",
    city: "Dubai",
    completionDate: "DEC 2027",
    lastUpdated: "Nov 12, 2024",
    price: "$274,485",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/rC9gbTgGtB7bPqldnzbyT0UiiRA/Lw-Gew../0.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    recommendationText: "Recommended",
    recommended: true,
    videoUrl: "https://www.youtube.com/embed/WDiZAhc4apg", //PlaceHolder Video
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=25.049377041759, 55.24226491219041&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "First Avenue Mall", distance: "0.3 km" },
      { name: "Jebel Ali Village Early Childhood Centre", distance: "1.1 km" },
      { name: "Eastland Park", distance: "1.9 km" },
      { name: "Sufouh Beach", distance: "13.4 km" },
      { name: "Downtown Dubai", distance: "21.1 km" },
      { name: "Al Maktoum International Airport", distance: "31.4 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/NXDIW2oAiGTPdnzjyIVrgRo5l_o/T65VNA../0.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/R4z3j4nwdrqVJPFJEtQwV5j8YUU/G0DCGA../1.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/w04GddxW_mDaLcqygPGl_jfCVkw/QhSuNQ../9.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/LfwrficaV8NF-SdxilTgbUOEmU4/iharDQ../8.png",
          "https://api.reelly.io/vault/ZZLvFZFt/ZtMlomuQmh0z7hXtBFHUXENh4Mo/sZF6HA../18.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/NWTSuaF-0l00gbZovZDzHjOFufg/04ClxA../20.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/m4inmq4czvzFzh6-IpjjuW5o8QM/wHMUQQ../29.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/vTVSl8nZPdKD4f_P3ENKgK0tQKs/4oltEg../Bathroom.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Ignite your curiosity and transform the ordinary into the extraordinary, the mundane into the magical. Immerse yourself in a community that fuels your passions and energizes your spirit with the exhilarating vibrancy of life. Spanning across four unique towers, Sobha Solis delivers the ideal harmony of dynamic activity and serene relaxation. This urban sanctuary features a collection of 1, 1.5, 2 and 3-bedroom residences spread across 4 distinct towers all set upon a shared podium exclusive to residents. Each apartment is designed to offer spacious layouts complemented by large windows and balconies that invite ample natural light and stunning views.",
          "Sobha Solis is a community that fuels your passions and invigorates you with the high-octane vibrancy of life. Enjoy a breath of fresh air at a dynamic outdoor sports court and jogging tracks. The community hosts a range of adrenaline-pumping activities such as outdoor rock-climbing walls, a parkour park, and a CrossFit and calisthenics zone in the indoor gym. Sobha Solis is designed for residents to experience the thrill of adventure and connect with like-minded neighbors in vibrant shared spaces, including outdoor cinemas, co-working stations, and dog parks.",
          "Book lovers can lose themselves in a curated library, while nature enthusiasts can stroll through landscaped zen gardens and jogging paths. Restore balance at a Yoga and Meditation Zone or take a refreshing dip in the leisure pool. Experience a world-class clubhouse with fine dining, social spaces, and breathtaking views of Dubai's skyline, Dubai Miracle Garden, and the Dubai Autodrome.",
        ],
        finishingMaterials: "Modern finishing with high-quality materials.",
        kitchenAppliances: "Fitted kitchen and bathroom.",
        furnishing: "No.",
        locationBenefits: [
          "Motor City in Dubai is a unique and lively community that combines urban living with a captivating theme centered around motorsports. This modern neighborhood features thoughtfully designed architecture, road names reminiscent of race tracks, and a shared love for cars and racing.",
          "Residents enjoy green spaces with manicured parks, jogging paths, and peaceful gardens, providing a retreat from the excitement of urban living. Amenities like fitness centers, pools, and sports courts encourage active lifestyles and community engagement.",
          "Strategically located in the heart of Dubai, Motor City offers easy access to shopping, dining, and entertainment, making it an ideal destination for families, professionals, and motorsport enthusiasts.",
        ],
      },
      pt: {
        generalFacts: [
          "Desperte sua curiosidade e transforme o comum no extraordinário, o rotineiro no mágico. Mergulhe em uma comunidade que alimenta suas paixões e energiza seu espírito com a vibrante energia da vida. Composta por quatro torres exclusivas, Sobha Solis oferece a harmonia ideal entre atividade dinâmica e relaxamento sereno. Este santuário urbano apresenta residências de 1, 1,5, 2 e 3 quartos em 4 torres distintas, todas situadas sobre um pódio compartilhado exclusivo para os residentes. Cada apartamento foi projetado para oferecer layouts espaçosos, com grandes janelas e varandas que proporcionam luz natural e vistas deslumbrantes.",
          "Sobha Solis é uma comunidade que inspira suas paixões e te revigora com a alta energia da vida. Desfrute de ar fresco nas dinâmicas quadras esportivas ao ar livre e pistas de corrida. A comunidade oferece uma variedade de atividades emocionantes, como paredes de escalada, um parque de parkour, e zonas de CrossFit e calistenia no ginásio interno. Sobha Solis é projetado para que os residentes vivam aventuras e se conectem com vizinhos em espaços compartilhados vibrantes, incluindo cinemas ao ar livre, estações de trabalho e parques para cães.",
          "Amantes de livros podem se perder em uma biblioteca bem-curada, enquanto os apaixonados pela natureza podem passear por jardins zen e trilhas para corrida. Restaure o equilíbrio na Zona de Ioga e Meditação ou mergulhe na piscina de lazer. Viva experiências culinárias refinadas em um clube exclusivo com vistas panorâmicas do horizonte icônico de Dubai, Dubai Miracle Garden e o Autódromo de Dubai.",
        ],
        finishingMaterials:
          "Acabamento moderno com materiais de alta qualidade.",
        kitchenAppliances: "Cozinha e banheiro equipados.",
        furnishing: "Não.",
        locationBenefits: [
          "Motor City em Dubai é uma comunidade única e animada que combina a vida urbana com uma temática envolvente centrada no automobilismo. Este bairro moderno apresenta uma arquitetura cuidadosamente planejada, nomes de ruas inspirados em pistas de corrida e uma paixão compartilhada por carros e corridas.",
          "Os moradores desfrutam de espaços verdes com parques bem cuidados, trilhas para corrida e jardins tranquilos, proporcionando um refúgio do ritmo acelerado da vida urbana. Comodidades como academias, piscinas e quadras esportivas incentivam estilos de vida ativos e engajamento comunitário.",
          "Localizado estrategicamente no coração de Dubai, Motor City oferece fácil acesso a compras, restaurantes e entretenimento, tornando-o um destino ideal para famílias, profissionais e entusiastas do automobilismo.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/7HqwOkIoxodIzXPSFgLnZTkY4FA/OJpWrw../Group+41.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Leisure Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/j6msrJpwNpa8jOToFD323R7g-Xo/UNRctA../28.jpg",
      },
      {
        name: "Lap Pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/IUQQ9Pe2Ed7pPTXZJFQKbCcxEFw/WWmd1g../15.jpg",
      },
      {
        name: "Gym",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/hQqKqBGgv7gD6eNRFC-lwuc17Jk/Y4eosw../17.png",
      },
      {
        name: "Zen Garden",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/AN5QSj-_cJ0wVsTrY8-B8comEcU/PazRxQ../19.jpg",
      },
      {
        name: "BBQ and Picnic Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/nEvfNYZ9I3lYBIXJ20sJs_Wf60A/dY-CUw../12.png",
      },
      {
        name: "Jogging Track",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/pUWpJ07VgNXIFQgRxrjA6zlmsdQ/i-nAVQ../Landscape.png",
      },
      {
        name: "Outdoor Cinema",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/6rgZ1HrKBDrUCkWRWbIp8XL_e5E/T0qrFA../Podium+-+Amenities.png",
      },
      {
        name: "Kids Play Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/CwHsyfRO8RpyG6VkGkimtrnXy-4/f9tTBQ../Podium+-+Kids+play+area.png",
      },
      {
        name: "Yoga and Meditation Zone",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/gBhz0yywz81whELGYjemmbtBmuQ/OoLxag../13.png",
      },
      {
        name: "Retail",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/sX8MkGbQVh3jqxo0uU4JTCfr3mw/Tj_Luw../16.jpg",
      },
      {
        name: "Clubhouse",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/jacRYQ5__BV6tycAJT0RHdxkLXs/EVIxNw../F%26B+by+Autodrome.jpg",
      },
      {
        name: "Lounge",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/WXfrkvTrYKSOPMvO49KtoTxsnFU/XFPFJA../27.jpg",
      },
      {
        name: "Rock-Climbing Walls",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/BIEqosR4bXthTfgcAOZsE86R75Q/enUgOg../10.png",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 1,007,360 — AED 1,172,771",
        priceRangeUSD: "$274,300 — $319,300",
        size: "536 sqft — 576 sqft",
        sizeSqm: "49.8 sqm — 53.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/DwsOhp9bHsBijTATakUjMmiNwcE/OHveug../1br.png",
      },
      {
        type: "Apartments",
        bedrooms: 1.5,
        priceRange: "AED 1,227,072 — AED 1,358,369",
        priceRangeUSD: "$334,100 — $369,800",
        size: "637 sqft — 700 sqft",
        sizeSqm: "59.2 sqm — 65 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/mZFdGpq3eq1IjX_nEUUagJq_UJA/98Kg7Q../1.5br.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 2,080,140 — AED 2,312,409",
        priceRangeUSD: "$566,300 — $629,600",
        size: "1124 sqft — 1125 sqft",
        sizeSqm: "104.4 sqm — 104.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/xSG9ALD4MXowAnSiDLDcPc0S3Iw/5u1BVQ../2br.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 3,843,810 — AED 3,928,808",
        priceRangeUSD: "$1,046,500 — $1,069,600",
        size: "1889 sqft",
        sizeSqm: "175.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/SEZxRJdjjktgctA2i25mkSeal0s/99nsuw../3br.png",
      },
    ],
    developerDetails: {
      name: "Sobha",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
      description:
        "Sobha is an international luxury real estate developer committed to redefining the art of living by building sustainable communities. Established in 1976 as an interior decoration firm in Oman by the iconic PNC Menon — a visionary leader in the space, we have grown our presence world-over with developments and investments in the UAE, Oman, Bahrain, Brunei, and India.",
      website: "https://www.sobharealty.com/",
      email: "hello@sobharealty.com",
      address:
        "Sobha Sapphire Building, Al Khaleej Al Tejari 2 Street, Business Bay, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        saturday: "10 AM - 5 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 18,
    title: "320 Riverside Crescent",
    developer: "Sobha",
    location: "Sobha Hartland II",
    city: "Dubai",
    completionDate: "JUL 2027",
    lastUpdated: "Nov 12, 2024",
    price: "$425,255",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/ltB0PeYqF9paqGXLd4gTttDk-pU/1bO6CA../119.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    recommendationText: "Recommended",
    recommended: true,
    videoUrl: "https://youtube.com/embed/6UJ9SVu_sZA",
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=place_id:ChIJXwawGtdnXz4RXj2mbhkzyTw&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "Kinderville Ras Al Khor", distance: "5.5 km" },
      { name: "Nad Al Sheba Park", distance: "6.7 km" },
      { name: "Avenue Mall - Nad Al Shiba", distance: "8.1 km" },
      { name: "Downtown Dubai", distance: "8.7 km" },
      { name: "Mercato Beach", distance: "12.3 km" },
      { name: "Dubai International Airport", distance: "13.8 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/FxcGGRj2CItDhu0JP6PM3RalY4U/HTiqrg../119.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/s728LDWUITGJ6PhoUHs6M7ScYdo/rrWz-Q../120.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/iKXW3LsJ6Ggg_iKtbquK048Ttzg/-zyx9Q../121.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/trea2EsSkc3FfXLUZL3RTVh5fvg/oYB_vA../124.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/HwOhqxRyuKnnfLYCv7wj1j2Mno0/mBCwrg../125.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/izOIIoJBtO3mMxgi4WXzYb7-NIg/Ntkkzw../0.jpg",

          "https://api.reelly.io/vault/ZZLvFZFt/nGRyh0cokhk_21kVUlqCeX9xR-Q/Rkh-Rw../127.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/l2DSjeKGI3MRTEB3Iwh-0mNWkkc/1-Z8qQ../126.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Introducing 320 Riverside Crescent at Sobha Hartland II—a residential masterpiece that epitomizes sublime living at its finest. With its impressive 57 stories, this development sets the stage for a new realm of elegance and sophistication, where every moment is infused with a sense of enchantment and fulfillment.",
          "This community is more than just a collection of homes; it represents a way of life that harmoniously integrates the elements of water and people. The architectural design features captivating finite curves, while the vistas are boundless, captivating the imagination and inspiring a profound connection with nature. Residents are treated to panoramic views from every angle, with community views at the forefront, clear vistas of Dubai Al Ain Road on one side, and the shimmering lights of the city on the other. Open podiums and balconies provide a unique opportunity to embrace beachfront views, creating a tranquil ambiance.",
        ],
        finishingMaterials: "High-quality finishes.",
        kitchenAppliances: "Fully fitted kitchen.",
        furnishing: "No.",
        locationBenefits: [
          "Sobha Hartland II in MBR City, Dubai, features exclusive mansions in a luxurious community with 50% of its territory dedicated to parks and green walkways. Verdant parks, European-style boulevards, and proximity to the serene Ras Al Khor nature reserve, home to mangroves and exotic birds, offer residents a tranquil retreat.",
          "Indulge in water sports, swimming, and sunbathing on a well-equipped beach near two blue lagoons and a private promenade. Sobha Hartland II is situated in MBR City, surrounded by affluent districts like Business Bay, Downtown, and Creek Harbour.",
          "High demand and an impressive ROI potential of ~6.7% make this a premium investment, supported by on-site international schools, retail, dining, and freehold ownership for UAE residents and foreign citizens.",
        ],
      },
      pt: {
        generalFacts: [
          "Apresentamos o 320 Riverside Crescent no Sobha Hartland II—uma obra-prima residencial que personifica o viver sublime em seu melhor. Com impressionantes 57 andares, este desenvolvimento define um novo patamar de elegância e sofisticação, onde cada momento é impregnado de encanto e realização.",
          "Essa comunidade é mais do que uma coleção de casas; representa um estilo de vida que integra harmoniosamente os elementos da água e das pessoas. O design arquitetônico apresenta curvas finitas cativantes, enquanto as vistas são ilimitadas, capturando a imaginação e inspirando uma profunda conexão com a natureza. Os residentes desfrutam de vistas panorâmicas de todos os ângulos, com vistas da comunidade à frente, do Dubai Al Ain Road de um lado e das luzes cintilantes da cidade do outro. Pódios abertos e varandas oferecem uma oportunidade única de contemplar vistas à beira-mar, criando uma atmosfera tranquila.",
        ],
        finishingMaterials: "Acabamentos de alta qualidade.",
        kitchenAppliances: "Cozinhas totalmente equipadas.",
        furnishing: "Não.",
        locationBenefits: [
          "Sobha Hartland II em MBR City, Dubai, apresenta mansões exclusivas em uma comunidade luxuosa com 50% de seu território dedicado a parques e passarelas verdes. Parques exuberantes, bulevares de estilo europeu e proximidade ao sereno Ras Al Khor, lar de manguezais e pássaros exóticos, oferecem aos residentes um refúgio tranquilo.",
          "Desfrute de esportes aquáticos, natação e banhos de sol em uma praia bem equipada próxima a duas lagoas azuis e um calçadão privativo. Sobha Hartland II está localizado em MBR City, cercado por distritos afluentes como Business Bay, Downtown e Creek Harbour.",
          "Alta demanda e um impressionante potencial de ROI de ~6,7% tornam este um investimento premium, apoiado por escolas internacionais no local, varejo, restaurantes e propriedade livre para residentes dos Emirados Árabes Unidos e cidadãos estrangeiros.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/kHHldqT4B2mxB7ZqJ1x_L9UGC2w/qOefBw../320+Riverside+Crescent_page-0001+%281%29+%281%29.jpg",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Access to Crystall Lagoon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/EnGlSRGBG1tPYSl1ZMK3MU7GBkA/zXcriQ../123.jpg",
      },
      {
        name: "Swimming Pools",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ODMmbP2EgLnjN08kbk6LZkhOTQ4/u7spow../129.jpg",
      },
      {
        name: "Outdoor And Indoor Gyms",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/WtGBeYjIFHtqAtvI8bZXKBL4lww/jhjMNg../130.jpg",
      },
      {
        name: "F&B",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/LUxnT7R3407-twsv3AZTaCtpR-s/AZVCCw../131.jpg",
      },
      {
        name: "Kids Play Area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/gZq9rEKgMgAmbo9fjTfgJoibNoA/PVZsUw../132.jpg",
      },
      {
        name: "BBQ Areas",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/AwhE3VpgQ7VsH9qZsQNWtP-TBYI/PWrVnw../133.jpg",
      },
      {
        name: "Sport Courts",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/b32NQb1us0DCUWwr23eDmd2jcAs/s8Ki1g../134.jpg",
      },
      {
        name: "Retail",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ce4QPRZw1gb5NPqQF812C01bn4E/NrcpGQ../135.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 1.5,
        priceRange: "AED 1,560,688 — AED 1,878,243",
        priceRangeUSD: "$424,900 — $511,400",
        size: "678 sqft — 827 sqft",
        sizeSqm: "63 sqm — 76.8 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/sz6Qb9OMo4h1CqMNtEVBr9K4dgE/PxN2gQ../1.5.png",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 1,617,424 — AED 2,156,171",
        priceRangeUSD: "$440,300 — $587,000",
        size: "737 sqft — 1003 sqft",
        sizeSqm: "68.5 sqm — 93.2 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/twtWu4ALbdDCUQaKFtmTviFQEIY/Eps3CQ../1.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 2,693,553 — AED 2,844,214",
        priceRangeUSD: "$733,300 — $774,300",
        size: "1171 sqft — 1323 sqft",
        sizeSqm: "108.8 sqm — 122.9 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/devhPut960PoitUpKvB_5ebhJ4E/mcYsoQ../2.png",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 3,553,284 — AED 4,111,765",
        priceRangeUSD: "$967,400 — $1,119,400",
        size: "1653 sqft — 1811 sqft",
        sizeSqm: "153.6 sqm — 168.2 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/wpWF8fudL6kWhNLFkln3RcVpB1k/xIY3Gg../3.png",
      },
    ],
    developerDetails: {
      name: "Sobha",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
      description:
        "Sobha is an international luxury real estate developer committed to redefining the art of living by building sustainable communities. Established in 1976 as an interior decoration firm in Oman by the iconic PNC Menon — a visionary leader in the space, we have grown our presence world-over with developments and investments in the UAE, Oman, Bahrain, Brunei, and India.",
      website: "https://www.sobharealty.com/",
      email: "hello@sobharealty.com",
      address:
        "Sobha Sapphire Building, Al Khaleej Al Tejari 2 Street, Business Bay, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        saturday: "10 AM - 5 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 19,
    title: "Sobha One",
    developer: "Sobha",
    location: "Bukadra",
    city: "Dubai",
    completionDate: "DEC 2026",
    lastUpdated: "Nov 12, 2024",
    price: "$434,574",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/ngg0w4GQQhGXmegoou72fiQn5k0/9vYGTw../Sobha-One-1.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    //recommendationText: "Recommended by ambassadors",
    recommended: false,
    videoUrl: "https://youtube.com/embed/_4zS_6yQZp4",
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=place_id:ChIJ3d44As9nXz4RjDkPFi--agw&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "International School of Creative Science", distance: "7.8 km" },
      { name: "Dubai Mall", distance: "10 km" },
      { name: "DXB Airport", distance: "10.8 km" },
      { name: "Dubai Opera", distance: "13 km" },
      { name: "Palm Jumeirah", distance: "18 km" },
      { name: "Dubai Marina", distance: "27.8 km" },
      { name: "Palm Jumeirah", distance: "28.7 km" },
      { name: "Emirates Golf Club", distance: "29.4 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/AZ8fDEXcsB69ydW3SBqScUfuTm0/EIxSPQ../Sobha-One-1.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/RD-9GtRN7znaBnjKDAvcsF2MzAQ/f6FvUQ../sobha+1.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/NB13b_SwCElyaGNCxQM-E3ddVuY/uB7x3A../photo_2022-12-02+11_54_11.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/Igez2Kglbf_7reYbQfirr9nyhWI/TIvaVw../WhatsApp+Image+2023-01-23+at+08_50_37.jpeg",

          "https://api.reelly.io/vault/ZZLvFZFt/H8pi8BOCXdSzFxM493lJoSpEkgE/vrrRJw../bedroom-2x.jpg.webp",
          "https://api.reelly.io/vault/ZZLvFZFt/1KRhcASmmdnHSO5o7iDAdnLOTI0/4ZL8-w../dining-room-2x.jpg.webp",
          "https://api.reelly.io/vault/ZZLvFZFt/ZFPIYuEFQ9DQJ1ZUZldupgY_LqM/NUpdLQ../living-room-2x.jpg.webp",
          "https://api.reelly.io/vault/ZZLvFZFt/9RT1mSmi3pZzi2Z7lDHSVhhK0h0/AWrVoQ../sobha-one-interior-by-floor-plans.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/KxFDwnP_DPUlL0MMQCRmZjzdyNM/wCIxsQ../sobha-one-runner-7.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/MeKjkTF7K-2yu5l1v_15WLeGPCc/SBuvAA../sobha-one-runner-8.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/S5s7Z5HK-hReQu1Pih8XXXKdqik/LfovAQ../11.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/cOLHnNZ1pJU8bm6774GDRMzOoi0/J8Yw7g../12.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/U61r1T4j0mZPOrLzEZocWKuHMaE/7X0wSA../13.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/cVi0_O0nZeY8SUNbnkpJ5ZR-jxU/ozHxDg../16.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/UQatRIx5pO7AZUy2DobBZOIg04U/gLEYwA../17.jpg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Let the journey begin at Sobha One, a new development by Sobha Group that features 1 to 4-bedroom waterfront apartments located at Sobha Hartland, Dubai. Discover a life that is both rich enough to appeal to the brilliance of modernity and close enough to nature to be considered interesting and elegant.",
          "The development presents a unique design of five interlinked towers, rising from 30 to 65 storeys, where residents are going to experience sky gardens and similar facilities. The advancement comes with a Putt golf course offering 18 holes for its lovers to unwind after a long day at work.",
        ],
        finishingMaterials: "High-quality materials.",
        kitchenAppliances: "Fully fitted kitchens.",
        furnishing: "No. Bedroom wardrobe in all units.",
        locationBenefits: [
          "Bukadra, located within the vibrant city of Dubai, is a district that seamlessly blends urban convenience with a serene residential atmosphere. Nestled close to iconic landmarks like the Burj Khalifa and Downtown Dubai, Bukadra offers easy access to the heart of the city while maintaining a sense of tranquility that appeals to its residents.",
          "The area is characterized by a diverse mix of residential options, from spacious villas and townhouses to modern apartments, making it an attractive destination for families, professionals, and expatriates seeking a comfortable and well-connected place to live. It is known for its peaceful ambiance, with tree-lined streets and green spaces providing a retreat from bustling city life.",
          "Strategically positioned near major highways, including Sheikh Zayed Road and Al Khail Road, residents enjoy quick access to other parts of Dubai. Public transportation options, such as buses and the Dubai Metro, further enhance the area's connectivity.",
        ],
      },
      pt: {
        generalFacts: [
          "Deixe a jornada começar no Sobha One, um novo desenvolvimento do Sobha Group que apresenta apartamentos à beira-mar de 1 a 4 quartos localizados no Sobha Hartland, Dubai. Descubra uma vida rica o suficiente para atrair o brilho da modernidade e próxima o suficiente da natureza para ser considerada interessante e elegante.",
          "O empreendimento apresenta um design único de cinco torres interligadas, com alturas que variam de 30 a 65 andares, onde os residentes poderão desfrutar de jardins suspensos e instalações semelhantes. O avanço inclui um campo de golfe Putt com 18 buracos, oferecendo aos amantes do esporte um local para relaxar após um longo dia de trabalho.",
        ],
        finishingMaterials: "Materiais de alta qualidade.",
        kitchenAppliances: "Cozinhas totalmente equipadas.",
        furnishing: "Não. Guarda-roupa nos quartos em todas as unidades.",
        locationBenefits: [
          "Bukadra, localizada na vibrante cidade de Dubai, é um distrito que combina perfeitamente a conveniência urbana com uma atmosfera residencial tranquila. Próxima de marcos icônicos como o Burj Khalifa e o Downtown Dubai, Bukadra oferece fácil acesso ao coração da cidade enquanto mantém um senso de tranquilidade que atrai seus moradores.",
          "A área é caracterizada por uma variedade diversificada de opções residenciais, desde villas espaçosas e casas geminadas até apartamentos modernos, tornando-se um destino atraente para famílias, profissionais e expatriados que buscam um lugar confortável e bem conectado para viver. É conhecida por sua atmosfera pacífica, com ruas arborizadas e espaços verdes que oferecem um refúgio da vida agitada da cidade.",
          "Estratégicamente posicionada perto das principais rodovias, incluindo a Sheikh Zayed Road e a Al Khail Road, os moradores desfrutam de acesso rápido a outras partes de Dubai. Opções de transporte público, como ônibus e o metrô de Dubai, melhoram ainda mais a conectividade da área.",
        ],
      },
    },

    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/EVWkyGhoqElOvqJbW59WT8weQx4/9s7ilg../WhatsApp-Image-2023-01-23-at-08_49_40.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Open pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/XaQdLDdcl2oZYspjqB_zcMlm_lg/pl3hFw../open+pool.jpg",
      },
      {
        name: "Kids play area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/tceot-uqSwJl9h7E74Y810LPSaE/DuFidw../19.jpg",
      },
      {
        name: "Dining outlets",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/bX8sgNRneIN0jphBKZKutOQnKkE/euYQBA../dining.jpg",
      },
      {
        name: "Courtyard",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/66SM9wBpKxxGmkUKD8CL7crD360/4DgeOA../123.jpeg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 1.5,
        priceRange: "AED 1,594,890 — AED 2,571,240",
        priceRangeUSD: "$434,200 — $700,000",
        size: "721 sqft — 1224 sqft",
        sizeSqm: "67 sqm — 113.7 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/FwErNERjHuTm1r5RuvnyWRLV2Iw/iysbKQ../1%2C5b.jpg",
      },
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 1,685,946 — AED 2,215,015",
        priceRangeUSD: "$459,000 — $603,000",
        size: "733 sqft — 963 sqft",
        sizeSqm: "68.1 sqm — 89.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/zC9QzmE4bD90nAtW4KAE6wlL0mo/6Bd7xA../Sobha+One+One+bedroom.jpg",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 2,332,162 — AED 4,368,494",
        priceRangeUSD: "$634,900 — $1,189,300",
        size: "1138 sqft — 2449 sqft",
        sizeSqm: "105.7 sqm — 227.5 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/jO0j3ct278mAmWKW-DHiNRNYEP0/QnwILg../Sobha+One+Two+bedroom.jpg",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 4,139,949 — AED 4,975,809",
        priceRangeUSD: "$1,127,100 — $1,354,700",
        size: "2010 sqft — 2495 sqft",
        sizeSqm: "186.7 sqm — 231.8 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/1Tcxa0Mv5ml_IoXq5WIjMkk2XCM/Uvfn3Q../Sobha+One+Three+bedroom.jpg",
      },
      {
        type: "Apartments",
        bedrooms: 4,
        priceRange: "AED 4,739,133 — AED 5,226,774",
        priceRangeUSD: "$1,290,200 — $1,423,000",
        size: "2230 sqft — 2489 sqft",
        sizeSqm: "207.2 sqm — 231.2 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/JPkBpXzaEYGPorvd75BkinEMxeQ/SS5mXQ../Sobha+One+Four+bedroom.jpg",
      },
      {
        type: "Duplex",
        bedrooms: 4,
        priceRange: "AED 4,885,588 — AED 8,042,976",
        priceRangeUSD: "$1,330,100 — $2,189,700",
        size: "2355 sqft — 3351 sqft",
        sizeSqm: "218.8 sqm — 311.3 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/4IndAjQGBy0IobWsfEgv4wcyOgc/Grb4pg../4d.png",
      },
      {
        type: "Duplex",
        bedrooms: 5,
        priceRange: "AED 11,723,184 — AED 11,725,248",
        priceRangeUSD: "$3,191,600 — $3,192,200",
        size: "4885 sqft — 4886 sqft",
        sizeSqm: "453.8 sqm — 453.9 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/PyFcnFw6hNtAdQObepK_DdTJcZ0/wokcAg../5d.png",
      },
    ],
    developerDetails: {
      name: "Sobha",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
      description:
        "Sobha is an international luxury real estate developer committed to redefining the art of living by building sustainable communities. Established in 1976 as an interior decoration firm in Oman by the iconic PNC Menon — a visionary leader in the space, we have grown our presence world-over with developments and investments in the UAE, Oman, Bahrain, Brunei, and India.",
      website: "https://www.sobharealty.com/",
      email: "hello@sobharealty.com",
      address:
        "Sobha Sapphire Building, Al Khaleej Al Tejari 2 Street, Business Bay, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        saturday: "10 AM - 5 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  {
    id: 20,
    title: "Sobha Seahaven",
    developer: "Sobha",
    location: "Dubai Marina",
    city: "Dubai",
    completionDate: "AUG 2026",
    lastUpdated: "Nov 12, 2024",
    price: "$1,203,044",
    imageUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/PDWxGWXp0YiJ9FqLiyFeed8M32M/nUTccA../7.jpg",
    statusBadge: "On Sale",
    salesStatus: "On Sale",
    //commissionBadge: "1M AED Commission",
    recommendationText: "Recommended",
    recommended: true,
    videoUrl: "https://www.youtube.com/embed/DoUBbLjiZmU",
    mapUrl:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBauSYTp4mBbYKhK5yt2vfnRjbukayP5HA&q=place_id:ChIJFdCXVrVrXz4RGHYW7mHS2GM&zoom=13",
    developerLogoUrl:
      "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
    commissionDetails: {
      commission: "5.5%",
      paymentTerm: "20% of payment",
      paymentCycle: "On booking",
    },

    //notice: "The development comprises four individual buildings...", <<< continuar
    nearbyPlaces: [
      { name: "Calisthenics Park", distance: "1.2 km" },
      { name: "Marinascape Mall", distance: "1.5 km" },
      { name: "JBR Beach", distance: "2.1 km" },
      { name: "Dubai Marina Mall", distance: "2.3 km" },
      { name: "The International School of Choueifat", distance: "4.2 km" },
      { name: "The Palm Jumeirah", distance: "5.4 km" },
      { name: "Burj Arab", distance: "7.8 km" },
      { name: "DXB Airport", distance: "31.2 km" },
    ],
    visualizations: [
      {
        type: "Architecture",
        images: [
          "https://api.reelly.io/vault/ZZLvFZFt/zIGuy9i4Sl1SlyNLDU2yAz7q2Ak/RpOvng../7.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/aBDRgYiVo0TfZvoTeWPB3SG-pRI/E8saig../10.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/841X1q41-BoHTmQPX6kNRROkd2E/f3EFAw../36.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/OGnzsS-QqaCsyy4wuMK9bEsk4Eg/KheWow../WhatsApp+Image+2023-01-23+at+08_41_42.jpeg",

          "https://api.reelly.io/vault/ZZLvFZFt/c2J5MD7t46kIW8UA_GcVQFXqKuQ/lMpSUQ../53.jpg",
          "https://api.reelly.io/vault/ZZLvFZFt/vI76hiPBFz4xFsof_9AizWcfbOk/I0hJYA../WhatsApp+Image+2023-01-23+at+08_47_10.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/F_-VAwqAzk7YeSENtPDFBz91Chg/K-x7_Q../WhatsApp+Image+2023-01-23+at+08_47_07.jpeg",
          "https://api.reelly.io/vault/ZZLvFZFt/3_YfLvMJLLBumvclGkJcfTtteL8/fOhFbQ../WhatsApp+Image+2023-01-23+at+08_47_26.jpeg",
        ],
      },
    ],
    overview: {
      en: {
        generalFacts: [
          "Sobha Seahaven by Sobha Group, a brand new residential development offering a choice of luxury design 1, 2 & 3 bedroom apartments located at Dubai Harbour. These amazing design three towers collection are laced with lower level of surprising lifestyle amenities, where the highest of them comes with 67-storey, rising high in the sky with awe-inspiring views of Dubai.",
        ],
        finishingMaterials:
          "Extra large format Porcelain tile. Pre-Engineered timber flooring. Contemporary & stylish Carpet. Veneer finished solid core tall & elegant doors. Marble & Solid surface counter tops. Doubly Glazed Floor to Ceiling glass curtain wall. Leatherette & rich veneer finished wardrobes. Clear heights of 3 meters in Living areas. Smart Home automation enabled. State-of-art home appliances. Elegant Single slot diffusers. Flexi track lighting.",
        kitchenAppliances:
          "Kitchens will come fully-fitted and will be equipped with Miele fittings and marble and solid surface countertops. Smart home system and state-of-the-art home appliances will also be offered in Sobha Seahaven.",
        furnishing: "No. Bedroom wardrobe in all units",
        locationBenefits: [
          "Sobha Seahaven located in the Mouth of Dubai Harbour. Residents of this development can get to other areas of the emirate, as well as to key attractions and landmarks, in just a several-minute drive thanks to direct access to the Sheikh Zayed Road. For instance, Downtown Dubai with the iconic Burj Khalifa, The Dubai Mall and Business Bay, which is the business center of the emirate, are reachable within a 20-minute drive from your apartment.  Dubai Harbour is located at the intersection of the iconic Palm Jumeirah and Bluewaters, equidistant from Dubai International Airport and Al Maktoum International Airport. This unique waterfront neighbourhood is a stone’s throw away from the city’s most-loved landmarks, beautiful beaches and world-famous attractions such as Ain Dubai and Burj Al Arab. Embrace the call of the sea at Dubai Harbour. An extraordinary seafront district that seamlessly merges a range of F&B and retail offerings with VIP, living, and hospitality experiences, Dubai Harbour is also home to the most advanced cruise terminal and the largest marina in the region.",
        ],
      },
      pt: {
        generalFacts: [
          "Sobha Seahaven da Sobha Group, um novo empreendimento residencial oferecendo opções de apartamentos luxuosos de 1, 2 e 3 quartos localizados no Dubai Harbour. Esta incrível coleção de três torres é equipada com um nível inferior de comodidades surpreendentes, onde a mais alta delas possui 67 andares, elevando-se ao céu com vistas impressionantes de Dubai.",
        ],
        finishingMaterials:
          "Azulejo de porcelanato de grande formato. Piso de madeira pré-fabricado. Carpete contemporâneo e elegante. Portas altas e elegantes com acabamento em folheado de núcleo sólido. Bancadas de mármore e superfície sólida. Parede cortina de vidro duplo do chão ao teto. Guarda-roupas com acabamento em couro sintético e folheado rico. Altura livre de 3 metros nas áreas de estar. Automação residencial inteligente integrada. Eletrodomésticos de última geração. Difusores de slot único elegantes. Iluminação de trilho flexível.",
        kitchenAppliances:
          "As cozinhas serão totalmente equipadas e contarão com acessórios da Miele, além de bancadas de mármore e superfície sólida. Sistema de casa inteligente e eletrodomésticos de última geração também serão oferecidos no Sobha Seahaven.",
        furnishing: "Não. Guarda-roupa no quarto em todas as unidades.",
        locationBenefits: [
          "Sobha Seahaven está localizado na entrada do Dubai Harbour. Os moradores deste empreendimento podem acessar outras áreas do emirado, bem como principais atrações e pontos de referência, em apenas alguns minutos de carro graças ao acesso direto à Sheikh Zayed Road. Por exemplo, Downtown Dubai, com o icônico Burj Khalifa, The Dubai Mall e Business Bay, que é o centro empresarial do emirado, são acessíveis em até 20 minutos de carro do seu apartamento. O Dubai Harbour está localizado na interseção da icônica Palm Jumeirah e Bluewaters, equidistante do Aeroporto Internacional de Dubai e do Aeroporto Internacional Al Maktoum. Este bairro único à beira-mar está a poucos passos dos marcos mais amados da cidade, belas praias e atrações mundialmente famosas como o Ain Dubai e o Burj Al Arab. Abrace o chamado do mar no Dubai Harbour. Um distrito extraordinário à beira-mar que integra harmoniosamente uma gama de ofertas de alimentação, varejo, experiências VIP, residenciais e de hospitalidade. O Dubai Harbour também abriga o terminal de cruzeiros mais avançado e a maior marina da região.",
        ],
      },
    },
    generalPlanImage:
      "https://api.reelly.io/vault/ZZLvFZFt/r8asWYZb46ACLo7B5MwgwckB9hg/4GST4Q../Group+206.png",
    buildings: [
      {
        name: "Tower A",
        description: "A mix of 1, 2 and 3 bedroom apartments",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lgR1gmJ_ejvGSWQ6JAh1kAmNNc8/jpWTjA../Sobha+Marina+C.jpg",
      },
      {
        name: "Tower B",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/It7KgpdQjnkKs-xhhJv9NktXR5E/C3U3Zg../Sobha+Marina+B.jpg",
      },
      {
        name: "Tower C",
        description: "Coming soon",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/e0YcC0QR1-G_CC5M15T3GiMpAZc/fnIMFA../Sobha+Marina+A.jpg",
      },
    ],
    facilities: [
      {
        name: "Swimming pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/06b4_CJeRLE7pVm8KreZv3ZrdmI/4dKoAQ../5.jpg",
      },
      {
        name: "Health club",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/GmcRILRaSkKhbrJxC6Y04eN2epg/ni3LVw../50.jpg",
      },
      {
        name: "Outdoor Cinema",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/-1glqsZ7b3Tm5uivOqjpfBI5z44/49sVUA../19-2.jpg",
      },
      {
        name: "Skate park",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/_TVL__C3XpuygYYvomHmbkjaj_k/qB1QXw../5d9a9179.jpg",
      },
      {
        name: "Kids pool",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/DhLUg9EyY3_L_JfpuR3aXC22BUk/LVyddw../happy-kids-near-the-pool-N6ZH7FU-scaled.jpg",
      },
      {
        name: "Sauna and steam",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/5eFO6TjNtL7uUHr7F6GCUsngMX8/M_npVQ../featured-image-steam-room_jpeg.jpg",
      },
      {
        name: "Walking track",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/8l9UY-t5jsMR12o5iohr-wicxxU/7606rA../33.jpg",
      },
      {
        name: "Dining places",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/3OFa4U7DOO7wGzVOJ5hd7KWoY7o/KTF9YA../49.jpg",
      },
      {
        name: "Water sport",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/WUNtWHHf51_ZG235aZrmpqLLNHE/AJTfhg../51.jpg",
      },
      {
        name: "Family zone with BBQ area",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/XAzYTmMs_Cucyv2PNdHBP8zhCZE/tTV6Lg../63.jpg",
      },
    ],

    units: [
      {
        type: "Apartments",
        bedrooms: 1,
        priceRange: "AED 4,415,173 — AED 5,548,005",
        priceRangeUSD: "$1,202,000 — $1,510,400",
        size: "1130 sqft — 1244 sqft",
        sizeSqm: "105 sqm — 115.6 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/aXlBG-p7pMtHQY3vahwye9jU0D0/HskaBA../Sea+Heaven+One+bedroom.jpg",
      },
      {
        type: "Apartments",
        bedrooms: 1.5,
        priceRange: "AED 4,719,790 — AED 5,092,856",
        priceRangeUSD: "$1,285,000 — $1,386,500",
        size: "1193 sqft — 1242 sqft",
        sizeSqm: "110.8 sqm — 115.4 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/7mZHPTk7pT3ilVFIYxcOsoTaOU4/84Knxg../1.5.png",
      },
      {
        type: "Apartments",
        bedrooms: 2,
        priceRange: "AED 6,349,940 — AED 8,699,201",
        priceRangeUSD: "$1,728,800 — $2,368,400",
        size: "1716 sqft — 2023 sqft",
        sizeSqm: "159.4 sqm — 187.9 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ahUGoZzfuCJ9B6Ruosnfvy3GiN0/3hdzZw../Sobha+Marina+Two+bedroom.jpg",
      },
      {
        type: "Apartments",
        bedrooms: 3,
        priceRange: "AED 9,514,167 — AED 21,382,320",
        priceRangeUSD: "$2,590,200 — $5,821,300",
        size: "2375 sqft — 3749 sqft",
        sizeSqm: "220.6 sqm — 348.3 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/ZOOgtnzyIVfyHRbJoRwIJG1q5_M/owd03g../Sobha+Marina+Three+bedroom.jpg",
      },
      {
        type: "Penthouse",
        bedrooms: 4,
        priceRange: "AED 22,240,845 — AED 42,252,420",
        priceRangeUSD: "$6,055,100 — $11,503,200",
        size: "4044 sqft — 7285 sqft",
        sizeSqm: "375.7 sqm — 676.8 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/Xim1hme1nxf9pCS1Al7-LEHM7rw/J8mwPA../4bP.png",
      },
      {
        type: "Penthouse",
        bedrooms: 3,
        priceRange: "AED 27,482,426 — AED 0",
        priceRangeUSD: "$7,482,100 — $0",
        size: "4186 sqft — 0 sqft",
        sizeSqm: "388.9 sqm — 0 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/R-jnIo0eI6d5U26c1MwDMmWnyXw/66SnMg../3bP.png",
      },
      {
        type: "Penthouse",
        bedrooms: 5,
        priceRange: "AED 51,188,438 — AED 53,869,313",
        priceRangeUSD: "$13,936,100 — $14,665,900",
        size: "7815 sqft — 8217 sqft",
        sizeSqm: "726 sqm — 763.4 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/lhDQA3dNKMUZHTds82TVIyzxuyw/X77uwA../5brp.png",
      },
      {
        type: "Penthouse",
        bedrooms: 6,
        priceRange: "AED 105,330,810 — AED 158,590,575",
        priceRangeUSD: "$28,676,300 — $43,176,300",
        size: "16205 sqft — 24399 sqft",
        sizeSqm: "1505.5 sqm — 2266.7 sqm",
        imageUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/wKsL43fI2b3ELOpt2zuSgFKCJUE/esj-Sg../6brp.png",
      },
    ],
    developerDetails: {
      name: "Sobha",
      logoUrl:
        "https://api.reelly.io/vault/ZZLvFZFt/2ICnZvNCBrZuhI218X9zNa2ZE8I/JfqF7w../1537869753264.jpeg",
      description:
        "Sobha is an international luxury real estate developer committed to redefining the art of living by building sustainable communities. Established in 1976 as an interior decoration firm in Oman by the iconic PNC Menon — a visionary leader in the space, we have grown our presence world-over with developments and investments in the UAE, Oman, Bahrain, Brunei, and India.",
      website: "https://www.sobharealty.com/",
      email: "hello@sobharealty.com",
      address:
        "Sobha Sapphire Building, Al Khaleej Al Tejari 2 Street, Business Bay, Dubai, UAE",
      workingTime: {
        weekdays: "Monday - Friday",
        weekdayHours: "9 AM - 6 PM",
        saturday: "10 AM - 5 PM",
        sunday: "Day off",
      },
    },

    salesContacts: [
      {
        name: "Weverton",
        position: "Your Property Expert",
        photoUrl:
          "https://api.reelly.io/vault/ZZLvFZFt/brs89YCYTU6HV26CHlj-hxF3Kqs/1W0OcQ../A71V2100.jpg",
        languages: ["English", "Portuguese"],
        rating: 5,
        whatsappLink: "https://wa.me/+971556605815",
        company: "Escodelar Properties",
      },
      // Add more contacts here if needed
    ],
  },
  // Add more projects as needed
];

export default projectData;
