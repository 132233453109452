import React from "react";
import styled from "styled-components";
import { FiUser, FiMail, FiPhone, FiMessageSquare } from "react-icons/fi";

// Styled components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-image: url("/images/background-image.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom; /* Aligns the image’s bottom edge with the container’s bottom */
  background-repeat: no-repeat;
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  box-sizing: border-box; /* Ensure padding is included within the width */
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #0c1244;
  margin-bottom: 1rem;
  text-align: center;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #888;
`;

const Input = styled.input`
  width: 100%; /* Ensure it takes full width of container */
  padding: 0.75rem;
  padding-left: 2.5rem; /* Space for icon */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f1f1;
  box-sizing: border-box; /* Prevents overflow */
`;

const FormTextArea = styled.textarea`
  width: 100%; /* Ensure it takes full width of container */
  padding: 0.75rem;
  padding-left: 2.5rem; /* Space for icon */
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  background-color: #f1f1f1;
  box-sizing: border-box; /* Prevents overflow */
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0c1244;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  &:hover {
    background-color: #0a0e33;
  }
`;

const FAQItem = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
`;

const FAQQuestion = styled.strong`
  font-size: 1.1rem;
  color: #0c1244;
`;

const FAQAnswer = styled.p`
  font-size: 1rem;
  color: #333;
  margin-top: 0.5rem;
  font-weight: normal; /* Make answers normal (non-bold) */
`;

const ContactUsForm = () => (
  <CardContainer>
    <Title>Entre em contato conosco</Title>
    <InputContainer>
      <IconWrapper>
        <FiUser />
      </IconWrapper>
      <Input placeholder="Your Name" />
    </InputContainer>
    <InputContainer>
      <IconWrapper>
        <FiMail />
      </IconWrapper>
      <Input placeholder="Your Email" />
    </InputContainer>
    <InputContainer>
      <IconWrapper>
        <FiPhone />
      </IconWrapper>
      <Input placeholder="Your Phone" />
    </InputContainer>
    <InputContainer>
      <IconWrapper>
        <FiMessageSquare />
      </IconWrapper>
      <FormTextArea rows="4" placeholder="Your Message" />
    </InputContainer>
    <SubmitButton>Send Message</SubmitButton>
  </CardContainer>
);

const FAQSection = ({ faqData }) => (
  <CardContainer>
    <Title>Perguntas Frequentes</Title>
    {faqData.map((faq, index) => (
      <FAQItem key={index}>
        <FAQQuestion>{faq.question}</FAQQuestion>
        <FAQAnswer>{faq.answer}</FAQAnswer>
      </FAQItem>
    ))}
  </CardContainer>
);

const FAQ = () => {
  const faqData = [
    {
      question:
        "Quais são os requisitos para estrangeiros comprarem imóveis em Dubai?",
      answer:
        'Estrangeiros podem comprar imóveis em áreas "freehold", com 100% de propriedade.',
    },
    {
      question:
        "É necessário ser residente em Dubai para comprar uma propriedade?",
      answer:
        "Não, estrangeiros podem comprar propriedades sem ser residentes.",
    },
    {
      question:
        "Quais são os custos adicionais envolvidos na compra de um imóvel?",
      answer:
        "Inclui 4% de taxa de registro, corretagem de 2%-5%, e não há imposto sobre ganho de capital.",
    },
    {
      question:
        "Quanto tempo leva para concluir a compra de um imóvel em Dubai?",
      answer:
        "O processo pode levar entre 30 a 60 dias, dependendo se é à vista ou financiado.",
    },
    {
      question:
        "Como funciona o processo de financiamento imobiliário para estrangeiros?",
      answer:
        "Estrangeiros podem financiar até 60% do valor do imóvel, devendo aportar 40% do valor.",
    },
    {
      question: "Quais são os bairros mais promissores para investir em Dubai?",
      answer:
        "Downtown Dubai, Dubai Marina, JVC, Business Bay, e Palm Jumeirah são populares.",
    },
    {
      question:
        "É possível comprar imóveis na planta? Quais são as vantagens e riscos?",
      answer:
        "Sim, com preços mais baixos e planos flexíveis. Riscos incluem atrasos e avaliação do desenvolvedor.",
    },
    {
      question:
        "Qual é o retorno médio sobre o investimento (ROI) em propriedades em Dubai?",
      answer:
        "O ROI varia entre 6% a 8% em média, com algumas áreas chegando a 10%.",
    },
    {
      question:
        "Os imóveis em Dubai têm potencial de valorização a longo prazo?",
      answer:
        "Sim, Dubai continua a se expandir como um hub global, o que pode aumentar a valorização.",
    },
    {
      question: "Como funciona o processo de aluguel de imóveis em Dubai?",
      answer:
        "Proprietários podem alugar por contratos anuais ou curtos como AirBnB. Aluguéis pagos em cheques pós-datados.",
    },
    {
      question:
        "Quais são os custos anuais de manutenção de uma propriedade em Dubai?",
      answer:
        "As taxas variam entre AED 10 a AED 30 por pé quadrado, dependendo do tipo de propriedade.",
    },
    {
      question: "Como funciona o visto de investidor imobiliário em Dubai?",
      answer:
        "Imóveis acima de AED 2 milhões podem qualificar para o Golden Visa, com 10 anos de duração.",
    },
    {
      question:
        "É possível vender o imóvel facilmente? Existe um mercado secundário ativo?",
      answer:
        "Sim, especialmente em áreas estabelecidas, dependendo das condições do mercado.",
    },
    {
      question:
        "Como posso gerenciar a propriedade à distância (serviços de administração de imóveis)?",
      answer:
        "Serviços como Escodelar Properties oferecem gestão remota, incluindo aluguel e manutenção.",
    },
    {
      question: "Quais são os melhores tipos de imóveis para investimento?",
      answer:
        "Apartamentos para aluguéis de curto prazo, villas para longo prazo, e comerciais para rendimento.",
    },
    {
      question:
        "É seguro investir em imóveis em Dubai? Quais são as garantias legais?",
      answer:
        "Sim, Dubai tem um sistema jurídico robusto e regulamentos imobiliários supervisionados pelo DLD.",
    },
    {
      question: "Quais são os direitos dos investidores em Dubai?",
      answer:
        "Investidores estrangeiros têm propriedade plena em áreas freehold e um sistema judicial transparente.",
    },
    {
      question:
        "Quais são as taxas de comissão para corretores de imóveis em Dubai?",
      answer:
        "A comissão varia de 2% a 5% do valor da transação, dependendo da agência.",
    },
    {
      question:
        "Qual é o futuro do mercado imobiliário em Dubai e quais são as perspectivas de crescimento?",
      answer:
        "Com infraestrutura em expansão e iniciativas pró-investimento, o mercado imobiliário de Dubai tem boas perspectivas.",
    },
  ];

  return (
    <PageContainer>
      <FAQSection faqData={faqData} />
      <ContactUsForm />
    </PageContainer>
  );
};

export default FAQ;
