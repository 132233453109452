import React from "react";
import styled from "styled-components";

// Styled Components
const HeaderSectionContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 25rem;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  @media (max-width: 840px) {
    height: 20rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: -2.5rem;
  left: 10rem;
  z-index: 2;
  background-color: #fff;
  border: 2px solid #d4d4d4;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 840px) {
    bottom: -2rem;
    left: 1rem;
  }
`;

const DeveloperLogo = styled.img`
  width: 10rem;
  height: auto;
  object-fit: contain;
  @media (max-width: 840px) {
    width: 6rem;
  }
`;

// HeaderSection Component
const HeaderSection = ({ imageUrl, developerLogoUrl, developer }) => {
  return (
    <HeaderSectionContainer>
      <ImageContainer imageUrl={imageUrl} />
      <LogoContainer>
        <DeveloperLogo src={developerLogoUrl} alt={developer} />
      </LogoContainer>
    </HeaderSectionContainer>
  );
};

export default HeaderSection;
