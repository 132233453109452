// src/pages/Login.js
import React, { useState } from "react";
import "./Login.css";
// import { FaHeadset } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../firebaseConfig"; // Import Firebase authentication
import { signInWithEmailAndPassword } from "firebase/auth"; // Import signInWithEmailAndPassword from Firebase
import { doc, getDoc } from "firebase/firestore";

const LoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Track errors
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Reset error message

    try {
      // Authenticate user with Firebase
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("User logged in:", userCredential.user);

      const user = userCredential.user;

      // Fetch user profile from Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
      // Notify parent (e.g., App.js) that the user is logged in
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("User data fetched:", userData);

        // Save userData in localStorage or context
        localStorage.setItem("userData", JSON.stringify(userData));
        onLogin(); // Notify parent component about login
        navigate("/"); // Redirect to dashboard
      } else {
        console.error("No user data found!");
      }
    } catch (error) {
      // Handle authentication errors
      if (error.code === "auth/user-not-found") {
        setErrorMessage("No account found with this email.");
      } else if (error.code === "auth/wrong-password") {
        setErrorMessage("Invalid email or password.");
      } else if (error.code === "auth/too-many-requests") {
        setErrorMessage("Too many failed attempts. Please try again later.");
      } else {
        setErrorMessage("Failed to log in. Please try again.");
      }
    }
  };

  return (
    <div className="login-container">
      {/* Header */}
      <div className="login-header">
        <img src="images/domar logo.png" alt="Logo" className="logo" />
      </div>

      {/* Login Form */}
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Sign in or create new account</h2>

        {/* Email Input */}
        <input
          type="email"
          placeholder="weverton@escodelar.ae"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
          required
        />

        {/* Password Input */}
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
          required
        />

        {/* Display Error Message */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* Submit Button */}
        <button type="submit" className="continue-btn">
          Continue
        </button>

        {/* Forgot Password */}
        <div className="forgot-password">
          <Link to="/forgot-password">Forgot password?</Link>
        </div>

        {/* Create Account */}
        <div className="create-account">
          <span>
            Don’t have an account?{" "}
            <Link to="/create-account">Create account</Link>
          </span>
        </div>
      </form>

      {/* Statistics */}
      <div className="statistics">
        <p>
          <strong>24830</strong>
        </p>
        <p>
          Agents of Dubai are already using Escodelar in working with clients
        </p>
      </div>

      {/* Support Button */}
      {/* <div className="support">
        <button className="support-btn">
          <FaHeadset size={20} style={{ marginRight: "8px" }} />
        </button>
      </div> */}
    </div>
  );
};

export default LoginPage;
