import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Papa from "papaparse"; // CSV parser
import { auth, db } from "../firebaseConfig"; // Firebase config
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  padding: 2rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #0c1244;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;

  &:hover {
    background-color: #0a0e33;
  }
`;

const Results = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #333;
`;

const ErrorList = styled.ul`
  margin-top: 1rem;
  color: red;
  font-size: 0.9rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  th,
  td {
    border: 1px solid #ccc;
    padding: 0.5rem;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Admin = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [errors, setErrors] = useState([]);
  const [parsedData, setParsedData] = useState([]); // Parsed file content
  const [isAdmin, setIsAdmin] = useState(false); // Admin access control
  const [loading, setLoading] = useState(true); // For admin check
  // const [isUploading, setIsUploading] = useState(false); // For upload process
  const isUploadRef = useRef(false); // Prevent admin check during uploads
  const fileInputRef = useRef(null); // Ref to trigger file input
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the logged-in user is an admin
    const checkAdminRole = async () => {
      setLoading(true);
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user && !isUploadRef.current) {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists() && userDoc.data().role === "admin") {
            setIsAdmin(true);
          } else {
            alert("Access Denied: Admins only.");
            navigate("/"); // Redirect to the home page or login page
          }
        } else if (!user) {
          alert("Please log in to access this page.");
          navigate("/login"); // Redirect to login
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    checkAdminRole();
  }, [navigate]);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setUploadStatus(`File "${uploadedFile.name}" selected. Ready to parse.`);
    setErrors([]);
    setParsedData([]);
  };

  const handleSelectFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically open file dialog
    }
  };

  const parseFile = () => {
    if (!file) {
      alert("Please upload a valid CSV file.");
      return;
    }

    setUploadStatus("Parsing file...");
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setParsedData(result.data); // Store parsed data for preview
        setUploadStatus(`Parsed ${result.data.length} rows successfully.`);
      },
      error: (error) => {
        console.error("Error parsing file:", error.message);
        setUploadStatus("Failed to parse the file.");
      },
    });
  };

  const uploadUsers = async () => {
    if (parsedData.length === 0) {
      alert("No data to upload. Please parse the file first.");
      return;
    }

    // setIsUploading(true); // Mark as uploading
    isUploadRef.current = true; // Prevent admin check during upload
    setUploadStatus("Starting upload...");
    const errorList = [];

    for (let i = 0; i < parsedData.length; i++) {
      const row = parsedData[i];
      try {
        // Basic field validation
        if (!row.email) throw new Error("Missing email field.");
        if (!row.name) throw new Error("Missing name field.");

        await createUser(row);
      } catch (error) {
        errorList.push(
          `Row ${i + 1} (Email: ${row.email || "N/A"}): ${error.message}`
        );
      }
    }

    setErrors(errorList);
    setUploadStatus(
      `Upload completed with ${errorList.length} errors. Check the error list below.`
    );
    // setIsUploading(false); // Reset uploading state
    isUploadRef.current = false; // Allow admin checks again
  };

  const createUser = async (userData) => {
    const {
      name,
      email,
      phone = "N/A",
      creci = "N/A",
      city = "N/A",
      password = "defaultPassword123",
    } = userData;

    // Warn if default password is being used
    if (!userData.password) {
      console.warn(
        `User ${email} is missing a password. Using default password.`
      );
    }

    // Create user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const userId = userCredential.user.uid;

    // Add metadata to Firestore
    await setDoc(doc(db, "users", userId), {
      name,
      email,
      phone,
      creci,
      city,
      role: "user", // Default role is "user"
      createdAt: new Date(),
    });
  };

  if (loading) return <div>Loading...</div>;
  if (!isAdmin) return null; // Render nothing if the user is not an admin

  return (
    <Container>
      <Title>Admin - Upload Users</Title>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <Button onClick={handleSelectFile}>Select CSV File</Button>
      {file && <p>Selected File: {file.name}</p>}
      <Button onClick={parseFile}>Preview File</Button>
      {parsedData.length > 0 && (
        <>
          <h3>Preview Parsed Data:</h3>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>CRECI</th>
                <th>City</th>
                <th>Password</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((row, index) => (
                <tr key={index}>
                  <td>{row.name || "N/A"}</td>
                  <td>{row.email || "N/A"}</td>
                  <td>{row.phone || "N/A"}</td>
                  <td>{row.creci || "N/A"}</td>
                  <td>{row.city || "N/A"}</td>
                  <td>{row.password || "defaultPassword123"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={uploadUsers}>Upload Users</Button>
        </>
      )}
      {uploadStatus && <Results>{uploadStatus}</Results>}
      {errors.length > 0 && (
        <ErrorList>
          <h3>Error List:</h3>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ErrorList>
      )}
    </Container>
  );
};

export default Admin;
