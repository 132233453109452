// src/components/Facilities.js
import React from "react";
import styled from "styled-components";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  LocationBenefits,
  LocationBlockContainer,
  TitleFlex,
  TitleIcon,
} from "./LocationBlock";
import { PropertyTitle } from "./PropertyDetails";
import starIcon from "../assets/star.svg";

const CarouselContainer = styled.div`
  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background: #000;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    color: #fff;
  }

  .slick-slide {
    padding: 0 8px; /* Add spacing between slides */
    box-sizing: border-box; /* Prevent unwanted scaling issues */
  }

  .slick-dots li button:before {
    color: #000;
  }
`;

const FacilityGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  @media (max-width: 840px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FacilityCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // gap: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FacilityImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 14rem;
  @media (max-width: 840px) {
    height: 10rem;
  }
`;

// const CardContent = styled.div`
//   padding: 12px;
// `;

// const CardTitle = styled.h3`
//   color: #000;
//   margin-bottom: 4px;
//   font-size: 16px;
//   font-weight: 500;
// `;

const Facilities = ({ facilities }) => {
  // const carouselSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <div>
      <TitleFlex>
        <TitleIcon>
          <img width="100%" src={starIcon} alt="Important points" />
        </TitleIcon>
        <PropertyTitle>Important points</PropertyTitle>
      </TitleFlex>
      <LocationBlockContainer>
        <CarouselContainer>
          {/* <Slider {...carouselSettings}>
            {facilities.map((facility, index) => (
              <FacilityCard key={index}>
                <FacilityImage src={facility.imageUrl} alt={facility.name} />
                <CardContent>
                  <CardTitle>{facility.name}</CardTitle>
                </CardContent>
              </FacilityCard>
            ))}
          </Slider> */}
          <FacilityGrid>
            {facilities.map((facility, index) => (
              <FacilityCard key={index}>
                <FacilityImage src={facility.imageUrl} alt={facility.name} />
              </FacilityCard>
            ))}
          </FacilityGrid>
        </CarouselContainer>

        <LocationBenefits>
          <ol>
            <li>
              Estamos falando de um Branded residence, que é um imóvel assinado
              por marca e neste caso estamos falando a tradicionalissima suiça
              Franck Muller de relógios
            </li>
            <li>
              Os tamanhos da unidade para essa região também são excelentes,
              estamos falando de unidades a partir de 150m2.
            </li>
            <li>Smart home, automação básica já entregue.</li>
          </ol>
        </LocationBenefits>
      </LocationBlockContainer>
    </div>
  );
};

export default Facilities;
