// src/components/Visualisation.js
import React from "react";
import styled from "styled-components";
import Slider from "react-slick"; // Import react-slick carousel
import "slick-carousel/slick/slick.css"; // Slick carousel default styles
import "slick-carousel/slick/slick-theme.css"; // Slick carousel theme styles
import { LocationBlockContainer } from "./LocationBlock";
import { PropertyTitle } from "./PropertyDetails";
import targetIcon from "../assets/target.svg";
import buildingIcon from "../assets/building.svg";
import starIcon from "../assets/star.svg";
import compassIcon from "../assets/compass.svg";
import mapIcon from "../assets/map.svg";

const Section = styled.div`
  padding: 1rem 0;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
`;

const TabButton = styled.button`
  background-color: ${(props) => (props.active ? "#0e216a;" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#0e216a;")};
  border: none;
  border-radius: 20px;
  padding: 8px 12px;
  font-weight: 600;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 34px;
`;

const CarouselContainer = styled.div`
  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background: #000;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    color: #fff;
  }
  .slick-slide {
    padding: 0 8px; /* Add spacing between slides */
    box-sizing: border-box; /* Prevent unwanted scaling issues */
  }
  .slick-dots li button:before {
    color: #000;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 30rem;
  border-radius: 12px;
  object-fit: cover;
  background-color: #e0e0e0;
  @media (max-width: 840px) {
    height: 15rem;
  }
`;

const CarouselVideo = styled.iframe`
  width: 100%;
  height: 30rem;
  border-radius: 12px;
  object-fit: cover;
  background-color: #e0e0e0;
  @media (max-width: 840px) {
    height: 15rem;
  }
`;

const IconCardsSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 3rem;
  padding: 0 1rem;
`;

const IconCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
`;

const IconWrapper = styled.div`
  margin-bottom: 1rem;

  img {
    width: 3rem;
  }
`;

const CardText = styled.p`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000;
`;

const TopIconsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  margin-top: 3rem;
  padding: 0 5rem;

  @media (max-width: 840px) {
    gap: 1.5rem;
    padding: 0 2rem;
  }
`;

const SmallIcon = styled.img`
  width: 2rem;
  height: 2rem;

  @media (max-width: 840px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Visualisation = ({ selectedTab, onTabClick, images, video }) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const videoURL = `${video}?rel=0&controls=1&showinfo=0`;

  return (
    <Section>
      <PropertyTitle>Escodelar present:</PropertyTitle>
      <LocationBlockContainer>
        <TabContainer>
          <TabButton
            active={selectedTab === "Architecture"}
            onClick={() => onTabClick("Architecture")}
          >
            Image
          </TabButton>
          <TabButton
            active={selectedTab === "Interior"}
            onClick={() => onTabClick("Interior")}
          >
            Video
          </TabButton>
        </TabContainer>
        <CarouselContainer>
          {images.length > 0 ? (
            <Slider {...carouselSettings}>
              {images.map((image, index) => (
                <div key={index}>
                  <CarouselImage
                    src={image}
                    alt={`${selectedTab} ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <CarouselVideo
              src={videoURL}
              title="Habtoor Grand Residences"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          )}
        </CarouselContainer>
        <TopIconsGrid>
          <SmallIcon src={compassIcon} alt="Compass" />
          <SmallIcon src={mapIcon} alt="Map" />
          <SmallIcon src={compassIcon} alt="Compass" />
          <SmallIcon src={mapIcon} alt="Map" />
          <SmallIcon src={compassIcon} alt="Compass" />
          <SmallIcon src={mapIcon} alt="Map" />
          <SmallIcon src={compassIcon} alt="Compass" />
          <SmallIcon src={mapIcon} alt="Map" />
        </TopIconsGrid>
        <IconCardsSection>
          <IconCard>
            <IconWrapper>
              <img src={targetIcon} alt="Target Icon" />
            </IconWrapper>
            <CardText>
              Será a maior torre residencial de relógio do mundo.
            </CardText>
          </IconCard>

          <IconCard>
            <IconWrapper>
              <img src={buildingIcon} alt="Building Icon" />
            </IconWrapper>
            <CardText>
              104 andares com vistas para golf club, Sheikh Zayed e todo o
              skyline maravilhoso de JLT e Palm Jumeirah.
            </CardText>
          </IconCard>

          <IconCard>
            <IconWrapper>
              <img src={starIcon} alt="Star Icon" />
            </IconWrapper>
            <CardText>Será entregue em Q2 2027.</CardText>
          </IconCard>
        </IconCardsSection>
      </LocationBlockContainer>
    </Section>
  );
};

export default Visualisation;
export { SmallIcon };
