// src/components/PaymentPlan.js
import React from "react";
import paymentIcon from "../assets/payment.svg";
import {
  LocationBenefits,
  LocationBlockContainer,
  TitleFlex,
  TitleIcon,
} from "./LocationBlock";
import { PropertyTitle, Section } from "./PropertyDetails";

const paymentPlanItems = [
  "10% na reserva.",
  "10% dentro de 1 mês após a reserva.",
  "5% em 1º de abril de 2025.",
  "5% em 1º de outubro de 2025.",
  "5% em 1º de abril de 2026.",
  "5% em 1º de outubro de 2026.",
  "5% em 1º de abril de 2027.",
  "5% em 1º de outubro de 2027.",
  "50% no momento da entrega do projeto.",
];

const PaymentPlan = () => {
  return (
    <Section>
      <TitleFlex>
        <TitleIcon>
          <img width="100%" src={paymentIcon} alt="Payment plan" />
        </TitleIcon>
        <PropertyTitle>Payment Plan</PropertyTitle>
      </TitleFlex>
      <LocationBlockContainer>
        <LocationBenefits>
          <ul>
            {paymentPlanItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </LocationBenefits>
      </LocationBlockContainer>
    </Section>
  );
};

export default PaymentPlan;
