// src/pages/InitiateSale.js
import React, { useState } from "react";
import styled from "styled-components";
import { db, storage } from "../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FiUser, FiMail, FiPhone, FiFile } from "react-icons/fi";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background-color: #f1f3f8;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 2rem;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #0c1244;
  margin-bottom: 1.5rem;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f9fc;
  border-radius: 8px;
  padding: 0.8rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
`;

const InputField = styled.input`
  flex: 1;
  padding: 0.6rem;
  font-size: 1rem;
  border: none;
  outline: none;
  background: none;
`;

const FileButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e7ff;
  color: #0c1244;
  font-weight: bold;
  border-radius: 8px;
  padding: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #c2c9f2;
  }
  svg {
    margin-right: 8px;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  color: white;
  background-color: #0c1244;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0a0e33;
  }
  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const InitiateSale = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telephone: "",
    passport: null,
    idPicture: null,
  });

  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      const passportRef = ref(storage, `passports/${formData.passport.name}`);
      const idPictureRef = ref(
        storage,
        `idPictures/${formData.idPicture.name}`
      );

      const passportSnapshot = await uploadBytes(
        passportRef,
        formData.passport
      );
      const idPictureSnapshot = await uploadBytes(
        idPictureRef,
        formData.idPicture
      );

      const passportUrl = await getDownloadURL(passportSnapshot.ref);
      const idPictureUrl = await getDownloadURL(idPictureSnapshot.ref);

      await addDoc(collection(db, "salesData"), {
        name: formData.name,
        email: formData.email,
        telephone: formData.telephone,
        passportUrl,
        idPictureUrl,
        timestamp: new Date(),
      });

      alert("Form submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <PageContainer>
      <FormContainer>
        <Title>Initiate a Sale</Title>
        <form onSubmit={handleSubmit}>
          <FormRow>
            <FiUser size={24} color="#0C1244" style={{ marginRight: "10px" }} />
            <InputField
              name="name"
              onChange={handleChange}
              placeholder="Client Name"
              required
            />
          </FormRow>
          <FormRow>
            <FiMail size={24} color="#0C1244" style={{ marginRight: "10px" }} />
            <InputField
              type="email"
              name="email"
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </FormRow>
          <FormRow>
            <FiPhone size={24} color="#0C1244" style={{ marginRight: "10px" }} />
            <InputField
              type="tel"
              name="telephone"
              onChange={handleChange}
              placeholder="Telephone"
              required
            />
          </FormRow>
          <FileButton htmlFor="passport">
            <FiFile size={20} />
            Upload Passport Picture
            <FileInput
              id="passport"
              type="file"
              name="passport"
              onChange={handleChange}
              accept="image/*"
              required
            />
          </FileButton>
          <FileButton htmlFor="idPicture">
            <FiFile size={20} />
            Upload ID Picture
            <FileInput
              id="idPicture"
              type="file"
              name="idPicture"
              onChange={handleChange}
              accept="image/*"
              required
            />
          </FileButton>
          <SubmitButton type="submit" disabled={uploading}>
            {uploading ? "Submitting..." : "Submit"}
          </SubmitButton>
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default InitiateSale;
