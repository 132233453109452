// src/components/PropertyDetails.js
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import projectData from "../projectData";
import Visualisation from "./Visualisation";
// import Overview from "./Overview";
import Facilities from "./Facilities";
import Units from "./Units";
import LocationBlock from "./LocationBlock";
import PaymentPlan from "./PaymentPlan";

const Container = styled.div`
  padding: 0;
  margin: 0;
  background-color: #f7f6f7;
`;

const ContentWrapper = styled.div`
  padding: 0 10rem; /* Adding padding for left and right margins */

  @media (max-width: 840px) {
    padding: 0 1rem;
  }
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 14rem;
  width: calc(100% - 14rem);
  background-color: #fff;
  border-bottom: 1px solid #ebecf0;
  display: flex;
  align-items: baseline;
  padding: 16px;
  padding-bottom: 0px;
  font-weight: 700;
  z-index: 13;

  @media (max-width: 840px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ebecf0;
    display: flex;
    align-items: baseline;
    padding: 16px;
    padding-bottom: 0px;
    font-weight: 700;
    z-index: 13;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 18px;
  cursor: pointer;
`;

const Tab = styled.div`
  color: #000;
  font-size: 18px;
  margin-left: 16px;
  padding-bottom: 8px;
  border-bottom: ${(props) => (props.active ? "2px solid #000" : "none")};
`;

const HeaderSection = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 25rem;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  @media (max-width: 840px) {
    height: 20rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: -2.5rem;
  left: 10rem;
  z-index: 2;
  background-color: #fff;
  border: 2px solid #d4d4d4;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 840px) {
    bottom: -2rem;
    left: 1rem;
  }
`;

const DeveloperLogo = styled.img`
  width: 10rem;
  height: auto;
  object-fit: contain;
  @media (max-width: 840px) {
    width: 6rem;
  }
`;

const PropertyDetailsContainer = styled.div`
  margin-top: 3rem; /* Add space between the logo and title */
  padding: 0 1rem;
  text-align: center;
  @media (max-width: 840px) {
    margin-top: 3rem;
  }
`;

const PropertyTitle = styled.h1`
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 2.5rem; /* Matches ~50px */
  line-height: 1.3; /* Matches ~66px */
  color: #0e216a;
  margin: 0;
  padding: 0;
  @media (max-width: 840px) {
    font-size: 2rem;
  }
`;

const Section = styled.div`
  padding: 1rem 0;
`;

const BlockTitle = styled.h2`
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
`;

const GeneralPlanImage = styled.img`
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
  height: auto;
`;

const ProjectsGridLast = styled.div`
  display: flex;
  justify-content: start;
  overflow: auto; /* Allows scrolling if needed */
  margin-bottom: 4.5rem;
`;

const ContactInfoSticky = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 840px) {
    gap: 0rem;
  }
`;

const ContactCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 12px 16px;
  width: 50%;
  @media (max-width: 840px) {
    width: 90%;
  }
`;

const ContactInfoLast = styled.div`
  display: flex;
  align-items: center;
`;

const ContactImage = styled.img`
  object-fit: cover;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  margin-right: 12px;
`;

const NameText = styled.h4`
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  margin-top: 4px;
  margin-bottom: 0;
`;

const PositionText = styled.p`
  color: #85858a;
  font-size: 12px;
  font-weight: 500;
`;

const LanguageTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 12px;
`;

const LanguageTag = styled.div`
  background-color: #f2f2f6;
  border-radius: 20px;
  padding: 5px 8px;
`;

const RatingBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const RatingStars = styled.div`
  color: #ffcd3c;
  font-size: 22px;
  margin-right: 8px;
`;

const RatingText = styled.span`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
`;

const WhatsappButton = styled.a`
  background-color: #00d757;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  margin-top: 12px;
  cursor: pointer;
`;

const WhatsappIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

// Styled components for the sticky contact section
const StickyContactWrapper = styled.div`
  position: fixed;
  bottom: 8px;
  left: 11rem; /* Adjust this value based on sidebar width */
  right: 4.5rem;
  left: 20rem;
  border-radius: 12px;
  background-color: #fff;
  border-top: 1px solid #ebecf0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 6px 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // align-items: center;
  // justify-content: space-between;
  z-index: 1000;

  /* Make sure it doesn't overlap the sidebar */
  @media (max-width: 840px) {
    left: 0;
    right: 0;
    // flex-direction: column;
    // align-items: flex-start;
    padding: 0.5rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContactName = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  @media (max-width: 840) {
    font-size: 0.7rem;
  }
`;

const ContactPosition = styled.p`
  font-size: 0.75rem;
  color: #85858a;
  margin: 0;
  @media (max-width: 840) {
    font-size: 0.2rem;
  }
`;

const ContactActions = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  color: #fff;
  background-color: #0e216a;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 40px;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProjectButton = styled(ActionButton)`
  background-color: #fff;
  color: #000;
  border: 1px solid #ebecf0;
  max-width: 15px;
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 12px;
  height: 12px;
`;

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StickyContact = ({ contact, propertyId }) => {
  const navigate = useNavigate(); // Use useNavigate inside StickyContact

  return (
    <StickyContactWrapper>
      <ContactInfoSticky>
        <ContactImage src={contact.photoUrl} alt={contact.name} />
        <ContactDetails>
          <ContactName>
            {/* {contact.name} ({contact.company}) */}
            {contact.name}
          </ContactName>
          <ContactPosition>{contact.position}</ContactPosition>
        </ContactDetails>
        <ProjectButton>
          <Icon
            src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/66793058cf9d59b9cdeff6dd_call.png"
            alt="Call"
          />
        </ProjectButton>
        <ProjectButton>
          <Icon
            src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/64805d1f48e07cea39d2be35_whatsapp%20(1).png"
            alt="WhatsApp"
          />
        </ProjectButton>
      </ContactInfoSticky>
      <ContactActions>
        {/* Get offer link button with SVG icon */}
        <ActionButton
          onClick={() => navigate(`/property/${propertyId}/offer-link`)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M9.6706 14.8285L15.3275 9.17168M11.7919 7.05036L13.2061 5.63615C14.7682 4.07405 17.3009 4.07405 18.863 5.63615C20.4251 7.19825 20.4251 9.73091 18.863 11.293L17.4488 12.7072M7.54928 11.293L6.13507 12.7072C4.57297 14.2693 4.57297 16.802 6.13507 18.3641C7.69716 19.9262 10.2298 19.9262 11.7919 18.3641L13.2061 16.9499"
              stroke="currentcolor"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          Get offer link
        </ActionButton>
      </ContactActions>
    </StickyContactWrapper>
  );
};

const SalesContactsComponent = ({ contacts }) => (
  <ProjectsGridLast>
    {contacts.map((contact, index) => (
      <ContactCard key={index}>
        <ContactContent>
          <ContactInfoLast>
            <ContactImage src={contact.photoUrl} alt={contact.name} />
            <div>
              <NameText>{contact.name}</NameText>
              <PositionText>{contact.position}</PositionText>
            </div>
          </ContactInfoLast>
          <LanguageTags>
            {contact.languages.map((language, index) => (
              <LanguageTag key={index}>{language}</LanguageTag>
            ))}
          </LanguageTags>
          <RatingBlock>
            <RatingStars>{"★".repeat(contact.rating)}</RatingStars>
            <RatingText>{contact.rating}/5</RatingText>
          </RatingBlock>
        </ContactContent>
        <WhatsappButton href={contact.whatsappLink} target="_blank">
          <WhatsappIcon>
            <svg
              width="29"
              height="28"
              viewBox="0 0 29 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.5 13.6407C28.5 21.1692 22.3482 27.2825 14.7579 27.2825C12.351 27.2825 10.0842 26.6618 8.11484 25.5862L0.5 28L2.98499 20.678C1.72964 18.6247 1.00752 16.2155 1.00752 13.6418C1.00752 6.10986 7.16164 0 14.7578 0C22.3482 0 28.5 6.10986 28.5 13.6407ZM14.7578 2.17235C8.38666 2.17235 3.19732 7.31385 3.19732 13.6418C3.19732 16.1548 4.0163 18.4788 5.39767 20.3688L3.95798 24.6167L8.39948 23.2132C10.2218 24.4125 12.4081 25.1043 14.7578 25.1043C21.1243 25.1043 26.3125 19.9605 26.3125 13.6325C26.3125 7.31502 21.1243 2.17235 14.7578 2.17235ZM21.6937 16.7837C21.612 16.6472 21.388 16.5643 21.0555 16.3847C20.709 16.2178 19.0593 15.4186 18.742 15.309C18.4399 15.1969 18.2158 15.1421 17.9919 15.4746C17.7573 15.8048 17.1192 16.5655 16.9244 16.7836C16.7167 17.0052 16.523 17.0333 16.1893 16.8676C15.8604 16.7031 14.7683 16.3485 13.4838 15.2051C12.4851 14.3243 11.805 13.2311 11.6102 12.8917C11.4095 12.5545 11.5869 12.3748 11.7549 12.2103C11.9124 12.0587 12.0932 11.816 12.26 11.6224C12.4339 11.4299 12.4887 11.2922 12.5996 11.0705C12.7116 10.8419 12.6544 10.6494 12.5716 10.4767C12.4887 10.3099 11.8097 8.6684 11.5321 7.99757C11.2486 7.32908 10.9732 7.43993 10.7784 7.43993C10.5753 7.43993 10.3561 7.41193 10.1274 7.41193C9.90575 7.41193 9.53942 7.49362 9.22672 7.83308C8.92222 8.1644 8.04837 8.97289 8.04837 10.6202C8.04837 12.2629 9.25471 13.8589 9.42739 14.0782C9.5954 14.2998 11.7549 17.7788 15.1837 19.1182C18.6079 20.4575 18.6079 20.0154 19.2309 19.9594C19.8387 19.9034 21.2259 19.1474 21.5036 18.3727C21.7765 17.5851 21.7765 16.9225 21.6937 16.7837Z"
                fill="currentcolor"
              ></path>
            </svg>
          </WhatsappIcon>
          WhatsApp
        </WhatsappButton>
      </ContactCard>
    ))}
  </ProjectsGridLast>
);

const PropertyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const property = projectData.find((project) => project.id === parseInt(id));
  const [selectedTab, setSelectedTab] = useState("Architecture");
  // const [language, setLanguage] = useState("en");
  const [unitType, setUnitType] = useState("AED"); // Default to AED
  const [measurementType, setMeasurementType] = useState("SQFT"); // Default to SQFT

  // Add a new function for handling measurement type toggle
  const handleMeasurementToggle = (type) => {
    setMeasurementType(type);
  };

  if (!property) {
    return <Container>Property not found</Container>;
  }

  const handleBackClick = () => {
    navigate("/");
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // const handleLanguageChange = (lang) => {
  //   setLanguage(lang);
  // };

  const handleToggleClick = (type) => {
    setUnitType(type);
  };

  return (
    <Container>
      <HeaderContainer>
        <BackButton onClick={handleBackClick}>← Back</BackButton>
        <Tab active>General info</Tab>
      </HeaderContainer>
      <HeaderSection>
        <ImageContainer imageUrl={property.imageUrl} />

        <LogoContainer>
          <DeveloperLogo
            src={property.developerLogoUrl}
            alt={property.developer}
          />
        </LogoContainer>
      </HeaderSection>
      <ContentWrapper>
        <Section>
          <PropertyDetailsContainer>
            <PropertyTitle>{property.title}</PropertyTitle>
          </PropertyDetailsContainer>
        </Section>
        <Section>
          <Visualisation
            selectedTab={selectedTab}
            onTabClick={handleTabClick}
            images={
              property.visualizations.find(
                (visual) => visual.type === selectedTab
              )?.images || []
            }
            video={property.videoUrl}
          />
        </Section>
        <Section>
          <LocationBlock
            location={property.location}
            mapUrl={property.mapUrl}
            nearbyPlaces={property.nearbyPlaces}
          />
        </Section>
        <Section>
          <Facilities facilities={property.facilities} />
        </Section>
        <Section>
          <Units
            units={property.units}
            unitType={unitType}
            measurementType={measurementType}
            onUnitTypeToggle={handleToggleClick}
            onMeasurementToggle={handleMeasurementToggle}
          />
        </Section>
        <PaymentPlan />
        <Section>
          <h3>Executive sales contacts</h3>
          <SalesContactsComponent contacts={property.salesContacts} />
        </Section>
        <StickyContact
          contact={property.salesContacts[0]}
          propertyId={property.id}
        />
      </ContentWrapper>

      {/* Remaining sections can follow similarly */}
    </Container>
  );
};

export default PropertyDetails;
export {
  Container,
  HeaderContainer,
  BackButton,
  ContentWrapper,
  Section,
  BlockTitle,
  GeneralPlanImage,
  PropertyTitle,
  PropertyDetailsContainer,
};
