// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Firebase configuration object (replace with your credentials)
const firebaseConfig = {
  apiKey: "AIzaSyDwDrgLhdwZpnxsuYdCXSeUYj_TkJBzzHw",
  authDomain: "sohazur-domar.firebaseapp.com",
  projectId: "sohazur-domar",
  storageBucket: "sohazur-domar.appspot.com",
  messagingSenderId: "581253565960",
  appId: "1:581253565960:web:8250c859091595bb4c8bbd",
  measurementId: "G-E773QK1832",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Firestore instance
const storage = getStorage(app); // Storage instance
const auth = getAuth(app); // Auth instance

export { db, storage, auth };
