// src/pages/ForgotPassword.js
import React, { useState } from "react";
import "./ForgotPassword.css";
// import { FaHeadset } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSubmitted(false);

    if (!email) {
      setErrorMessage("Please enter your email.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSubmitted(true);
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setErrorMessage("Invalid email format.");
      } else if (error.code === "auth/user-not-found") {
        setErrorMessage("No user found for that email.");
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    }
  };

  return (
    <div className="forgot-password-container">
      {/* Logo */}
      <div className="forgot-password-header">
        <img src="images/domar logo.png" alt="Logo" className="logo" />
      </div>

      {/* Form */}
      <div className="forgot-password-form-container">
        {submitted ? (
          <div className="success-message">
            <div className="token-verification-animation">
              <img
                src="https://cdn.prod.website-files.com/63c5585ff31e74bfcf46a136/64b5d072f6269d189a3f76bd_At-sign.png"
                alt="Email Sent"
                className="pdf-icon"
              />
            </div>
            <h2 className="h1-text">
              A link to reset your password has been sent to your email
            </h2>
            <p className="text-block-2">
              Please check your mailbox and open the email from Reelly to reset
              your password. Didn't find the letter? - Check your spam folder.
            </p>
            <button
              className="back-to-login-btn"
              onClick={() => navigate("/login")}
            >
              Back to Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleResetPassword} className="forgot-password-form">
            <h1 className="form-header">Request password reset</h1>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input"
              required
            />
            {errorMessage && (
              <div className="error-message forgot-password text-red align-left">
                {errorMessage}
              </div>
            )}
            <button type="submit" className="login-button">
              Reset password
            </button>
            <a
              href="/login"
              className="back-to-login-link"
              onClick={(e) => {
                e.preventDefault();
                navigate("/login");
              }}
            >
              ← Back to login
            </a>
          </form>
        )}
      </div>

      {/* Support Button */}
      {/* <div className="support">
        <button className="support-btn">
          <FaHeadset size={20} style={{ marginRight: "8px" }} />
          Support
        </button>
      </div> */}
    </div>
  );
};

export default ForgotPassword;
