// src/pages/CreateAccount.js
import React, { useState } from "react";
import "./CreateAccount.css"; // Make sure the styles file is correctly imported
// import { FaHeadset } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const CreateAccount = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    password: "",
    companyWebsite: "",
    role: "Broker",
    position: "Seller",
    country: "None",
    companySize: "",
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }
    if (!formData.phone.match(/^\+\d+/)) {
      newErrors.phone = "Phone number must start with '+' and country code.";
    }
    if (formData.country === "None") {
      newErrors.country = "Please select your country.";
    }
    if (!formData.companySize) {
      newErrors.companySize = "Please select your company size.";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked");
    setErrors({});
    setSuccessMessage("");
    setErrorMessage("");

    console.log("Handling form submission...");

    // Validate the form fields
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      console.log("Validation errors:", validationErrors);
      setErrors(validationErrors);
      return;
    }

    try {
      console.log("Creating user with Firebase...");
      // Create user account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Save user details in Firestore
      const user = userCredential.user;
      await setDoc(doc(db, "users", user.uid), {
        fullName: formData.fullName,
        phone: formData.phone,
        email: formData.email,
        company: formData.companyWebsite,
        role: formData.role,
        position: formData.position,
        country: formData.country,
        companySize: formData.companySize,
        profilePicture:
          "https://xdil-qda0-zofk.m2.xano.io/vault/ZZLvFZFt/II7U8HuoQxB6a9fp1nH2dfLz1JQ/UNFV0g../user-icon.jpg", // Default picture
        favorites: {},
      });

      console.log("User created successfully:", userCredential);
      // Send email verification
      await sendEmailVerification(userCredential.user);

      setSuccessMessage(
        "Account created successfully! A verification email has been sent to your email address."
      );

      // Redirect to login page after 0.1 seconds
      setTimeout(() => navigate("/login"), 100);
    } catch (error) {
      console.error("Firebase error:", error);
      // Handle Firebase errors
      if (error.code === "auth/email-already-in-use") {
        setErrorMessage("This email is already registered. Please log in.");
      } else if (error.code === "auth/weak-password") {
        setErrorMessage("Password should be at least 6 characters.");
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="create-account-container">
      {/* Logo */}
      <div className="create-account-header">
        <img
          src="images/domar logo.png" // Update this path if your logo is in a different folder
          alt="Reelly Logo"
          className="logo"
        />
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit} className="create-account-form">
        <h1 className="sign-up-form-header">
          Create your account in Escodelar
        </h1>

        {successMessage && <div className="success-text">{successMessage}</div>}
        {errorMessage && <div className="error-text">{errorMessage}</div>}
        <Link to="/login" className="go-to-sign-in">
          <div className="grey-text-2">Already have an account?</div>
          <div className="sign-in-text">Sign in</div>
        </Link>

        <input
          type="text"
          name="fullName"
          placeholder="First and Last name"
          value={formData.fullName}
          onChange={handleChange}
          className="input"
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleChange}
          className="input"
          required
        />
        {errors.phone && <div className="error-text">{errors.phone}</div>}

        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="input"
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleChange}
          className="input"
          required
        />
        {errors.password && <div className="error-text">{errors.password}</div>}

        <div className="input-description-wrapper">
          <div className="profile-text">Your company name</div>
          <div className="input-description-text">
            Enter the name of the company you are working on, e.g. Escodelar
          </div>
          <input
            type="text"
            name="companyWebsite"
            placeholder="company.com"
            value={formData.companyWebsite}
            onChange={handleChange}
            className="input"
            required
          />
        </div>

        <div className="input-description-wrapper">
          <div className="profile-text">Your role</div>
          <div className="input-description-text">
            Which side do you represent?
          </div>
          <select
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="input"
            required
          >
            <option value="Broker">Broker</option>
            <option value="Developer">Developer</option>
            <option value="Investor">Investor</option>
          </select>
        </div>

        <div className="input-description-wrapper">
          <div className="profile-text">Your position</div>
          <div className="input-description-text">
            What is your position in the company?
          </div>
          <select
            name="position"
            value={formData.position}
            onChange={handleChange}
            className="input"
            required
          >
            <option value="Seller">Seller / Manager</option>
            <option value="Director">Director / CEO</option>
          </select>
        </div>

        <div className="input-description-wrapper">
          <div className="profile-text">Your country</div>
          <div className="input-description-text">
            Where do you live and work?
          </div>
          <select
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="input"
            required
          >
            <option value="None">Select your country</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="Brazil">Brazil</option>
            <option value="Argentina">Argentina</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Chile">Chile</option>
            <option value="Colombia">Colombia</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cuba">Cuba</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Honduras">Honduras</option>
            <option value="Mexico">Mexico</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Panama">Panama</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Egypt">Egypt</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Jordan">Jordan</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Libya">Libya</option>
            <option value="Morocco">Morocco</option>
            <option value="Oman">Oman</option>
            <option value="Qatar">Qatar</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Syria">Syria</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Yemen">Yemen</option>
            <option value="United States of America">United States</option>
            <option value="Canada">Canada</option>
            <option value="Mexico">Mexico</option>
            <option value="Argentina">Argentina</option>
            <option value="Brazil">Brazil</option>
            <option value="Colombia">Colombia</option>
            <option value="Chile">Chile</option>
            <option value="Peru">Peru</option>
            <option value="Belize">Belize</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cuba">Cuba</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Honduras">Honduras</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Panama">Panama</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Afghanistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Armenia">Armenia</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">
              Bosnia and Herzegovina
            </option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cabo Verde">Cabo Verde</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Central African Republic">
              Central African Republic
            </option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo (Congo-Brazzaville)">
              Congo (Congo-Brazzaville)
            </option>
            <option value="Congo (Congo-Kinshasa)">
              Congo (Congo-Kinshasa)
            </option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czechia (Czech Republic)">
              Czechia (Czech Republic)
            </option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Greece">Greece</option>
            <option value="Grenada">Grenada</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-Bissau">Guinea-Bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Honduras">Honduras</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Ivory Coast">Ivory Coast</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea North">Korea North</option>
            <option value="Korea South">Korea South</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia">Micronesia</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar (Burma)">Myanmar (Burma)</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="North Macedonia">North Macedonia</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Qatar">Qatar</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Vincent and the Grenadines">
              Saint Vincent and the Grenadines
            </option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Sudan">South Sudan</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Timor-Leste">Timor-Leste</option>
            <option value="Togo">Togo</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States of America">United States</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Vatican City">Vatican City</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
            {/* Add more countries */}
          </select>
          {errors.country && <div className="error-text">{errors.country}</div>}
        </div>

        <div className="input-description-wrapper">
          <div className="profile-text">Your company size</div>
          <div className="input-description-text">
            How many agents do you have in your agency?
          </div>
          <select
            name="companySize"
            value={formData.companySize}
            onChange={handleChange}
            className="input"
            required
          >
            <option value="None">Select your answer</option>
            <option value="alone">I work alone</option>
            <option value="2-5">2-5</option>
            <option value="5-10">5-10</option>
            <option value="10-25">10-25</option>
            <option value="25-50">25-50</option>
            <option value="50-100">50-100</option>
            <option value="200-500">200-500</option>
            <option value="500+">500+</option>
          </select>
          {errors.companySize && (
            <div className="error-text">{errors.companySize}</div>
          )}
        </div>

        <div className="checkbox-container">
          <label className="w-form-label">
            <input
              type="checkbox"
              name="agreeTerms"
              required
              className="checkbox"
            />
            I have read & accept{" "}
            <a href="/terms/privacy-policy" className="link-3">
              Privacy Policy
            </a>
            , <a href="/terms/terms-of-use">Terms of Use</a>, and{" "}
            <a href="/terms/cookie-policy" className="link-2">
              Cookie Policy
            </a>
          </label>
        </div>
        {errors.firebase && <div className="error-text">{errors.firebase}</div>}
        <button type="submit" className="login-button">
          Create account
        </button>
      </form>

      {/* Support Button */}
      {/* <div className="support">
        <button className="support-btn">
          <FaHeadset size={20} style={{ marginRight: "8px" }} />
          Support
        </button>
      </div> */}
    </div>
  );
};

export default CreateAccount;
